import React from "react";
import { connect } from "react-redux";
import { CetificateLandingContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Displays } from "components";
import { withTranslation } from "react-i18next";
import { AlertControl } from "components/Modals/Alert";
import { GENERAET_CERIFICATE } from "utils/functions/certificate";

class CetificateLandingContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {}, 1000);
    this.fetchCertificate();
  }

  fetchCertificate = async () => {
    const { router } = this.props;
    let res = await GENERAET_CERIFICATE(router.params?.id);
    if (res && res.status === 200) {
      this.handleDownload(res.data);
    } else {
      AlertControl.show({
        isForceSelect: true,
        title: "Notification\nข้อความแจ้ง",
        description:
          "Download failed.\n Please contact support.\nดาวน์โหลดไม่สำเร็จ\n กรุณาติดต่อเจ้าหน้าที่",
        // btnLabel2: t("Back", { ns: "common" }),
        // onEvent2: () => this.props.router.navigate("/"),
      });
    }
  };

  handleDownload = (e) => {
    const link = document.createElement("a");
    link.download = "certificate.png";
    link.href = e;
    link.click();
    AlertControl.show({
      isForceSelect: true,
      title: "Notification\nข้อความแจ้ง",
      description:
        'Click the "Download" button\n to save to your device.\nคลิกปุ่ม "ดาวน์โหลด"\n เพื่อบันทึกลงในอุปกรณ์ของคุณ',
      // btnLabel2: t("Back", { ns: "common" }),
      // onEvent2: () => this.props.router.navigate("/"),
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <CetificateLandingContainerStyled>
        {isLoading && <Displays.Loading />}
      </CetificateLandingContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(CetificateLandingContainer)));
