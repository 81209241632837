import React from "react";
import { ScanQRCodeFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Inputs } from "components";

const ScanQRCodeForm = ({ onSuccessScan, onSubmit }) => {
  const schema = yup.object().shape({
    // serial_no: yup.string().required('New password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { i18n } = useTranslation(["common"]);

  return (
    <ScanQRCodeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.ScanQRCode
                {...field}
                label={
                  i18n.language === "en"
                    ? "Please open your camera to scan the QR code."
                    : "เปิดกล้องเพื่อ สแกน คิวอาร์โค๊ด"
                }
                // placeholder={t('test')}
                onSuccessScan={onSuccessScan}
                errors={errors.serial_no?.message}
              />
            )}
            name="serial_no"
            defaultValue=""
          />
        </div>
        {/*  <div className="btn_submit_wrap">
          <button
            type="button"
            label="ตกลง"
            // ===== case Activity admin scan ===========
            // ==========================================
            // onClick={() =>
            //   onSuccessScan(
            //     "655511e6d742c2335268d3c1,669e186aeb0f3f457a8d6daf,66c393f102a600add2769c06,1,2"
            //   )
            // }
            // ========= case showroom customer scan ====
            // ==========================================
            // onClick={() => onSuccessScan("/showroom")}

            // ========= case showroom admin scan =======
            // ==========================================
            // onClick={() =>
            //   onSuccessScan(
            //     "showroom,6486adfc1aeb83a7308e2186,67624901660698fbd98dcae5"
            //   )
            // }
          />
        </div> */}
        {/* <button type="button" onClick={() => onSuccessScan("IKJ266360-V261320")}>
          Test
        </button> */}
      </form>
    </ScanQRCodeFormStyled>
  );
};

ScanQRCodeForm.propTypes = {};

export default ScanQRCodeForm;
