import React, { useState } from "react";
import { BookingStep1FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const BookingStep1Form = ({
  ageOptions,
  instumentTypeOptions,
  cateProductOptions,
  categoryID,
  priceOptions,
  purposeOptions,
  onSubmit,
  onBack,
}) => {
  const { i18n } = useTranslation([""]);

  const schema =
    categoryID === "1"
      ? yup.object().shape({
          purpose: yup.object().nullable().required("required"),
          age_range: yup.object().nullable().required("required"),
          instrument_type: yup.object().nullable().required("required"),
          product: yup.object().nullable().required("required"),
          price_range: yup.object().nullable().required("required"),
          // model_info: yup.string().required("required"),
        })
      : yup.object().shape({
          purpose: yup.object().nullable().required("required"),
          age_range: yup.object().nullable().required("required"),
          instrument_type: yup.object().nullable().required("required"),
          product: yup.object().nullable().required("required"),
          // price_range: yup.object().nullable().required("required"),
          // model_info: yup.string().required("required"),
        });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [productOptions, setProductOptions] = useState([]);

  return (
    <BookingStep1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="section">
          <div className="header">
            <p className="title">
              {categoryID === "1"
                ? i18n.language === "en"
                  ? "Please provide the following details so that we can prepare the musical instruments for testing or arrange a suitable visit based on your objectives"
                  : "กรุณากรอกรายละเอียด เพื่อเราสามารถ\nเตรียมเครื่องดนตรีสำหรับทดลองหรือสถานที่\nสำหรับการเยี่ยมชม"
                : i18n.language === "en"
                ? "Please provide the following details so that we can prepare the musical instruments for testing or arrange a suitable visit based on your objectives"
                : "กรุณากรอกรายละเอียด เพื่อเราสามารถ\nเตรียมเครื่องดนตรีสำหรับทดลองหรือสถานที่\nสำหรับการเยี่ยมชม"}
            </p>
            <div className="sub_title">
              {categoryID === "1"
                ? i18n.language === "en"
                  ? "1. Select Musical Instrument Test"
                  : "1. เลือกเครื่องดนตรี"
                : i18n.language === "en"
                ? "1. Select Professional Audio"
                : "1. เลือกเครื่องเสียงมืออาชีพ"}
            </div>
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  theme_standard
                  options={purposeOptions.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value,
                  }))}
                  placeholder={
                    categoryID === "1"
                      ? i18n.language === "en"
                        ? "Purpose of purchase or visit"
                        : "จุดประสงค์ในการเลือกซื้อหรือเข้าชม"
                      : i18n.language === "en"
                      ? "Purpose of purchase or visit"
                      : "จุดประสงค์การเข้าชม"
                  }
                  errors={errors.purpose?.message}
                />
              )}
              name="purpose"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={ageOptions.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value,
                  }))}
                  placeholder={
                    i18n.language === "en"
                      ? "Choose the age of the music instrument players"
                      : "ระบุอายุของผู้เล่นเครื่องดนตรี"
                  }
                  errors={errors.age_range?.message}
                />
              )}
              name="age_range"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={instumentTypeOptions.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value, // เพิ่ม value
                  }))}
                  placeholder={
                    categoryID === "1"
                      ? i18n.language === "en"
                        ? "Choose type of musical instrument"
                        : "เลือกประเภทเครื่องดนตรี"
                      : i18n.language === "en"
                      ? "Choose Product Type"
                      : "เลือกประเภทสินค้า"
                  }
                  errors={errors.instrument_type?.message}
                  onChange={(e) => {
                    setValue("product", null);
                    field.onChange(e);
                    setProductOptions(
                      cateProductOptions[e.value]?.map((g) => ({
                        ...g,
                        label: i18n.language === "en" ? g.labelEn : g.labelTh,
                        value: g.value,
                      })) || []
                    );
                  }}
                />
              )}
              name="instrument_type"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={productOptions}
                  placeholder={
                    categoryID === "1"
                      ? i18n.language === "en"
                        ? "Select product category"
                        : "เลือกกลุ่มสินค้า"
                      : i18n.language === "en"
                      ? "Choose Product Model"
                      : "เลือกกลุ่มสินค้า"
                  }
                  errors={errors.product?.message}
                  disabled={productOptions.length === 0}
                />
              )}
              name="product"
            />
          </div>
          {categoryID === "1" && (
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DropdownSelect
                    {...field}
                    options={priceOptions.map((e) => ({
                      ...e,
                      label: i18n.language === "en" ? e.labelEn : e.labelTh,
                      value: e.value,
                    }))}
                    placeholder={
                      i18n.language === "en"
                        ? "Preferred price range"
                        : "ช่วงราคาที่สนใจ"
                    }
                    errors={errors.price_range?.message}
                  />
                )}
                name="price_range"
              />
            </div>
          )}
          <div className="input_wrap mb_38">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  theme_question
                  placeholder={
                    i18n.language === "en"
                      ? "Please specify the model or provide additional details"
                      : "ระบุรุ่น หรือ ข้อมูลเพิ่มเติม"
                  }
                />
              )}
              name={`model_info`}
            />
          </div>
          <div className="f_action_row">
            <div className="btn_wrap">
              <Buttons.BgStandard
                onClick={onBack}
                label={i18n.language === "en" ? "Back" : "ย้อนกลับ"}
              />
            </div>
            <div className="btn_wrap">
              <Buttons.BgStandard
                type="submit"
                label={i18n.language === "en" ? "Next" : "ต่อไป"}
              />
            </div>
          </div>
        </div>
      </form>
    </BookingStep1FormStyled>
  );
};

BookingStep1Form.propTypes = {};

export default BookingStep1Form;
