import React from "react";
import { connect } from "react-redux";
import { MusicalInstrumentContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import moment from "moment";
import { AlertControl } from "components/Modals/Alert";
import { BOOKING_PURPOSE_MUSIC_INSTRUMENT_OPTIONS } from "utils/functions/booking";
import {
  CATE_PRODUCT_OPTION_OBJECT,
  INSTUMENT_TYPE_OPTIONS,
} from "utils/functions/productBooking";
import { Buttons, Displays, Forms, Headers, Icons } from "components";

class MusicalInstrumentContainer extends React.Component {
  state = {
    step: 1,
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
    isLoading: true,
  };

  handleSwitchToSection2 = () => {
    this.setState({ isSection1: false });
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(`?start_date=${startDate}&end_date=${endDate}`);
  }

  fetchData = async (query) => {
    let resArr = await Promise.all([
      userService.GET_HOLIDATE_LIST(query),
      userService.GET_TIME_SLOT_LIST(query),
    ]);
    if (resArr) {
      this.setState({
        holidayData: resArr[0].data.docs,
        timeSlotData: resArr[1].data.docs,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmitStep1 = (values) => {
    this.setState({
      step1TempData: values,
      step: 2,
    });
  };

  handleSubmitStep2 = (values) => {
    let splitTime = values.time.split(",");
    let params = {
      ...values,
      start_time: splitTime[0],
      end_time: splitTime[1],
    };
    this.setState({
      step2TempData: params,
      step: 3,
    });
  };

  handleClickPrevWeek = () => {
    const { startDate } = this.state;
    const momentLimit = moment().endOf("day");
    const startTemp = moment(startDate, "YYYY-MM-DD")
      .subtract(7, "day")
      .format("YYYY-MM-DD");
    const endTemp = moment(startDate, "YYYY-MM-DD")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    if (momentLimit.isAfter(startDate, "YYYY-MM-DD")) {
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "คุณไม่สามารถดูย้อนหลังได้",
        btnLabel2: "ตกลง",
      });
    } else {
      this.setState(
        {
          startDate: startTemp,
          endDate: endTemp,
          isBgLoading: true,
        },
        () => {
          this.fetchData(`?start_date=${startTemp}&end_date=${endTemp}`);
        }
      );
    }
  };

  handleClickNextWeek = () => {
    const { endDate } = this.state;
    const momentLimit = moment().add(15, "day").endOf("day");
    const startTemp = moment(endDate, "YYYY-MM-DD")
      .add(1, "day")
      .format("YYYY-MM-DD");
    const endTemp = moment(endDate, "YYYY-MM-DD")
      .add(7, "day")
      .format("YYYY-MM-DD");
    if (momentLimit.isBefore(moment(endTemp, "YYYY-MM-DD"))) {
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "คุณสามารถดูล่วงหน้าได้เพียง 14 วันเท่านั้น",
        btnLabel2: "ตกลง",
      });
    } else {
      this.setState(
        {
          startDate: startTemp,
          endDate: endTemp,
          isBgLoading: true,
        },
        () => {
          this.fetchData(`?start_date=${startTemp}&end_date=${endTemp}`);
        }
      );
    }
  };

  handleSubmitBooking = async () => {
    const { authenRedux } = this.props;
    const { step1TempData, step2TempData } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...step1TempData,
      member_type_active: authenRedux.member_type_active,
      instrument_type_code: step1TempData.instrument_type.value,
      instrument_type_en: step1TempData.instrument_type.labelEn,
      instrument_type_th: step1TempData.instrument_type.labelTh,
      product_code: step1TempData.product.value,
      product_en: step1TempData.product.labelEn,
      product_th: step1TempData.product.labelTh,
      purpose: step1TempData.purpose.value,
      category: "1",
      ...step2TempData,
    };
    if (step1TempData.age_range) {
      params.age_min = step1TempData.age_range.value.start;
      params.age_max = step1TempData.age_range.value.end;
    }
    if (step1TempData.price_range) {
      params.price_range_min = step1TempData.price_range.value.start;
      params.price_range_max = step1TempData.price_range.value.end;
    }
    let res = await userService.POST_BOOKING_CREATE(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      toast.success("จองสำเร็จ");
      this.props.router.navigate(ROUTE_PATH.BOOKING);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("จองไม่สำเร็จ");
    }
  };

  handleBack = () => {
    const { step } = this.state;
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      if (step === 1) {
        this.props.router.navigate(ROUTE_PATH.BOOKING);
      } else {
        this.setState({
          step: step - 1,
          isLoading: false,
        });
      }
    }, 500);
  };

  render() {
    const { i18n } = this.props;
    const {
      isBgLoading,
      isLoading,
      step,
      startDate,
      endDate,
      timeSlotData,
      holidayData,
      step1TempData,
      step2TempData,
    } = this.state;
    return (
      <MusicalInstrumentContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.PageHeader
          icons={<Icons.SubMenuBooking />}
          title={"FLAGSHIP STORE, BANGKOK"}
          subTitle={
            i18n.language === "en"
              ? "Book a Music Instument Test"
              : "จองทดสอบเครื่องดนตรี"
          }
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            {step === 1 && (
              <div className="section">
                <Forms.BookingStep1Form
                  categoryID="1"
                  ageOptions={AGE_OPTIONS}
                  instumentTypeOptions={INSTUMENT_TYPE_OPTIONS}
                  cateProductOptions={CATE_PRODUCT_OPTION_OBJECT}
                  priceOptions={PRICE_OPTIONS}
                  purposeOptions={BOOKING_PURPOSE_MUSIC_INSTRUMENT_OPTIONS}
                  onSubmit={this.handleSubmitStep1}
                  onBack={this.handleBack}
                />
              </div>
            )}
            {step === 2 && (
              <div className="section">
                <Forms.BookingStep2Form
                  startDate={startDate}
                  endDate={endDate}
                  timeSlotData={timeSlotData}
                  holidayData={holidayData}
                  onClickPrevWeek={this.handleClickPrevWeek}
                  onClickNextWeek={this.handleClickNextWeek}
                  onSubmit={this.handleSubmitStep2}
                  onBack={this.handleBack}
                />
              </div>
            )}
            {step === 3 && (
              <>
                <div className="sum_title">
                  {i18n.language === "en"
                    ? "Please Check Detail "
                    : "ตรวจเช็ครายละเอียด"}
                </div>
                <div className="detail_card">
                  <div className="card_title">
                    {i18n.language === "en"
                      ? "Booking Detail "
                      : "นัดหมายลองเครื่องดนตรี"}
                  </div>
                  <div className="detail_title">
                    {" "}
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="info mb_20">
                    {i18n.language === "en"
                      ? step1TempData.instrument_type?.labelEn
                      : step1TempData.instrument_type?.labelTh}
                  </div>
                  <div className="detail_title">
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="info mb_20">
                    {i18n.language === "en"
                      ? step1TempData.product?.labelEn
                      : step1TempData.product?.labelTh}
                  </div>
                  <div className="detail_title">
                    {" "}
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="info mb_20">
                    {step1TempData?.model_info || "-"}
                  </div>
                  <div className="detail_title">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                  <div className="info">
                    {moment(step2TempData.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="info mb_40">
                    {i18n.language === "th" ? "เวลา" : "Time"}{" "}
                    {step2TempData?.start_time} - {step2TempData?.end_time}{" "}
                    {i18n.language === "th" && "น."}
                  </div>
                  <div className="btn_wrap">
                    <Buttons.BgStandard
                      theme_standard
                      label={i18n.language === "en" ? "Confirm" : "ยืนยัน"}
                      onClick={this.handleSubmitBooking}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </MusicalInstrumentContainerStyled>
    );
  }
}

const AGE_OPTIONS = [
  {
    labelTh: "3 - 12 ปี",
    labelEn: "3 - 12 years old",
    value: { start: 3, end: 12 },
  },
  {
    labelTh: "13 - 18 ปี",
    labelEn: "13 - 18 years old",
    value: { start: 13, end: 18 },
  },
  {
    labelTh: "19 - 24 ปี",
    labelEn: "19 - 24 years old",
    value: { start: 19, end: 24 },
  },
  {
    labelTh: "25 - 34 ปี",
    labelEn: "25 - 34 years old",
    value: { start: 25, end: 34 },
  },
  {
    labelTh: "35 - 44 ปี",
    labelEn: "35 - 44 years old",
    value: { start: 35, end: 44 },
  },
  {
    labelTh: "45 - 54 ปี",
    labelEn: "45 - 54 years old",
    value: { start: 45, end: 54 },
  },
  {
    labelTh: "65 ปีขึ้นไป",
    labelEn: "65+ years old",
    value: { start: 65, end: 0 },
  },
];

const PRICE_OPTIONS = [
  {
    labelTh: "0 – 10,000 บาท",
    labelEn: "0 – 10,000 THB",
    value: { start: 0, end: 10000 },
  },
  {
    labelTh: "10,000 - 50,000 บาท",
    labelEn: "10,000 - 50,000 THB",
    value: { start: 10000, end: 50000 },
  },
  {
    labelTh: "50,001 - 100,000 บาท",
    labelEn: "50,001 - 100,000 THB",
    value: { start: 50001, end: 100000 },
  },
  {
    labelTh: "100,001 - 300,000 บาท",
    labelEn: "100,001 - 300,000 THB",
    value: { start: 100001, end: 300000 },
  },
  {
    labelTh: "300,001 - 500,000 บาท",
    labelEn: "300,001 - 500,000 THB",
    value: { start: 300001, end: 500000 },
  },
  {
    labelTh: "500,001 - 1,000,000 บาท",
    labelEn: "500,001 - 1,000,000 THB",
    value: { start: 500001, end: 1000000 },
  },
  {
    labelTh: "มากกว่า 1,000,000 บาท",
    labelEn: "More than 1,000,000 THB",
    value: { start: 1000001, end: 1000002 },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(MusicalInstrumentContainer)));
