import React from "react";
import { BookingContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertControl } from "components/Modals/Alert";
import { MemberSwitchTypeWidget } from "widgets";
import { Buttons, Displays, Headers, Icons } from "components";

class BookingContainer extends React.Component {
  state = {
    isLoading: true,
    currentCase: 1,
    isSelected: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux } = this.props;
    let res = await userService.GET_MY_BOOKING_LIST(
      `?member_type=${authenRedux.member_type_active}`
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleNextCase = () => {
    this.setState((prevState) => ({
      currentCase: prevState.currentCase === 4 ? 1 : prevState.currentCase + 1, // Loop back to 1 after case 4
    }));
  };

  handleCancelBooking = async () => {
    const { i18n } = this.props;
    AlertControl.show({
      title:
        i18n.language === "en" ? "Confirm Cancellation" : "ยืนยันการยกเลิก",
      description:
        i18n.language === "en"
          ? "Do you want to cancel the booking?"
          : "คุณต้องการที่จะยกเลิกการจอง ใช่หรือไม่",
      btnLabel1: i18n.language === "en" ? "Back" : "กลับ",
      btnLabel2: i18n.language === "en" ? "Confirm" : "ตกลง",

      onEvent2: async () => {
        const { data } = this.state;
        this.setState({
          isBgLoading: true,
        });
        let params = {
          booking_id: data._id,
        };
        let res = await userService.POST_BOOKING_CANCEL(params);
        if (res && res.status === 200) {
          this.fetchData();
          toast.success("ยกเลิกการจองสำเร็จ");
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("ยกเลิกการจองไม่สำเร็จ กรุณาติดต่อเจ้าหน้าที่");
        }
      },
    });
  };

  handleButtonClick = (e) => {
    this.setState({ isSelected: false, category_id: e });
  };

  handleButtonGold = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.BOOKING}/${e}`);
  };

  renderContent = (e) => {
    const { data } = this.state;
    const { i18n } = this.props;
    switch (e.status) {
      case "1":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status yellow">Waiting</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={i18n.language === "en" ? "Cancel" : "ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
          </>
        );
      case "2":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status green">Approved</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="remark">
                {" "}
                {i18n.language === "en"
                  ? "In case you are unable to arrive on time, please cancel your reservation to maintain your right for future bookings."
                  : "*ในกรณีที่ท่านไม่สามารถมาตามเวลาได้ กรุณายกเลิกการจอง เพิ่อรักษาสิทธิ์ของท่านในการจองครั้งต่อไป"}
              </div>
              <div className="qr_sec">
                <QRCode
                  value={`showroom,${data.customer_ref_id},${data._id}`}
                  size={190}
                />
              </div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={i18n.language === "en" ? "Cancel" : "ยกเลิกการจอง"}
                  onClick={this.handleCancelBooking}
                />
              </div>
            </div>
          </>
        );
      case "3":
        return (
          <>
            <div className="status_detail">
              <div className="title">
                {i18n.language === "en"
                  ? "Booking Detail "
                  : "รายละเอียดการจองเวลา"}
              </div>
              <div className="detail_status">
                <div className="status orange">Rejected</div>
              </div>
              <div className="detail">
                <div className="detail_left">
                  <div className="title_detail">
                    {i18n.language === "en"
                      ? "Musical Instrument"
                      : "กลุ่มเครื่องดนตรี"}
                  </div>
                  <div className="title_detail">
                    {" "}
                    {i18n.language === "en" ? "Product" : "เครื่อง"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Model" : "รุ่น (ถ้ามี)"}
                  </div>
                  <div className="title_detail">
                    {i18n.language === "en" ? "Date" : "วันและเวลา"}
                  </div>
                </div>
                <div className="detail_right">
                  <div className="sub_title">
                    {i18n.language === "en"
                      ? e.instrument_type_en
                      : e.instrument_type_th}
                  </div>
                  <div className="sub_title">
                    {i18n.language === "en" ? e.product_en : e.product_th}
                  </div>
                  <div className="sub_title">{e.model_info || "-"}</div>
                  <div className="sub_title">
                    {i18n.language === "th" && "วัน"}
                    {moment(e.date).format("ddd DD MMMM YYYY")}
                  </div>
                  <div className="sub_title mb_20">
                    {i18n.language === "th" ? "เวลา" : "Time"} {e.start_time} -
                    {e.end_time} {i18n.language === "th" && "น."}
                  </div>
                </div>
              </div>
              <div className="remark">*{e.reason_reject}</div>
              <div className="btn_wrap">
                <Buttons.BgStandard
                  className="btn"
                  theme_standard
                  label={"ลงทะเบียนจองใหม่"}
                  onClick={() => {
                    this.setState({
                      data: false,
                    });
                  }}
                />
              </div>
            </div>
            {/* <div className="btn_wrap">
              <Buttons.BgStandard
                className="btn"
                theme_cancel
                label={"ไปหน้าลงทะเบียนแล้ว (Case 3)"}
                onClick={this.handleNextCase}
              />
            </div> */}
          </>
        );

      default:
        break;
    }
  };

  render() {
    const { i18n, t } = this.props;
    const { isBgLoading, isLoading, category_id, data, isSelected } =
      this.state;
    return (
      <BookingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            {isSelected ? (
              <>
                <Headers.PageHeader
                  icons={<Icons.SubMenuBooking />}
                  title={"FLAGSHIP STORE, BANGKOK"}
                  subTitle={
                    i18n.language === "en"
                      ? "Book a Music Instument Test"
                      : "จองทดสอบเครื่องดนตรี"
                  }
                />
                <div className="section">
                  <div className="activity_wrap">
                    <img
                      className="act_img"
                      alt="activity"
                      src={"/assets/images/backgrounds/booking-img.jpg"}
                    />
                  </div>
                  <div className="status_wrap">
                    <div className="title">
                      {t(
                        "Welcome to the Yamaha Flagship Store,Bangkok & YDACC (Yamaha Digital Audio Creative Center)",
                        { ns: "booking" }
                      )}
                    </div>
                    <div className="detail">
                      {t(
                        `To book your music instrument test or showroom visit, please click "Register" and fill out the form with your details, selecting your preferred time slot. Once your form is submitted, our team will update you on the status of your booking.`,
                        { ns: "booking" }
                      )}
                    </div>
                    <div className="detail bd">
                      {i18n.language === "en" ? `Location:\n` : `ที่อยู่:\n`}
                      <span>
                        {t(
                          "Siam Patumwan House, M Floor \n414 Phayathai Road, Wang Mai Sub District, \nPathum Wan District, Bangkok, \nthailand, 10330",
                          { ns: "booking" }
                        )}
                      </span>
                    </div>

                    <div className="detail bd">
                      Google Map:{" "}
                      <a
                        href="https://g.co/kgs/pZLf7Xc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://g.co/kgs/pZLf7Xc
                      </a>
                    </div>
                  </div>
                  {!data || data.status === "4" ? (
                    <>
                      <div className="btn_wrap">
                        <Buttons.BgStandard
                          theme_standard
                          label={t("Book a Musical Instrument Test", {
                            ns: "booking",
                          })}
                          onClick={() => this.handleButtonClick("1")}
                        />
                      </div>
                      <div className="btn_wrap">
                        <Buttons.BgStandard
                          theme_standard
                          label={t("Book a Professional Audio Demo", {
                            ns: "booking",
                          })}
                          onClick={() => this.handleButtonClick("2")}
                        />
                      </div>
                      <div className="btn_wrap">
                        <button
                          className="gold_btn"
                          onClick={() => this.handleButtonGold("3")}
                        >
                          {t("Book", { ns: "booking" })}
                          <Icons.GoldBtnIcon />
                        </button>
                      </div>
                    </>
                  ) : (
                    this.renderContent(data)
                  )}
                </div>
              </>
            ) : (
              <>
                <Headers.PageHeader
                  icons={<Icons.SubMenuBooking />}
                  title={"FLAGSHIP STORE, BANGKOK"}
                  subTitle={
                    category_id === "1"
                      ? i18n.language === "en"
                        ? "Book a Music Instument Test"
                        : "จองทดสอบเครื่องดนตรี"
                      : i18n.language === "en"
                      ? "Book a Professional Audio Demo"
                      : "จองทดสอบเครื่องเสียงมืออาชีพ"
                  }
                />
                {category_id === "1" ? (
                  <div className="desc">
                    {i18n.language === "en"
                      ? "Please provide the following details so that we can prepare the musical instruments for testing or arrange a suitable visit based on your objectives"
                      : "กรุณากรอกรายละเอียด เพื่อเราสามารถเตรียมเครื่องดนตรีสำหรับทดลองหรือสถานที่ สำหรับการเยี่ยมชม"}
                  </div>
                ) : (
                  <div className="desc">
                    {i18n.language === "en"
                      ? "Please provide the following details so that we can prepare the audio equipment for testing or arrange a suitable visit based on your objectives"
                      : "กรุณากรอกรายละเอียด เพื่อเราสามารถเตรียมเครื่องเสียงสำหรับทดลองหรือสถานที่ สำหรับการเยี่ยมชม"}
                  </div>
                )}
                <MemberSwitchTypeWidget
                  type="3"
                  onChange={() => {
                    this.props.router.navigate(
                      `${ROUTE_PATH.BOOKING}/${category_id}`
                    );
                  }}
                />
                <div className="btn_back">
                  <Buttons.BgStandard
                    className="btn"
                    label={t("Back", { ns: "booking" })}
                    onClick={() => {
                      this.setState({ isSelected: true });
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </BookingContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["booking"])(withRouter(BookingContainer)));
