import styled from "styled-components";

export const DateTimeStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .input {
    width: 100%;
    .react-datetime-picker__wrapper {
      border-radius: 21px;
      border: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      padding: 0 15px;
      width: 100%;
      height: 42px;
      background: transparent;
      text-align: left;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
    .input_calendar {
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      .react-calendar__navigation__label {
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .theme_standard {
  }

  &.theme_bosendorfer {
    .input {
      .react-datetime-picker__wrapper {
        border-radius: 0px;
        border: 1px solid ${({ theme }) => theme.COLORS.GOLD_1};
        color: ${({ theme }) => theme.COLORS.GOLD_1};
        font-family: ${({ theme }) => theme.FONT.STYLE.BRANDON_REGULAR};
      }
    }
  }
`;
