import React from "react";
import { BookingBosendorferStep2FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { CelendarWidget } from "widgets";
import { Buttons, Inputs } from "components";

const BookingBosendorferStep2Form = ({
  startDate,
  endDate,
  timeSlotData,
  holidayData,
  onClickPrevWeek,
  onClickNextWeek,
  onSubmit,
  onBack,
}) => {
  const schema = yup.object().shape({
    date: yup.string().required("New password is required"),
    time: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <BookingBosendorferStep2FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="section">
          <div className="header">
            <div className="title">
              Musical Instrument Test, Showroom Visit Booking
            </div>
            <div className="sub_title">2. Please check time slot</div>
          </div>
          <CelendarWidget
            theme_bosendorfer
            startDate={startDate}
            endDate={endDate}
            timeSlotData={timeSlotData}
            holidayData={holidayData}
            onClickPrevWeek={onClickPrevWeek}
            onClickNextWeek={onClickNextWeek}
          />
          <div className="f_title">
            3. Please select time slot that you convenient
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  theme_bosendorfer
                  minDate={new Date(moment().format("YYYY-MM-DD"))}
                  maxDate={
                    new Date(moment().add(14, "day").format("YYYY-MM-DD"))
                  }
                  placeholder={"เลือกวันที่ต้องการจอง"}
                  errors={errors.date?.message}
                />
              )}
              name="date"
            />
          </div>
          <div className="input_wrap ml_50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Radio
                  {...field}
                  theme_bosendorfer
                  options={[
                    // { label: "09.00 - 10.00", value: "09.00,10.00" },
                    { label: "10.00 - 11.00", value: "10.00,11.00" },
                    { label: "11.00 - 12.00", value: "11.00,12.00" },
                    { label: "13.00 - 14.00", value: "13.00,14.00" },
                    { label: "14.00 - 15.00", value: "14.00,15.00" },
                    { label: "15.00 - 16.00", value: "15.00,16.00" },
                    { label: "16.00 - 17.00", value: "16.00,17.00" },
                    { label: "17.00 - 18.00", value: "17.00,18.00" },
                  ]}
                  errors={errors.time?.message}
                />
              )}
              name="time"
              defaultValue={""}
            />
          </div>
          <div className="f_action_row">
            <div className="btn_wrap">
              <Buttons.BgStandard
                theme_bosendorfer
                onClick={onBack}
                label="Back"
                // disabled={isSubmitting || !isValid}
              />
            </div>
            <div className="btn_wrap">
              <Buttons.BgStandard
                theme_bosendorfer
                label="Next"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </form>
    </BookingBosendorferStep2FormStyled>
  );
};

BookingBosendorferStep2Form.propTypes = {};

export default BookingBosendorferStep2Form;
