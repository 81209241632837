import React, { useState } from "react";
import { PhoneNumberFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { userService } from "apiServices";
import VerifyOTPModalContainer from "containers/VerifyOTPModalContainer";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Buttons, Displays, Inputs, Modals } from "components";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PhoneNumberForm = ({ lineID, onStep2, onSuccess }) => {
  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required("กรุณากรอก")
      .matches(phoneRegExp, "กรอกเบอร์โทรศัพท์ผิดพลาด"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t, i18n } = useTranslation(["common", "form"]);

  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_renderModal, _setRenderModal] = useState(false);

  const _handleCloseModal = () => {
    const body = document.body;
    body.style.overflow = "auto";
    _setRenderModal(false);
  };

  const _onSubmit = async (values) => {
    if (!lineID) {
      AlertControl.show({
        title: t("Notification", { ns: "form" }),
        description: "ไม่สามารถทำรายการนี้ได้ เนื่องจากท่านได้สมัครผิดช่องทาง",
        btnLabel2: "กลับ",
      });
    } else {
      _setRenderModal(
        <VerifyOTPModalContainer
          memberType={"1"}
          phoneNumber={values.phone_number}
          onCancel={_handleCloseModal}
          onVerifySuccess={async () => {
            _setIsBgLoading(true);
            let resArr = await Promise.all([
              userService.GET_CUSTOMER_ACCOUNT_BY_PROFILE(values.phone_number),
            ]);
            if (resArr) {
              if (resArr[0].status !== 200 || resArr[0].data.length === 0) {
                _setIsBgLoading(false);
                AlertControl.show({
                  title: t("Notification", { ns: "form" }),
                  description:
                    i18n.language === "en"
                      ? "We’re sorry, the transaction cannot be completed because your phone number is not found in our system.\n\n If you are a new member, please select‘First-time Registration’."
                      : "ขออภัย ไม่สามารถทำรายการได้ เนื่องจากไม่มีเบอร์โทรศัพท์ของท่านในระบบสมาชิก\n\n ในกรณีที่ท่านสมัครสมาชิกใหม่ กรุณาเลือกเมนู “ลงทะเบียนครั้งแรก”",
                  btnLabel2: t("Back", { ns: "common" }),
                });
              } else {
                if (resArr[0].data.length === 1) {
                  if (
                    !resArr[0].data[0].register_type ||
                    resArr[0].data[0].register_type === "2" ||
                    resArr[0].data[0].register_type === "4"
                  ) {
                    _setIsBgLoading(false);
                    AlertControl.show({
                      title: t("Notification", { ns: "form" }),
                      description:
                        "กรุณาแจ้งเจ้าหน้าที่ เพื่อปลดล็อคเบอร์โทรศัพท์",
                      btnLabel2: t("Back", { ns: "common" }),
                    });
                  } else {
                    let params = {
                      customer_id: resArr[0].data[0]._id,
                      line_id: lineID,
                      phone_number: values.phone_number,
                    };
                    let res = await userService.POST_CUSTOMER_SYNC_LINE(params);
                    if (res && res.status === 200) {
                      _setIsBgLoading(false);
                      toast.success("ทำรายการสำเร็จ");
                      onSuccess && onSuccess();
                    } else {
                      _setIsBgLoading(false);
                      AlertControl.show({
                        title: t("Notification", { ns: "form" }),
                        description:
                          "ไม่สามารถทำรายการนี้ได้ กรุณาติดต่อโรงเรียนดนตรียามาฮ่าสาขาของท่าน",
                        btnLabel2: t("Back", { ns: "common" }),
                      });
                    }
                  }
                } else {
                  let dataGroupRelink = resArr[0].data.reduce(
                    (result, data) => {
                      switch (data.register_type) {
                        case "1":
                        case "3":
                          result.dataReadyRelink.push({
                            ...data,
                            phoneNumberInput: values.phone_number,
                          });
                          break;
                        default:
                          result.dataNotUseRelink.push(data);
                          break;
                      }
                      return result;
                    },
                    {
                      dataReadyRelink: [],
                      dataNotUseRelink: [],
                    }
                  );
                  if (dataGroupRelink.dataReadyRelink.length === 0) {
                    _setIsBgLoading(false);
                    AlertControl.show({
                      title: t("Notification", { ns: "form" }),
                      description:
                        "กรุณาแจ้งเจ้าหน้าที่ เพื่อปลดล็อคเบอร์โทรศัพท์ของท่าน",
                      btnLabel2: t("Back", { ns: "common" }),
                    });
                  } else {
                    if (dataGroupRelink.dataReadyRelink.length === 1) {
                      let params = {
                        customer_id: dataGroupRelink.dataReadyRelink[0]._id,
                        line_id: lineID,
                        phone_number: values.phone_number,
                      };
                      let res = await userService.POST_CUSTOMER_SYNC_LINE(
                        params
                      );
                      if (res && res.status === 200) {
                        _setIsBgLoading(false);
                        toast.success("ทำรายการสำเร็จ");
                        onSuccess && onSuccess();
                      } else {
                        _setIsBgLoading(false);
                        AlertControl.show({
                          title: t("Notification", { ns: "form" }),
                          description:
                            "ไม่สามารถทำรายการนี้ได้ กรุณาติดต่อโรงเรียนดนตรียามาฮ่าสาขาของท่าน",
                          btnLabel2: t("Back", { ns: "common" }),
                        });
                      }
                    } else {
                      _setIsBgLoading(false);
                      _handleCloseModal();
                      onStep2 && onStep2(dataGroupRelink.dataReadyRelink);
                    }
                  }
                }
              }
            }
          }}
        />
      );
    }
  };

  return (
    <>
      <PhoneNumberFormStyled>
        <Displays.BGLoading visible={_isBgLoading} />
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className="label">กรุณาใส่เบอร์โทรที่ท่านลงทะเบียน</div>
          <div className="label mb">Please insert Phone Number</div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={"*เบอร์โทรศัพท์ / Phone Number"}
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
          <div className="btn_submit_wrap">
            <Buttons.BgStandard
              type="submit"
              label={t("Proceed", { ns: "common" })}
            />
          </div>
        </form>
      </PhoneNumberFormStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </>
  );
};

PhoneNumberForm.propTypes = {};

export default PhoneNumberForm;
