import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { HeaderWarrantyStatusStyled } from "./styled";
import { Icons } from "components";
import { useTranslation } from "react-i18next";

const HeaderWarrantyStatus = ({
  theme_standard,
  theme_head_only,
  type,
  mb,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_head_only: theme_head_only,
  });
  const { i18n } = useTranslation(["Common"]);
  return (
    <HeaderWarrantyStatusStyled mb={mb}>
      <div className={customClass}>
        {type === "1" ? (
          <div className="content">
            <div className="hdw_icon_wrap">
              <Icons.MemberPerson2 />
            </div>
            <span>
              {i18n.language === "en"
                ? "You are in the process of registering as an individual.."
                : "ท่านกำลังลงทะเบียนแบบ บุคคล"}
            </span>
          </div>
        ) : (
          <div className="content">
            <div className="hdw_icon_wrap">
              <Icons.MemberCoperate />
            </div>
            <span>
              {i18n.language === "en"
                ? "You are registering as an organization."
                : "ท่านกำลังลงทะเบียนแบบ องค์กร"}
            </span>
          </div>
        )}
      </div>
    </HeaderWarrantyStatusStyled>
  );
};

HeaderWarrantyStatus.propTypes = {};

export default HeaderWarrantyStatus;
