import React from "react";
import { connect } from "react-redux";
import { ProductWarranteeDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { AlertControl } from "components/Modals/Alert";
import { BtnScanQRWidget, MemberSwitchTypeWidget } from "widgets";
import { Buttons, Cards, Displays, Forms, Headers, Icons } from "components";

class ProductWarranteeDetailContainer extends React.Component {
  state = {
    step: 1,
    isLoading: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (
      router.params?.id &&
      router.params?.id !== prevProps.router.params?.id
    ) {
      if (prevProps.router.params?.id !== "register") {
        this.setState({
          memberTypeRegisted: false,
          step: 1,
        });
      } else {
        this.setState({
          isLoading: true,
        });
        this.fetchData();
      }
    }
  }

  fetchData = async () => {
    const { router, t } = this.props;
    let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
      router.params?.id
    );
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    } else {
      toast.error(t("Incorrect serial number", { ns: "common" }));
      this.setState({
        step: 1,
      });
    }
  };

  // STEP 1 CHOOSE MEMBER TYPE
  // ==================================
  // ==================================
  handleSubmitStep1 = (e) => {
    const { router } = this.props;
    if (!router.params?.id || router.params?.id === "register") {
      this.setState({
        step: 2,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: true,
        step: 3,
      });
      this.fetchData();
    }
  };

  // STEP 2 SCAN QRCODE PRODUCT WARRANTY
  // ==================================
  // ==================================
  handleSuccessScan = async (sn) => {
    const split = sn.split("/");
    const serialNo = split[split.length - 1];
    switch (serialNo) {
      case "showroom":
        this.handleShowRoomScan();
        break;
      default:
        this.setState({
          isBgLoading: true,
        });
        let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
          serialNo
        );
        if (res && res.status === 200) {
          this.setState({
            isLoading: true,
            step: 3,
          });
          // this.fetchData();
          this.props.router.navigate(
            `${ROUTE_PATH.PRODUCT_WARRANTEE}/${serialNo}`
          );
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error("เลขซีเรียลไม่ถูกต้อง");
        }
        break;
    }
  };
  handleShowRoomScan = async () => {
    AlertControl.show({
      title: "ยืนยันการเข้าโชว์รูม",
      description: "คุณต้องการเชื่อมข้อมูลการเข้าใช้โขว์รูม ใช่หรือไม่",
      btnLabel1: "กลับ",
      btnLabel2: "ตกลง",
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await userService.POST_SHOWROOM_SCAN_QR_BY_CUSTOMER();
        if (res && res.status === 200) {
          this.setState({
            isBgLoading: false,
          });
          toast.success("เชื่อมข้อมูลการเข้าใช้โชว์รูมสำเร็จ");
          this.props.router.navigate(ROUTE_PATH.HOME);
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error(
            "เชื่อมข้อมูลไม่สำเร็จ เนื่องจากไอดีของท่านถูกแบนหรือวันนี้มีการเชื่อมข้อมูลแล้ว กรุณาติดต่อเจ้าหน้าที่"
          );
        }
      },
    });
  };
  handlePrevStep2 = () => {
    this.setState({
      step: 1,
    });
  };

  // STEP 3 SCAN QRCODE PRODUCT WARRANTY
  // ==================================
  // ==================================
  handleAddProductWarranty = async (sn) => {
    const { productWarrantyAddMore } = this.state;
    this.setState({
      isBgLoading: true,
    });
    const split = sn.split("/");
    const serialNo = split[split.length - 1];
    let hasDuplicate = !productWarrantyAddMore
      ? false
      : productWarrantyAddMore.some(
          (e) =>
            e.serial_no === serialNo ||
            `${e.serial_no}-${e.item_no}` === serialNo
        );
    if (hasDuplicate) {
      this.setState({
        isBgLoading: false,
      });
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "Serial no นี้ ถูกใช้แล้ว",
      });
    } else {
      let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(serialNo);
      if (res && res.status === 200) {
        if (res.data.status !== "1") {
          this.setState({
            isBgLoading: false,
          });
          toast.error("เลขประกันของท่านถูกใช้แล้ว กรุณาติดต่อเจ้าหน้าที่");
        } else {
          let temp = !productWarrantyAddMore ? [] : [...productWarrantyAddMore];
          temp.push(res.data);
          this.setState({
            productWarrantyAddMore: [...temp],
            isBgLoading: false,
          });
        }
      } else {
        toast.error("ไม่มี Serial no นี้ในระบบ");
      }
    }
  };
  handleDeleteProductAddMore = (index) => {
    const { productWarrantyAddMore } = this.state;
    let temp = [...productWarrantyAddMore];
    temp.splice(index, 1);
    this.setState({
      productWarrantyAddMore: [...temp],
    });
  };
  handleSubmitStep3 = async (values) => {
    this.handleNextStep();
    const { authenRedux, t } = this.props;
    const { data, productWarrantyAddMore } = this.state;
    this.setState({
      isBgLoading: true,
    });
    if (
      !values?.image_file ||
      values?.image_file.filter((e) => e.isNewUpload).length === 0
    ) {
      toast.error(t("Please upload your receipt slip.", { ns: "common" }));
    } else {
      const bodyFormData = new FormData();
      bodyFormData.set("member_type", authenRedux.member_type_active);
      switch (authenRedux.member_type_active) {
        case "1":
          bodyFormData.set("product_warrantee_id", data._id);
          bodyFormData.append("image", values?.image_file[0]);
          break;
        case "2":
          const productWarrantyList = [{ product_warrantee_id: data._id }];
          if (productWarrantyAddMore && productWarrantyAddMore.length > 0) {
            productWarrantyAddMore.forEach((e) => {
              productWarrantyList.push({ product_warrantee_id: e._id });
            });
          }
          bodyFormData.set(
            "organization_id",
            authenRedux.organization_detail._id
          );
          bodyFormData.set(
            "product_warranty_list",
            JSON.stringify(productWarrantyList)
          );
          bodyFormData.append("image", values?.image_file[0]);
          break;
        default:
          break;
      }
      let res =
        authenRedux.member_type_active === "1"
          ? await userService.POST_UPLOAD_SLIP(bodyFormData)
          : await userService.POST_UPLOAD_COPERATE_REGIS(bodyFormData);
      if (res && res.status === 200) {
        this.setState({
          isBgLoading: false,
        });
        toast.success(t("Successfully registered", { ns: "common" }));
        this.handleNextStep();
      } else {
        this.setState({
          isBgLoading: false,
        });
        toast.error(t("Register fail", { ns: "common" }));
      }
    }
  };

  handleNextStep = () => {
    const { router } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.PRODUCT_WARRANTEE}/${router.params?.id}/success`
    );
  };

  render() {
    const { t, i18n, authenRedux } = this.props;
    const { isLoading, isBgLoading, data, productWarrantyAddMore, step } =
      this.state;
    return (
      <ProductWarranteeDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.PageHeader
          icons={<Icons.Warranty />}
          title={"PRODUCT WARRANTY REGISTRATION"}
          subTitle={"ลงทะเบียนรับประกันสินค้า"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            {step === 1 && (
              <MemberSwitchTypeWidget
                type="3"
                onChange={(e) => this.handleSubmitStep1(e)}
              />
            )}
            {step === 2 && (
              <div className="content2">
                <Displays.HeaderWarrantyStatus
                  type={authenRedux.member_type_active}
                />
                <div className="content_input">
                  <Forms.ScanQRCodeForm
                    onSuccessScan={this.handleSuccessScan}
                  />
                </div>
                <div className="btn_sec">
                  <div className="btn_edit">
                    <Buttons.BgStandard
                      label={i18n.language === "en" ? "Back" : "ย้อนกลับ"}
                      onClick={this.handlePrevStep2}
                    />
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <>
                <div className="product_info_wrap">
                  <Displays.HeaderWarrantyStatus
                    type={authenRedux.member_type_active}
                  />
                  <Cards.ProductWarrantee
                    isEdit={false}
                    src={data.image}
                    title={
                      data.pd_dep_desc +
                      " " +
                      data.pd_cat02_desc +
                      " " +
                      data.pd_cat03_desc
                    }
                    description={data.description}
                    descriptionLine2={data.description_line_2}
                    item_id={data.item_no}
                    sn={data.serial_no}
                    warrantyPeriod={data.warranty_period}
                    warrantyUnit={data.warranty_unit}
                    warrantyDescription={data.warranty_description_th}
                    approvedWarrantyDate={data.approved_warranty_date}
                    extensionPeriod={data.extension_period}
                    extensionUnit={data.extension_unit}
                    rejectedReason={data.rejected_reason}
                    status={data.status}
                  />
                </div>
                {authenRedux.member_type_active === "2" && (
                  <>
                    {productWarrantyAddMore &&
                      productWarrantyAddMore.map((e, i) => (
                        <div key={i} className="product_more_item">
                          <Cards.ProductWarrantee
                            description={e.description}
                            descriptionLine2={e.description_line_2}
                            sn={e.serial_no}
                            warrantyPeriod={e.warranty_period}
                            warrantyUnit={e.warranty_unit}
                            warrantyDescription={e.warranty_description_th}
                            status={e.status}
                            approvedWarrantyDate={e.approved_warranty_date}
                            extensionPeriod={e.extension_period}
                            extensionUnit={e.extension_unit}
                            rejectedReason={e.rejected_reason}
                            isDelete
                            onDelete={() =>
                              this.handleDeleteProductAddMore(i, e)
                            }
                          />
                        </div>
                      ))}
                    <div className="btn_add_sn">
                      <BtnScanQRWidget
                        onScanSuccess={this.handleAddProductWarranty}
                      />
                    </div>
                  </>
                )}
                {data && data.status !== "1" && data.status !== "5" ? (
                  <Displays.AlertLabel
                    label={[
                      t("Warranty information", { ns: "common" }),
                      t("Already registered", { ns: "common" }),
                    ]}
                  />
                ) : (
                  <Forms.ProductWarranteeForm
                    onSubmit={this.handleSubmitStep3}
                  />
                )}
              </>
            )}
          </>
        )}
      </ProductWarranteeDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(ProductWarranteeDetailContainer)));
