import React from "react";

export default function renderIcon({
  width = 14,
  height = 14,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <g
        id="Group_526"
        data-name="Group 526"
        transform="translate(-0.092 -0.257)"
      >
        <g
          id="Ellipse_2598"
          data-name="Ellipse 2598"
          transform="translate(0.092 0.257)"
          fill="#4b1e78"
          stroke={color}
          stroke-width="0.5"
        >
          <circle cx="7" cy="7" r="7" stroke="none" />
          <circle cx="7" cy="7" r="6.75" fill="none" />
        </g>
        <g
          id="Group_417"
          data-name="Group 417"
          transform="translate(4.401 3.437)"
        >
          <g id="Group_416" data-name="Group 416">
            <path
              id="Path_4147"
              data-name="Path 4147"
              d="M0,0,3.3,1.936,5.864,3.443"
              fill="none"
              stroke={color}
              stroke-width="0.5"
            />
            <line
              id="Line_215"
              data-name="Line 215"
              y1="3.443"
              x2="5.864"
              transform="translate(0 3.443)"
              fill="none"
              stroke={color}
              stroke-width="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
