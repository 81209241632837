import BgIconPrefix from "./BgIconPrefix";
import BgStandard from "./BgStandard";
import BtnViewMoreExpand from "./BtnViewMoreExpand";

const EXPORT = {
  BgIconPrefix,
  BgStandard,
  BtnViewMoreExpand,
};

export default EXPORT;
