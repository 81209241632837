import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { NewInfoStyled } from "./styled";
import { IMG_PATH } from "utils/constants/imgPath";

const NewInfo = ({
  theme_standard,
  title,
  src = "/assets/images/mockups/new-1.jpg",
  description,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <NewInfoStyled>
      <div className={customClass}>
        <div className="new_info_container">
          <div className="ni_title">{title}</div>
          <img
            className="ni_img"
            alt="new yamaha"
            src={src ? IMG_PATH + src : "/assets/images/mockups/new-1.jpg"}
          />
          <div />
          <div className="ni_description">{description}</div>
        </div>
      </div>
    </NewInfoStyled>
  );
};

NewInfo.propTypes = {};

export default NewInfo;
