import React from "react";

export default function renderIcon({
  width = 34.318,
  height = 32.514,
  color = "#4B1E78",
  isActive,
}) {
  return !isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2367"
            data-name="Rectangle 2367"
            width="34.318"
            height="32.514"
            transform="translate(0 0)"
            fill="none"
            stroke="#4b1e78"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_835"
        data-name="Group 835"
        transform="translate(10543.404 3100.739)"
      >
        <g id="Group_834" data-name="Group 834">
          <path
            id="Path_4773"
            data-name="Path 4773"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10543.404 -3100.739)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_794"
          data-name="Group 794"
          transform="translate(-10536 -3088.636)"
        >
          <g
            id="Group_738"
            data-name="Group 738"
            transform="translate(0.084 -7)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_4713"
              data-name="Path 4713"
              d="M31.729,31.514H2.588A1.588,1.588,0,0,1,1,29.926V13.807a1.591,1.591,0,0,1,.619-1.259L16.19,1.33a1.588,1.588,0,0,1,1.938,0L32.7,12.548a1.588,1.588,0,0,1,.619,1.259V29.926A1.588,1.588,0,0,1,31.729,31.514Z"
              fill="none"
              stroke="#4B1E78"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_4714"
              data-name="Path 4714"
              d="M22.055,31.2V24.5a2.969,2.969,0,0,0-2.791-3.119H15.053A2.969,2.969,0,0,0,12.262,24.5v6.7c0,.108.019.21.028.315h9.737c.009-.1.028-.207.028-.315"
              fill="none"
              stroke="#4b1e78"
              strokeWidth="1"
            />
            <path
              id="Path_4715"
              data-name="Path 4715"
              d="M22.055,31.2V24.5a2.969,2.969,0,0,0-2.791-3.119H15.053A2.969,2.969,0,0,0,12.262,24.5v6.7c0,.108.019.21.028.315h9.737C22.036,31.41,22.055,31.308,22.055,31.2Z"
              fill="none"
              stroke="#4B1E78"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2367"
            data-name="Rectangle 2367"
            width="34.318"
            height="32.514"
            transform="translate(0 0)"
            fill="none"
            stroke="#4b1e78"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_835"
        data-name="Group 835"
        transform="translate(10543.404 3100.739)"
      >
        <g id="Group_834" data-name="Group 834">
          <path
            id="Path_4773"
            data-name="Path 4773"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10543.404 -3100.739)"
            fill="#4b1e78"
          />
        </g>
        <g
          id="Group_794"
          data-name="Group 794"
          transform="translate(-10536 -3088.636)"
        >
          <g
            id="Group_738"
            data-name="Group 738"
            transform="translate(0.084 -7)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_4713"
              data-name="Path 4713"
              d="M31.729,31.514H2.588A1.588,1.588,0,0,1,1,29.926V13.807a1.591,1.591,0,0,1,.619-1.259L16.19,1.33a1.588,1.588,0,0,1,1.938,0L32.7,12.548a1.588,1.588,0,0,1,.619,1.259V29.926A1.588,1.588,0,0,1,31.729,31.514Z"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_4714"
              data-name="Path 4714"
              d="M22.055,31.2V24.5a2.969,2.969,0,0,0-2.791-3.119H15.053A2.969,2.969,0,0,0,12.262,24.5v6.7c0,.108.019.21.028.315h9.737c.009-.1.028-.207.028-.315"
              fill="none"
              stroke="#4b1e78"
              strokeWidth="1"
            />
            <path
              id="Path_4715"
              data-name="Path 4715"
              d="M22.055,31.2V24.5a2.969,2.969,0,0,0-2.791-3.119H15.053A2.969,2.969,0,0,0,12.262,24.5v6.7c0,.108.019.21.028.315h9.737C22.036,31.41,22.055,31.308,22.055,31.2Z"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
