import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ScanQRCodeStyled } from "./styled";
import QrReader from "react-qr-scanner";
import { Icons } from "components";

const ScanQRCode = ({
  label = "Scan QR Warranty",
  theme_standard,
  onSuccessScan,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_isOpen, setIsOpen] = useState(false);

  const handleClickScan = async () => {
    setIsOpen(true);
  };

  const _successScan = (e) => {
    if (e) {
      setIsOpen(false);
      onSuccessScan && onSuccessScan(e.text);
    }
  };

  return (
    <ScanQRCodeStyled>
      <div className={customClass}>
        {!_isOpen ? (
          <div className="scan_qr_container" onClick={handleClickScan}>
            <div className="sq_block">
              <div className="sq_label">{label}</div>
              <Icons.Scan width={30} />
            </div>
          </div>
        ) : (
          <div className="scan_show">
            <QrReader
              delay={300}
              style={{
                width: 240,
                height: 240,
              }}
              constraints={{
                video: {
                  facingMode: { exact: `environment` },
                },
              }}
              onError={() => {}}
              onScan={_successScan}
            />
            {/* <button
              type="button"
              onClick={() =>
                onSuccessScan(
                  "IKJ266436-V261320"
                )
              }
            >
              Test
            </button> */}
          </div>
        )}
      </div>
    </ScanQRCodeStyled>
  );
};

ScanQRCode.propTypes = {};

export default ScanQRCode;
