import React from "react";
import { connect } from "react-redux";
import { ActivityHistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/formatMoney";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { MemberSwitchTypeWidget } from "widgets";
import { Buttons, Displays, Tables } from "components";

class ActivityHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?member_type=${this.props.authenRedux.member_type_active}`);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authenRedux &&
      this.props.authenRedux !== prevProps.authenRedux
    ) {
      this.fetchData(
        `?member_type=${this.props.authenRedux.member_type_active}`
      );
    }
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_ACTIVITY(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  render() {
    const { i18n, router, t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <ActivityHistoryContainerStyled>
        <div className="member_type_layout">
          <MemberSwitchTypeWidget type="4" />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="product_warranty_title">
              {i18n.language === "en"
                ? "Activity Registration History"
                : "ประวัติการลงทะเบียนกิจกรรม"}
            </div>
            <Tables.HeaderRowPanel
              columns={columns({ i18n })}
              data={data}
              onClickRow={this.handleClickHistory}
            />
          </>
        )}
        <div className="btn_wrap">
          <Buttons.BgStandard
            label={t("Back", { ns: "common" })}
            onClick={() => router.navigate(-1)}
          />
        </div>
      </ActivityHistoryContainerStyled>
    );
  }
}

const columns = ({ i18n }) => [
  {
    title: i18n.language === "en" ? "Date" : "วันที่",
    field: "created_at",
    headerStyle: { width: "184px", textAlign: "center" },
    style: { width: 184, textAlign: "center" },
    render: ({ value }) => moment(value).format("DD-MM-YYYY"),
  },
  {
    title: i18n.language === "en" ? "List" : "รายการ",
    field: "activity_name",
    headerStyle: { textAlign: "center" },
  },
  {
    title: i18n.language === "en" ? "Point" : "คะแนน",
    field: "activity_point",
    headerStyle: { width: "184px", textAlign: "center" },
    style: { width: 184, textAlign: "center" },
    render: ({ origin, value }) => `${RenderCommaMoney(value)} Points`,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common", "profile"])(withRouter(ActivityHistoryContainer)));
