import styled from "styled-components";

export const SpecialPointsContainerStyled = styled.div`
  padding: 60px 18px 104px 18px;
  .header_sec {
    display: flex;
  }
  .menu_item_wrap {
    margin-bottom: 16px;
  }
`;
