import styled from "styled-components";

export const DateTimePickerStyled = styled.div`
  .label {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .input_container {
    display: flex;
    column-gap: 12px;
    .input_item {
      position: relative;
      width: 100%;
      .select-label {
        position: absolute;
        top: -8px;
        left: 10px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        color: gray;
        z-index: 50;
        padding: 0 5px;
      }
    }

    .input {
      .react-select__control {
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
        border-radius: 4px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        min-height: 35px;
      }

      .react-select__menu {
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-radius: 4px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }

      .react-select__option {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        padding: 10px;
      }

      .react-select__option--is-selected {
        background: ${({ theme }) => theme.COLORS.PRIMARY};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }

      .react-select__option--is-focused {
        background: ${({ theme }) => theme.COLORS.GRAY_2};
      }
    }
  }
`;
