import styled from "styled-components";

export const ProductWarranteeFormStyled = styled.div`
  padding: 0 27px;
  .input_wrap {
    margin-bottom: 32px;
  }
  .btn_submit_wrap {
    margin: auto;
  }
`;
