import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BgStandardStyled } from "./styled";

const BgStandard = ({
  theme_stadard_btn,
  theme_cancel,
  theme_standard_border,
  theme_bosendorfer,
  type = "button",
  label,
  onClick,
  disabled,
  bgColor,
  txtColor,
  height,
}) => {
  const customClass = cx({
    theme_stadard_btn: theme_stadard_btn,
    theme_cancel: theme_cancel,
    theme_standard_border: theme_standard_border,
    theme_bosendorfer: theme_bosendorfer
  });
  return (
    <BgStandardStyled
      disabled={disabled}
      bgColor={bgColor}
      txtColor={txtColor}
      height={height}
    >
      <div className={customClass}>
        <button
          type={type}
          disabled={disabled}
          className="btn"
          onClick={onClick}
        >
          {label}
        </button>
      </div>
    </BgStandardStyled>
  );
};

BgStandard.propTypes = {};

export default BgStandard;
