import React, { useEffect } from "react";
import { InterestedFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";
import { useTranslation } from "react-i18next";

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const InterestedForm = ({ initialValue, onSubmit }) => {
  const schema = yup.object().shape({
    // personal_first_name: yup.string().required("*Required"),
    // personal_last_name: yup.string().required("*Required"),
    // personal_email: yup.string().email().required("*Required"),
    // personal_phone_number: yup
    //   .string()
    //   .required("*Required")
    //   .matches(phoneRegExp, "*Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValue) {
      reset({ ...initialValue });
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { i18n } = useTranslation([]);

  return (
    <InterestedFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="header">
          <div className="title">
            {i18n.language === "en" ? "Other interests" : "ความสนใจอื่นๆ"}
          </div>
          <div className="sub_title">
            {i18n.language === "en"
              ? "To present special activities for your specific preferences"
              : "เพื่อนำเสนอ กิจกรรมสุดพิเศษ สำหรับความชอบเฉพาะของคุณ"}
          </div>
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                placeholder={
                  i18n.language === "en"
                    ? "Your favorite music genre"
                    : "แนวเพลงที่ท่านชอบ"
                }
                errors={errors.personal_first_name?.message}
              />
            )}
            name="question1"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                placeholder={
                  i18n.language === "en"
                    ? "Your favorite musical instrument"
                    : "เครื่องดนตรีที่ท่านชอบ"
                }
                errors={errors.personal_last_name?.message}
              />
            )}
            name="question2"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                placeholder={
                  i18n.language === "en" ? "Other questions" : "คำถามอื่นๆ"
                }
                errors={errors.personal_email?.message}
              />
            )}
            name="question3"
            defaultValue=""
          />
        </div>
        <div className="input_wrap ">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                theme_br50
                {...field}
                isRequire
                placeholder={
                  i18n.language === "en" ? "Other questions" : "คำถามอื่นๆ"
                }
                errors={errors.personal_phone_number?.message}
              />
            )}
            name="question4"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_32">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                theme_br50
                {...field}
                isRequire
                placeholder={
                  i18n.language === "en" ? "Other questions" : "คำถามอื่นๆ"
                }
                errors={errors.personal_phone_number?.message}
              />
            )}
            name="question5"
            defaultValue=""
          />
        </div>
        <div className="btn_sec">
          <div className="btn_submit">
            <Buttons.BgStandard
              type="submit"
              label={i18n.language === "en" ? "Add information" : "เพิ่มข้อมูล"}
            />
          </div>
        </div>
      </form>
    </InterestedFormStyled>
  );
};

InterestedForm.propTypes = {};

export default InterestedForm;
