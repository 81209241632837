import styled from "styled-components";

export const PhoneNumberFormStyled = styled.div`
  .label {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    &.mb {
      margin-bottom: 16px;
    }
  }
  .input_wrap {
    margin-bottom: 20px;
  }
  .btn_submit_wrap {
  }
`;
