import styled from "styled-components";

export const BookingStep2FormStyled = styled.div`
  .section {
    .f_title {
      margin-left: 30px;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      white-space: pre-wrap;
    }
    .input_wrap {
      margin-bottom: 20px;
      &.mb_70 {
        margin-bottom: 70px;
      }
      &.ml_50 {
        margin-left: 50px;
      }
    }
    .f_action_row {
      display: flex;
      column-gap: 14px;
      .btn_wrap {
        flex: 1;
      }
    }
  }
`;
