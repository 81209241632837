import styled from "styled-components";

export const BookingBosendorferStep2FormStyled = styled.div`
  .section {
    .header {
      margin-bottom: 26px;
      display: flex;
      flex-direction: column;
      .title {
        margin-bottom: 22px;
        white-space: pre-wrap;
        color: ${({ theme }) => theme.COLORS.GOLD_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
      }
      .sub_title {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
      }
    }
    .f_title {
      margin-bottom: 15px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
    }
    .input_wrap {
      margin-bottom: 20px;
      &.mb_70 {
        margin-bottom: 70px;
      }
      &.ml_50 {
        margin-left: 50px;
      }
    }
    .f_action_row {
      display: flex;
      column-gap: 14px;
      .btn_wrap {
        flex: 1;
      }
    }
  }
`;
