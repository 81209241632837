import styled from "styled-components";

export const FavouriteContainerStyled = styled.div`
  padding: 60px 18px 104px 18px;
  .header_sec {
    padding: 0 16px;
    display: flex;
  }
  .title_status {
    margin-bottom: 27px;
    padding: 0 18px;
    border-radius: 12px;
    width: fit-content;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S19};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .fav_item_wrap {
    margin-bottom: 15px;
  }
`;
