import styled from "styled-components";

export const PrivilegeContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .comming_soon {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .fav_row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .new_item_wrap {
      margin-bottom: 28px;
      flex-basis: calc(50% - 10px);
      overflow: hidden;
    }
  }
`;
