import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ActivityFormInfoConfirmStyled } from "./styled";
import { Buttons } from "components";

const ActivityFormInfoConfirm = ({
  theme_standard,
  activity_code,
  activity_name_th,
  personal_first_name,
  personal_last_name,
  personal_email,
  personal_phone_number,
  onCancel,
  onConfirm,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ActivityFormInfoConfirmStyled>
      <div className={customClass}>
        <div className="activity_confirm_container">
          <div className="avc_title">ยืนยันการสแกน</div>
          <div className="avc_body">
            <div className="avc_row">
              <div className="cr_left_col">ชื่อกิจกรรม</div>
              <div className="cr_right_col">{activity_code}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">รหัสกิจกรรม</div>
              <div className="cr_right_col">{activity_name_th}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">ชื่อลูกค้า</div>
              <div className="cr_right_col">
                {personal_first_name + " " + personal_last_name}
              </div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">เบอร์โทรลูกค้า</div>
              <div className="cr_right_col">{personal_phone_number}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">อีเมล</div>
              <div className="cr_right_col">{personal_email}</div>
            </div>
          </div>
          <div className="avc_action_row">
            <div className="ca_col">
              <Buttons.BgStandard
                theme_cancel
                label={"ยกเลิก"}
                onClick={onCancel}
              />
            </div>
            <div className="ca_col">
              <Buttons.BgStandard label={"ตกลง"} onClick={onConfirm} />
            </div>
          </div>
        </div>
      </div>
    </ActivityFormInfoConfirmStyled>
  );
};

ActivityFormInfoConfirm.propTypes = {};

export default ActivityFormInfoConfirm;
