export default function Hamburger({
  width = "25",
  height = "24",
  color = "#FFFFFF",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_382_7030)">
        <path
          d="M4.04922 12.0001L11.3992 19.3501C11.6492 19.6001 11.7701 19.8918 11.7617 20.2251C11.7534 20.5584 11.6242 20.8501 11.3742 21.1001C11.1242 21.3501 10.8326 21.4751 10.4992 21.4751C10.1659 21.4751 9.87422 21.3501 9.62422 21.1001L1.92422 13.4251C1.72422 13.2251 1.57422 13.0001 1.47422 12.7501C1.37422 12.5001 1.32422 12.2501 1.32422 12.0001C1.32422 11.7501 1.37422 11.5001 1.47422 11.2501C1.57422 11.0001 1.72422 10.7751 1.92422 10.5751L9.62422 2.87511C9.87422 2.62511 10.1701 2.50428 10.5117 2.51261C10.8534 2.52094 11.1492 2.65011 11.3992 2.90011C11.6492 3.15011 11.7742 3.44178 11.7742 3.77511C11.7742 4.10844 11.6492 4.40011 11.3992 4.65011L4.04922 12.0001Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
