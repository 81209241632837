import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { NavigationMenuItemStyled } from "./styled";

const NavigationMenuItem = ({
  theme_standard,
  icon,
  topLabel,
  bottomLabel,
  language,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <NavigationMenuItemStyled className={customClass} language={language}>
      <div className="nmi_left_col">
        <div className="rc_label">{topLabel}</div>
        {bottomLabel && <div className="rc_sub_label">{bottomLabel} </div>}
      </div>
      <div className="nmi_right_col">{icon}</div>
    </NavigationMenuItemStyled>
  );
};

NavigationMenuItem.propTypes = {};

export default NavigationMenuItem;
