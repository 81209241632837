import React from "react";
import { ProfileContainerStyled } from "./styled";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { MemberSwitchTypeWidget } from "widgets";
import { Cards, Headers } from "components";

class ProfileContainer extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const { authenRedux } = this.props;
    return (
      <ProfileContainerStyled>
        <div className="profile_detail">
          <Headers.PageHeader theme_center title={"PROFILE"} subTitle={"โปรไฟล์"} />
          <MemberSwitchTypeWidget type="4" />
          {authenRedux &&
            (authenRedux.member_type_active === "1" ? (
              <Cards.UserInfo data={authenRedux} />
            ) : (
              <Cards.CorperateInfo {...authenRedux.organization_detail} />
            ))}
        </div>
        <div className="interested_sec">{/* <Forms.InterestedForm /> */}</div>
      </ProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(ProfileContainer)));
