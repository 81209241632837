import { ShortcutWidgetStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Icons } from "components";

const ShortcutWidget = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["home"]);

  const _renderIcon = (e) => {
    switch (e.main_category) {
      case "1":
        return {
          icon: <Icons.ShortcutActive />,
          label: i18n.language === "en" ? "Activity" : "กิจกรรม",
        };
      case "2":
        return {
          icon: <Icons.ShortcutPriority />,
          label: i18n.language === "en" ? "Priority" : "สำคัญ",
        };
      case "3":
        return {
          icon: <Icons.ShortcutGame />,
          label: i18n.language === "en" ? "Game" : "เกมส์",
        };
      case "4":
        return {
          icon: <Icons.ShortcutSurvey />,
          label: i18n.language === "en" ? "Survey" : "แบบสอบถาม",
        };
      default:
        break;
    }
  };

  const _handleClickItem = (e) => {
    switch (e.main_category) {
      case "1":
        if (!e.action_ref_id) {
          navigate(ROUTE_PATH.ACTIVITIES);
        } else {
          switch (e.sub_category) {
            case "1":
              navigate(`${ROUTE_PATH.ACTIVITY_FORM}/${e.action_ref_id}`);
              break;
            case "2":
              navigate(`${ROUTE_PATH.ACTIVITIES}/${e.action_ref_id}`);
              break;
            default:
              break;
          }
        }
        break;
      case "2":
        navigate(ROUTE_PATH.ACTIVITIES);
        break;
      case "3":
        navigate(ROUTE_PATH.SPECIAL_POINTS_GAME);
        break;
      case "4":
        if (!e.action_ref_id) {
          navigate(ROUTE_PATH.SPECIAL_POINTS_FAV);
        } else {
          navigate(`${ROUTE_PATH.SPECIAL_POINTS_FAV}/test/${e.action_ref_id}`);
        }
        break;
      default:
        break;
    }
  };

  return (
    <ShortcutWidgetStyled>
      <div className="title">{t("SHORT CUT", { ns: "home" })}</div>
      <div className="shortcut_row">
        {data &&
          data.map((e, i) => {
            const render = _renderIcon(e);
            return (
              <div
                key={i}
                className="shortcut_item"
                onClick={() => _handleClickItem(e)}
              >
                <div className="shortcut_icon">{render.icon}</div>
                <div className="shortcut_title">{render.label}</div>
              </div>
            );
          })}
      </div>
    </ShortcutWidgetStyled>
  );
};

export default ShortcutWidget;
