import styled from "styled-components";

export const UserInfoStyled = styled.div`
  .user_info_container {
    margin-bottom: 5px;
    .ui_row {
      margin-bottom: 12px;
      padding-left: 43px;
      display: flex;
      align-items: center;
      column-gap: 14px;
      &.mb_32 {
        margin-bottom: 32px;
      }
      .ui_label {
        width: ${({ lang }) => (lang === "en" ? "112px" : "88px")};
        flex-shrink: 0;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
      .ui_value {
        display: flex;
        column-gap: 8px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
  }
  .btn_wrap {
    display: flex;
    justify-content: flex-end;
  }

  .theme_standard {
  }
`;
