import styled from "styled-components";

export const HomeContainerStyled = styled.div`
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .banner_wrap {
    margin-bottom: 10px;
  }
  .menu_wrap_nav_list {
    margin: auto;
    padding: 17px 20px;
    background: ${({ theme }) => theme.COLORS.GRAY_6};
    .menu_block {
      margin: auto;
      max-width: 352px;
      .menu_item_wrap {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 45px;
        }
      }
    }
  }
`;
