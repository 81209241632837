import React from "react";

export default function renderIcon({
  width = 10.177,
  height = 12.308,
  color = "#4b1e78",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.177 12.308"
    >
      <g
        id="Group_394"
        dataName="Group 394"
        transform="translate(0.276 0.417)"
      >
        <line
          id="Line_214"
          dataName="Line 214"
          x2="9.625"
          y2="6.375"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          id="Line_215"
          dataName="Line 215"
          y1="6.375"
          x2="9.625"
          transform="translate(0 5.1)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
