import styled from "styled-components";

export const LanguageWidgetStyled = styled.div`
  margin: 0 8px;
  display: flex;
  align-items: center;
  .btn_lang {
    background: transparent;
    padding: 4px 8px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
    cursor: pointer;
    &.left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.active {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
    }
  }
  .linear {
    height: 13px;
    width: 1.6px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    border: none;
  }
`;
