import DateTime from "./DateTime";
import DropdownSelect from "./DropdownSelect";
import DateTimePicker from "./DateTimePicker";
import InputCheckBox from "./InputCheckBox";
import Radio from "./Radio";
import ScanQRCode from "./ScanQRCode";
import TextArea from "./TextArea";
import TextField from "./TextField";
import Upload from "./Upload";

const EXPORT = {
  DateTime,
  DateTimePicker,
  DropdownSelect,
  InputCheckBox,
  Radio,
  ScanQRCode,
  TextArea,
  TextField,
  Upload,
};

export default EXPORT;
