import styled from "styled-components";

export const ActivitiesFormDetailContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .activity_wrap {
    margin-bottom: 22px;
    .act_img {
      width: 100%;
      height: auto;
    }
  }
  .activities_title {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .activities_sub_article {
  }
  .doc_block {
    margin-bottom: 12px;
    .doc_label {
      margin-bottom: 4px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
    .doc_download {
      display: flex;
      align-items: center;
      column-gap: 8px;
      background: transparent;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      cursor: pointer;
    }
  }
  .img_position2 {
    margin-top: 8px;
    .act_img2 {
      width: 100%;
      height: auto;
    }
  }
  .notice_close_regis {
    margin-top: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    white-space: pre-wrap;
  }
  .btn_wrap {
    width: 100%;
  }
  .action_row {
    margin-top: 30px;
    .btn_col {
      width: 100%;
    }
  }

  .member_registed_layout {
    margin-top: 32px;
    .mbr_title {
      margin-bottom: 4px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
    }
  }

  .qrcode_container {
    position: relative;
    padding: 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .btn_close {
      position: absolute;
      top: -15px;
      right: -15px;
      border-radius: 100px;
      padding: 8px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      cursor: pointer;
    }
  }
`;
