import React from "react";

export default function renderIcon({
  width = 16.4,
  height = 18.782,
  color = "#471E77",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.4 18.782"
    >
      <g id="Layer_1" transform="translate(-7.256 -5)">
        <path
          id="Path_4149"
          data-name="Path 4149"
          d="M23.336,19.247,21.942,18V13.188A5.974,5.974,0,0,0,17.077,7.1V6.445A1.538,1.538,0,0,0,15.457,5a1.538,1.538,0,0,0-1.62,1.445V7.1a5.962,5.962,0,0,0-4.865,6.087V18L7.577,19.247a.953.953,0,0,0,.756,1.646H22.569A.955.955,0,0,0,23.336,19.247Zm-3.556-.28H11.133V13.188a4.15,4.15,0,0,1,4.324-4.334,4.15,4.15,0,0,1,4.324,4.334Zm-4.324,4.815a2.06,2.06,0,0,0,2.162-1.925H13.295A2.051,2.051,0,0,0,15.457,23.782Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
