import styled from 'styled-components';

export const NewContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .fav_row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .new_item_wrap {
      margin-bottom: 28px;
      flex-basis: calc(50% - 10px);
      overflow: hidden;
    }
  }
`;
