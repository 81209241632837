import React from "react";

export default function renderIcon({
  width = 20.08,
  height = 21.3,
  color = "#4B1E78",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.079 21.296"
    >
      <g
        id="Group_553"
        data-name="Group 553"
        transform="translate(-13.954 -13.62)"
      >
        <g
          id="Group_554"
          data-name="Group 554"
          transform="translate(13.954 13.62)"
        >
          <path
            id="Path_4414"
            data-name="Path 4414"
            d="M33.853,18.434l-.766-.772-3.818-3.856a.62.62,0,0,0-.326-.173c-.017,0-.034,0-.05-.006l-.039,0c-.014,0-.027,0-.041,0H17.407a.62.62,0,0,0-.62.62V26.369a4.42,4.42,0,1,0,4.869,7.086H33.413a.62.62,0,0,0,.62-.62V18.872a.621.621,0,0,0-.18-.437m-4.4-2.676L31.9,18.233H29.448ZM18.375,33.676a3.181,3.181,0,1,1,3.181-3.181,3.185,3.185,0,0,1-3.181,3.181m14.418-1.46H22.448a4.421,4.421,0,0,0-4.073-6.143c-.117,0-.233.006-.349.015V14.86H28.208v3.993a.62.62,0,0,0,.62.62h3.964Z"
            transform="translate(-13.954 -13.62)"
            fill={color}
          />
          <path
            id="Path_4417"
            data-name="Path 4417"
            d="M26.071,21.813h5.81a.507.507,0,0,0,0-1.015h-5.81a.507.507,0,1,0,0,1.015"
            transform="translate(-19.894 -17.293)"
            fill={color}
          />
          <path
            id="Path_4460"
            data-name="Path 4460"
            d="M.335.861H4.174A.392.392,0,0,0,4.509.43.392.392,0,0,0,4.174,0H.335A.392.392,0,0,0,0,.43a.392.392,0,0,0,.335.43"
            transform="matrix(0.719, -0.695, 0.695, 0.719, 3.428, 18.644)"
            fill={color}
          />
          <path
            id="Path_4418"
            data-name="Path 4418"
            d="M26.071,27.122h5.81a.507.507,0,0,0,0-1.015h-5.81a.507.507,0,1,0,0,1.015"
            transform="translate(-19.894 -20.009)"
            fill={color}
          />
          <path
            id="Path_4419"
            data-name="Path 4419"
            d="M40.028,39.035h-5.81a.507.507,0,1,0,0,1.015h5.81a.507.507,0,1,0,0-1.015"
            transform="translate(-24.062 -26.624)"
            fill={color}
          />
          <path
            id="Path_4420"
            data-name="Path 4420"
            d="M40.028,44.575h-5.81a.507.507,0,1,0,0,1.015h5.81a.507.507,0,1,0,0-1.015"
            transform="translate(-24.062 -29.459)"
            fill={color}
          />
          <path
            id="Path_4421"
            data-name="Path 4421"
            d="M25.565,33.3a.507.507,0,0,0,.507.507H35.86a.507.507,0,1,0,0-1.015H26.072a.507.507,0,0,0-.507.507"
            transform="translate(-19.895 -23.428)"
            fill={color}
          />
          <path
            id="Path_4461"
            data-name="Path 4461"
            d="M.2.863H2.466c.109,0,.2-.193.2-.431S2.576,0,2.466,0H.2C.089,0,0,.193,0,.431S.089.863.2.863"
            transform="translate(3.595 19.263) rotate(-120)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
