import styled from "styled-components";

export const ActivityStyled = styled.div`
  .new_container {
    cursor: pointer;
    .new_img_wrap {
      position: relative;
      margin-bottom: 7px;
      .new_img {
        width: 100%;
        height: auto;
      }
      .status_line {
        position: absolute;
        bottom: 0px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .new_content_block {
      overflow: hidden;
      .n_header {
        margin-bottom: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) =>
          `${theme.FONT.STYLE.GT_AMERICA_STANDARD_BOLD},${theme.FONT.STYLE.PROMPT_SEMIBOLD}`};
      }
      .n_description {

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        color: ${({ theme }) => theme.COLORS.BLACK_1} !important;
        font-size: ${({ theme }) => theme.FONT.SIZE.S12} !important;
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT} !important;
      }
    }
  }

  .theme_standard {
  }
`;
