import React from "react";
import { connect } from "react-redux";
import { ActivityFormQuizContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { userService } from "apiServices";
import { ROUTE_PATH } from "utils/constants/routePath";
import { toast } from "react-toastify";
import { HeaderBackHomeWidget } from "widgets";
import { Displays, Forms } from "components";

class ActivityFormQuizContainer extends React.Component {
  state = {
    isLoading: true,
    activePage: 0,
    step: 1,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { router } = this.props;
    let resArr = await Promise.all([
      userService.GET_ACTIVITY_FORM_PDPA_DETAIL(router.params?.id),
      router.params?.question_id !== "create"
        ? userService.GET_MY_ACTIVITY_FORM_DETAIL(router.params?.question_id)
        : false,
    ]);
    if (resArr && resArr[0].status === 200) {
      if (resArr[1] && resArr[1].data) {
        const sortData = {
          ...resArr[0].data,
          question_list: resArr[0].data.question_list.sort(
            (a, b) => a.page - b.page
          ),
        };
        this.setState({
          initialValue: {
            ...resArr[1].data,
            question_list: resArr[1].data.question_list.map((e, i) => ({
              ...e,
              questions: e.questions.map((f, j) => {
                let render = f;
                if (f.type === "4") {
                  render.pdpa_details =
                    sortData.question_list[i].questions[j].pdpa_details;
                }
                return render;
              }),
            })),
          },
          data: sortData,
          isLoading: false,
        });
      } else {
        this.setState({
          data: {
            ...resArr[0].data,
            question_list: resArr[0].data.question_list.sort(
              (a, b) => a.page - b.page
            ),
          },
          isLoading: false,
        });
      }
    }
  };

  handlePersonalSubmit = (values) => {
    this.setState({
      tempPersonal: values,
      step: 2,
    });
  };

  handleBack = () => {
    const { activePage } = this.state;
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        activePage: activePage - 1,
        isLoading: false,
      });
    }, 500);
  };
  handleSubmit = async (values) => {
    const { authenRedux, router } = this.props;
    const { activePage, data, tempPersonal, tempValues } = this.state;
    const _updateTemp = (masterTempValue, currentValues) => {
      let temp = masterTempValue;
      if (!temp) {
        temp = [
          {
            title: data.question_list[activePage].title,
            desc: data.question_list[activePage].desc,
            page: data.question_list[activePage].page,
            questions: currentValues.questions,
          },
        ];
      } else {
        if (temp[activePage]) {
          temp[activePage] = {
            title: data.question_list[activePage].title,
            desc: data.question_list[activePage].desc,
            page: data.question_list[activePage].page,
            questions: currentValues.questions,
          };
        } else {
          temp.push({
            title: data.question_list[activePage].title,
            desc: data.question_list[activePage].desc,
            page: data.question_list[activePage].page,
            questions: currentValues.questions,
          });
        }
      }
      return temp;
    };
    if (activePage + 1 < data.question_list.length) {
      this.setState({
        isLoading: true,
      });
      setTimeout(() => {
        this.setState({
          tempValues: [..._updateTemp(tempValues, values)],
          activePage: activePage + 1,
          isLoading: false,
        });
      }, 500);
    } else {
      this.setState({
        isBgLoading: true,
      });
      const bodyFormData = new FormData();
      bodyFormData.append("activity_form_id", router.params?.id);
      bodyFormData.append("member_type", authenRedux.member_type_active);
      bodyFormData.append(
        "personal_first_name",
        tempPersonal.personal_first_name
      );
      bodyFormData.append(
        "personal_last_name",
        tempPersonal.personal_last_name
      );
      bodyFormData.append("personal_email", tempPersonal.personal_email);
      bodyFormData.append(
        "personal_phone_number",
        tempPersonal.personal_phone_number
      );
      if (router.params?.question_id !== "create") {
        bodyFormData.append(
          "activity_history_form_id",
          router.params?.question_id
        );
      }
      const questionList = _updateTemp(tempValues, values);
      bodyFormData.append(
        "question_list",
        JSON.stringify(
          questionList.map((e) => {
            return {
              ...e,
              questions: e.questions.map((f) => {
                let render = { ...f };
                if (
                  f.type === "3" &&
                  f.answer &&
                  f.answer[0] &&
                  f.answer[0].isNewUpload
                ) {
                  render.answer = f.answer[0].name;
                }
                return render;
              }),
            };
          })
        )
      );
      questionList.forEach((e) => {
        e.questions.forEach((f) => {
          if (
            f.type === "3" &&
            f.answer &&
            f.answer[0] &&
            f.answer[0].isNewUpload
          ) {
            bodyFormData.append(`file[]`, f.answer[0]);
          }
        });
      });
      let res = await userService.POST_ACTIVITY_FORM_HISTORY_REGISTER(
        bodyFormData
      );
      if (res && res.status === 200) {
        this.setState({
          isBgLoading: false,
        });
        toast.success("ลงทะเบียนสำเร็จ");
        this.handleNext();
      } else {
        this.setState({
          isBgLoading: false,
        });
        toast.error("ลงทะเบียนไม่สำเร็จ");
      }
    }
  };

  handleNext = () => {
    const { router } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.ACTIVITY_FORM}/${router.params?.id}`
    );
  };

  render() {
    const { t } = this.props;
    const {
      isBgLoading,
      isLoading,
      activePage,
      data,
      initialValue,
      tempValues,
      step,
    } = this.state;
    const renderQuestion = !data
      ? false
      : tempValues && tempValues[activePage]
      ? tempValues[activePage]
      : initialValue && initialValue.question_list.length > 0
      ? initialValue.question_list[activePage]
      : data.question_list[activePage];
    return (
      <ActivityFormQuizContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <HeaderBackHomeWidget />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_layout">
            {step === 1 && (
              <>
                <div className="header_question_layout">
                  <div className="title">
                    {t("Please fill in the details of the main applicant.", {
                      ns: "activity",
                    })}
                  </div>
                  <div className="desc">
                    {t(
                      "In the case of applying as a group or representative, please fill in the Team leader, parent or teacher.",
                      { ns: "activity" }
                    )}
                  </div>
                </div>
                <div className="quiz_container_layout">
                  <Forms.PersonalInfoForm
                    currentPage={activePage + 1}
                    totalPage={data.question_list.length}
                    initialValue={initialValue}
                    onBack={this.handleBack}
                    onSubmit={this.handlePersonalSubmit}
                  />
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="header_question_layout">
                  <div className="title">{renderQuestion.title}</div>
                  <div className="desc">{renderQuestion.desc}</div>
                </div>
                <div className="quiz_container_layout">
                  <Forms.QuickTestActivityForm
                    currentPage={activePage + 1}
                    totalPage={data.question_list.length}
                    initialValue={{ questions: renderQuestion.questions }}
                    onBack={this.handleBack}
                    onSubmit={this.handleSubmit}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </ActivityFormQuizContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(["activity", "profile"])(
    withRouter(ActivityFormQuizContainer)
  )
);
