import React from "react";

export default function renderIcon({
  width = 28.392,
  height = 32.655,
  color = "#4B1E78",
  isActive,
}) {
  return !isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_837"
        data-name="Group 837"
        transform="translate(10296.563 2798.726)"
      >
        <g id="Group_836" data-name="Group 836">
          <path
            id="Path_4766"
            data-name="Path 4766"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10296.563 -2798.726)"
            fill="#fff"
          />
          <g
            id="Group_793"
            data-name="Group 793"
            transform="translate(-10306.754 -2804.384)"
          >
            <path
              id="Path_4414"
              data-name="Path 4414"
              d="M47.851,21.546l-1.261-1.27L40.3,13.928a1.021,1.021,0,0,0-.536-.285c-.027-.006-.055-.007-.083-.01s-.042-.006-.064-.006-.044-.006-.067-.006H20.775a1.021,1.021,0,0,0-1.021,1.021l.222,30.614c0,.521.348,1.02.8,1.02H47.126a1.02,1.02,0,0,0,1.02-1.02V22.265a1.022,1.022,0,0,0-.3-.72m-7.251-4.4,4.044,4.073H40.6Zm5.506,27.093H21.8c-.029.128.03-28.5,0-28.573H38.558v6.573a1.021,1.021,0,0,0,1.021,1.021h6.526Z"
              transform="translate(1)"
              fill="#4B1E78"
            />
            <path
              id="Path_4417"
              data-name="Path 4417"
              d="M26.4,22.469h9.565a.835.835,0,0,0,0-1.671H26.4a.835.835,0,1,0,0,1.671"
              transform="translate(0.861 -1.245)"
              fill="#4B1E78"
            />
            <path
              id="Path_4418"
              data-name="Path 4418"
              d="M26.4,27.778h9.565a.835.835,0,0,0,0-1.671H26.4a.835.835,0,1,0,0,1.671"
              transform="translate(0.861 -1.73)"
              fill="#4B1E78"
            />
            <path
              id="Path_4421"
              data-name="Path 4421"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 -3.588)"
              fill="#4B1E78"
            />
            <path
              id="Path_4707"
              data-name="Path 4707"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 1.236)"
              fill="#4B1E78"
            />
            <path
              id="Path_4708"
              data-name="Path 4708"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 6.059)"
              fill="#4B1E78"
            />
          </g>
        </g>
        <path
          id="Path_4798"
          data-name="Path 4798"
          d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
          transform="translate(-10305.894 -2803.088)"
          fill="#4B1E78"
        />
        <path
          id="Path_4797"
          data-name="Path 4797"
          d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
          transform="translate(-10305.894 -2798.265)"
          fill="#4B1E78"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_837"
        data-name="Group 837"
        transform="translate(10296.563 2798.726)"
      >
        <g id="Group_836" data-name="Group 836">
          <path
            id="Path_4766"
            data-name="Path 4766"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10296.563 -2798.726)"
            fill="#4b1e78"
          />
          <g
            id="Group_793"
            data-name="Group 793"
            transform="translate(-10306.754 -2804.384)"
          >
            <path
              id="Path_4414"
              data-name="Path 4414"
              d="M47.851,21.546l-1.261-1.27L40.3,13.928a1.021,1.021,0,0,0-.536-.285c-.027-.006-.055-.007-.083-.01s-.042-.006-.064-.006-.044-.006-.067-.006H20.775a1.021,1.021,0,0,0-1.021,1.021l.222,30.614c0,.521.348,1.02.8,1.02H47.126a1.02,1.02,0,0,0,1.02-1.02V22.265a1.022,1.022,0,0,0-.3-.72m-7.251-4.4,4.044,4.073H40.6Zm5.506,27.093H21.8c-.029.128.03-28.5,0-28.573H38.558v6.573a1.021,1.021,0,0,0,1.021,1.021h6.526Z"
              transform="translate(1)"
              fill="#fff"
            />
            <path
              id="Path_4417"
              data-name="Path 4417"
              d="M26.4,22.469h9.565a.835.835,0,0,0,0-1.671H26.4a.835.835,0,1,0,0,1.671"
              transform="translate(0.861 -1.245)"
              fill="#fff"
            />
            <path
              id="Path_4418"
              data-name="Path 4418"
              d="M26.4,27.778h9.565a.835.835,0,0,0,0-1.671H26.4a.835.835,0,1,0,0,1.671"
              transform="translate(0.861 -1.73)"
              fill="#fff"
            />
            <path
              id="Path_4421"
              data-name="Path 4421"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 -3.588)"
              fill="#fff"
            />
            <path
              id="Path_4707"
              data-name="Path 4707"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 1.236)"
              fill="#fff"
            />
            <path
              id="Path_4708"
              data-name="Path 4708"
              d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
              transform="translate(0.861 6.059)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_4798"
          data-name="Path 4798"
          d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
          transform="translate(-10305.894 -2803.088)"
          fill="#fff"
        />
        <path
          id="Path_4797"
          data-name="Path 4797"
          d="M25.565,33.623a.835.835,0,0,0,.835.835H42.514a.835.835,0,1,0,0-1.671H26.4a.835.835,0,0,0-.835.835"
          transform="translate(-10305.894 -2798.265)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
