import styled from "styled-components";

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .h_logo_figure {
    margin: auto;
    margin-bottom: ${({ mb }) => (mb ? mb : "25px")};
    max-width: 126px;
    .header_img {
      width: 100%;
      height: auto;
    }
  }
  .header_info_block {
    margin-bottom: ${({ mbNode }) => (mbNode ? mbNode : "16px")};
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
  }

  .theme_standard {
  }
`;
