import React from "react";
import { connect } from "react-redux";
import { RegisterContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxAuthen } from "store/reducers/authenReducer";
import queryString from "query-string";
import { toast } from "react-toastify";
import moment from "moment";
import PDPAContainer from "containers/PDPAContainer";
import { TermConditionContainer, UserInfoConditionContainer } from "./Modals";
import { withTranslation } from "react-i18next";
import VerifyOTPModalContainer from "containers/VerifyOTPModalContainer";
// import { InputRegisTypeWidget } from "widgets";
import { Displays, Forms, Headers, Modals } from "components";
import { AlertControl } from "components/Modals/Alert";

class RegisterContainer extends React.Component {
  state = {
    step: 1,
  };

  componentDidMount() {}

  renderShowModal = (e) => {
    switch (e) {
      case "user_info":
        this.setState({
          renderModal: (
            <UserInfoConditionContainer onClose={this.handleCloseModal} />
          ),
          isShowModal: true,
        });
        break;
      case "term_condition":
        this.setState({
          renderModal: (
            <TermConditionContainer onClose={this.handleCloseModal} />
          ),
          isShowModal: true,
        });
        break;
      default:
        break;
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
      isShowModal: false,
    });
  };

  handleSubmit = async (values) => {
    const { memberType } = this.state;
    let { lineID, avatar } = queryString.parse(
      this.props.router.location.search
    );
    if (!lineID) {
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "ไม่สามารถทำรายการนี้ได้ เนื่องจากท่านได้สมัครผิดช่องทาง",
        btnLabel2: "กลับ",
      });
    } else {
      let params = {
        ...values,
        line_id: lineID,
        img_path: avatar,
        gender: values.gender.value,
        province: values.province.name_th,
        province_en: values.province.name_en,
        province_code: values.province.value,
        birthday: moment(values.birthday).format("YYYY-MM-DD"),
        member_type_active: "1",
      };
      if (memberType === "2") {
        params.organization_province = values.organization_province.name_th;
        params.organization_province_en = values.organization_province.name_en;
        params.organization_province_code = values.organization_province.value;
      }
      this.setState({
        regisData: params,
        step: 2,
      });
    }
  };

  handleAcceptPDPA = async (e) => {
    const { i18n } = this.props;
    const { regisData } = this.state;
    this.setState({
      renderModal: (
        <VerifyOTPModalContainer
          memberType={"1"}
          phoneNumber={regisData.phone_number}
          onCancel={this.handleCloseModal}
          onVerifySuccess={async () => {
            let params = {
              ...regisData,
              pdpa_id: e.pdpa_id,
              language: i18n.language,
              is_accepted_privacy_pdpa: e.is_accepted_privacy_pdpa
                ? true
                : false,
              is_accepted_marketing_pdpa: e.is_accepted_marketing_pdpa
                ? true
                : false,
              is_accepted_product_warranty_pdpa:
                e.is_accepted_product_warranty_pdpa ? true : false,
              is_accepted_certified_true_info_pdpa:
                e.is_accepted_certified_true_info_pdpa ? true : false,
            };
            this.setState({
              isBgLoading: true,
            });
            let res =
              regisData.member_type_active === "1"
                ? await userService.POST_REGISTER_CUSTOMER(params)
                : await userService.POST_ORGANIZATION_CREATE(params);
            if (res && res.status === 200) {
              this.props.router.navigate(ROUTE_PATH.SPLASH);
            } else {
              this.setState({
                isBgLoading: false,
                isShowModal: false,
              });
              toast.error(res.data?.error?.message);
            }
          }}
        />
      ),
      isShowModal: true,
    });
  };

  handleChangeMemberType = (e) => {
    this.setState({
      memberType: e,
    });
  };

  render() {
    const { t } = this.props;
    const { isShowModal, isBgLoading, renderModal, step } = this.state;
    return (
      <RegisterContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {step === 1 ? (
          <>
            <Headers.Logo mb={"40px"} />
            <div className="title_form">
              {t("Member Registration", { ns: "register" })}
            </div>
            {/* <div className="input_regis_type_wrap">
              <InputRegisTypeWidget onChange={this.handleChangeMemberType} />
            </div> */}
            <Forms.RegisterForm
              onSubmit={this.handleSubmit}
              handleClickShowModal={this.renderShowModal}
            />
            {/* {memberType &&
              (memberType === "1" ? (
                <Forms.RegisterForm
                  onSubmit={this.handleSubmit}
                  handleClickShowModal={this.renderShowModal}
                />
              ) : (
                <Forms.RegisterCorperateForm
                  onSubmit={this.handleSubmit}
                  handleClickShowModal={this.renderShowModal}
                />
              ))} */}
          </>
        ) : (
          <PDPAContainer type="modal" onAccept={this.handleAcceptPDPA} />
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RegisterContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common", "register"])(withRouter(RegisterContainer)));
