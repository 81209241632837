import styled from "styled-components";

export const ProfileContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .profile_detail {
    margin-bottom: 42px;
    .avatar_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      .avatar {
        width: 82.56px;
        height: 82.56px;
        border-radius: 50%;
      }
    }
  }
  .interested_sec {
    padding: 0px 12.5px 0px 12.5px;
  }
`;
