import styled from "styled-components";

export const BtnScanQRWidgetStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn_scan {
    max-width: 391px;
    padding: 12px 24px;
    border: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  .scan_show {
    text-align: center;
  }
`;
