import React from "react";
import { PrivilegeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Cards, Displays, Headers, Icons } from "components";

class PrivilegeContainer extends React.Component {
  state = {
    isLoading: false,
    text: "Coming soon .....",
    displayText: "",
    textIndex: 0,
  };

  componentDidMount() {
    // this.fetchData();
    this.startTextAnimation();
  }

  componentWillUnmount() {
    clearInterval(this.textInterval);
  }

  startTextAnimation = () => {
    this.textInterval = setInterval(() => {
      this.setState((prevState) => {
        const { text, textIndex } = prevState;
        const newText = prevState.displayText + text[textIndex];

        if (textIndex === text.length - 1) {
          setTimeout(() => {
            this.setState({ displayText: "", textIndex: 0 });
          }, 200);
          return prevState;
        }

        return { displayText: newText, textIndex: textIndex + 1 };
      });
    }, 200);
  };

  fetchData = async () => {
    let res = await userService.GET_NEW_LIST(`?is_active=true`);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handlClickItem = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.NEW}/${e._id}`);
  };

  render() {
    const { isLoading, data, displayText } = this.state;
    return (
      <PrivilegeContainerStyled>
        <Headers.PageHeader
          theme_standard
          title={"Privilege"}
          subTitle={"สิทธิพิเศษ"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : !data || data.docs.length === 0 ? (
          <div className="comming_soon">
            <Icons.MenuBarSong color={`#4D1E79`} width={50} height={50} />
            {displayText}
          </div>
        ) : (
          <div className="fav_row">
            {data.docs.map((e, i) => (
              <div
                key={i}
                className="new_item_wrap"
                onClick={() => this.handlClickItem(e)}
              >
                <Cards.New
                  src={e.front_img}
                  title={e.title}
                  description={e.description}
                />
              </div>
            ))}
          </div>
        )}
      </PrivilegeContainerStyled>
    );
  }
}

export default withTranslation(["common", "news"])(
  withRouter(PrivilegeContainer)
);
