import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { PageHeaderStyled } from "./styled";

const PageHeader = ({
  theme_standard,
  theme_f22b,
  theme_center,
  mb,
  mbNode,
  icons,
  title,
  subTitle,
  iconBG,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_f22b: theme_f22b,
    theme_center: theme_center,
  });
  return (
    <PageHeaderStyled mb={mb} mbNode={mbNode} className={customClass}>
      <div className="header">
        {icons && <div className="icon_wrap">{icons}</div>}
        <div className="title_sec">
          <div className="header_title">{title}</div>
          <div className="header_subtitle">{subTitle}</div>
        </div>
      </div>
    </PageHeaderStyled>
  );
};

PageHeader.propTypes = {};

export default PageHeader;
