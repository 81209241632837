import React from "react";

export default function renderIcon({ width = 45.992, height = 45.492 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.492 45.492"
    >
      <g
        id="Group_919"
        data-name="Group 919"
        transform="translate(-32.651 -302.819)"
      >
        <g
          id="Group_799"
          data-name="Group 799"
          transform="translate(-1842.631 -4983.615)"
        >
          <g
            id="Group_229"
            data-name="Group 229"
            transform="translate(1875.282 5286.434)"
          >
            <path
              id="Path_3786"
              data-name="Path 3786"
              d="M1920.774,5309.18a22.746,22.746,0,1,1-22.746-22.746,22.746,22.746,0,0,1,22.746,22.746"
              transform="translate(-1875.282 -5286.435)"
              fill="#4b1e78"
            />
          </g>
        </g>
        <g
          id="Group_807"
          data-name="Group 807"
          transform="translate(-75.991 -130.414)"
        >
          <path
            id="Path_4717"
            data-name="Path 4717"
            d="M2217.168,1461.044V1440.75h25.312v3.6h3.588V1461.3a2.648,2.648,0,0,0-.1.294c-.421,1.9-1.342,2.651-3.255,2.651h-13.787c-2.672,0-5.344.01-8.016,0a3.332,3.332,0,0,1-3.622-2.844c-.031-.12-.078-.237-.117-.355m1.8-18.476v.688q0,8.472,0,16.943c0,1.647.6,2.253,2.231,2.253h20.058a4.114,4.114,0,0,1-.591-2.713q.008-8.214,0-16.429v-.742Z"
            transform="translate(-2100.308 -996.772)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2419"
            data-name="Rectangle 2419"
            width="17.972"
            height="1.687"
            transform="translate(120.781 449.016)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2420"
            data-name="Rectangle 2420"
            width="7.442"
            height="1.687"
            transform="translate(131.31 461.213)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2421"
            data-name="Rectangle 2421"
            width="7.442"
            height="1.687"
            transform="translate(131.31 456.908)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2423"
            data-name="Rectangle 2423"
            width="7.442"
            height="9.579"
            transform="translate(120.781 453.321)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2422"
            data-name="Rectangle 2422"
            width="7.442"
            height="1.687"
            transform="translate(131.31 453.321)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
