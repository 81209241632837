import React from "react";

export default function renderIcon({
  width = 25.165,
  height = 32.76,
  color = "#4B1E78",
  isActive,
}) {
  return !isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_838"
        data-name="Group 838"
        transform="translate(10213.063 2647.75)"
      >
        <g
          id="Group_798"
          data-name="Group 798"
          transform="translate(691 2339.916)"
        >
          <path
            id="Path_4767"
            data-name="Path 4767"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10904.063 -4987.666)"
            fill="#fff"
          />
          <path
            id="Path_4770"
            data-name="Path 4770"
            d="M32.617,28.763a8.05,8.05,0,1,1,8.092-8.05,8.08,8.08,0,0,1-8.092,8.05m0-13.985a5.935,5.935,0,1,0,5.966,5.936,5.959,5.959,0,0,0-5.966-5.936"
            transform="translate(-10912.033 -4992.228)"
            fill="#4B1E78"
          />
          <path
            id="Path_4768"
            data-name="Path 4768"
            d="M44.146,49.454H42.02V45.389a9.413,9.413,0,0,0-9.425-9.378H30.532a9.412,9.412,0,0,0-9.425,9.378v4.065H18.981V45.389A11.535,11.535,0,0,1,30.532,33.9h2.062A11.535,11.535,0,0,1,44.146,45.389Z"
            transform="translate(-10910.981 -4996.26)"
            fill="#4B1E78"
          />
        </g>
        <rect
          id="Rectangle_2490"
          data-name="Rectangle 2490"
          width="23.863"
          height="2"
          transform="translate(-10200.348 -2608.891)"
          fill="#4B1E78"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_838"
        data-name="Group 838"
        transform="translate(10213.063 2647.75)"
      >
        <g
          id="Group_798"
          data-name="Group 798"
          transform="translate(691 2339.916)"
        >
          <path
            id="Path_4767"
            data-name="Path 4767"
            d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
            transform="translate(-10904.063 -4987.666)"
            fill="#4b1e78"
          />
          <path
            id="Path_4770"
            data-name="Path 4770"
            d="M32.617,28.763a8.05,8.05,0,1,1,8.092-8.05,8.08,8.08,0,0,1-8.092,8.05m0-13.985a5.935,5.935,0,1,0,5.966,5.936,5.959,5.959,0,0,0-5.966-5.936"
            transform="translate(-10912.033 -4992.228)"
            fill="#fff"
          />
          <path
            id="Path_4768"
            data-name="Path 4768"
            d="M44.146,49.454H42.02V45.389a9.413,9.413,0,0,0-9.425-9.378H30.532a9.412,9.412,0,0,0-9.425,9.378v4.065H18.981V45.389A11.535,11.535,0,0,1,30.532,33.9h2.062A11.535,11.535,0,0,1,44.146,45.389Z"
            transform="translate(-10910.981 -4996.26)"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_2490"
          data-name="Rectangle 2490"
          width="23.863"
          height="2"
          transform="translate(-10200.348 -2608.891)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
