import styled from "styled-components";

export const ScanQrCodeWidgetStyled = styled.div`
  .scqr_icon_wrap {
    margin-bottom: ${({ lang }) => (lang === "en" ? "0.5px" : "3.5px")};
    display: flex;
    justify-content: center;
    .btn_scan {
      border-radius: 73.7px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 73.7px;
      height: 73.7px;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
  }
  .scqr_label {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme, lang }) =>
      lang === "en" ? theme.FONT.SIZE.S20 : theme.FONT.SIZE.S16};
    font-family: ${({ theme }) =>
      `${theme.FONT.STYLE.GT_AMERICA_COMPRESSED_BOLD},${theme.FONT.STYLE.PROMPT_MEDIUM}`};
  }
`;

export const CameraScanContainer = styled.div`
  position: relative;
  margin: -20px;
  width: 100vw;
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BLACK_1};
  .btn_cmra_back {
    position: absolute;
    padding: 20px;
    z-index: 2;
  }
`;
