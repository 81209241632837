import activity from "./activity.json";
import booking from "./booking.json";
import common from "./common.json";
import form from "./form.json";
import history from "./history.json";
import home from "./home.json";
import menu from "./menu.json";
import news from "./news.json";
import point from "./point.json";
import profile from "./profile.json";
import register from "./register.json";

const Export = {
  activity,
  booking,
  common,
  form,
  history,
  home,
  menu,
  news,
  point,
  profile,
  register,
};

export default Export;
