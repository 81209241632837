import React from "react";

export default function renderIcon({
  width = 18.191,
  height = 39.383,
  color = "#4B1E78",
  isActive,
}) {
  return !isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_837"
        data-name="Group 837"
        transform="translate(10460.063 2949.714)"
      >
        <path
          id="Path_4769"
          data-name="Path 4769"
          d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
          transform="translate(-10460.063 -2949.714)"
          fill="#fff"
        />
        <g id="Group_836" data-name="Group 836">
          <path
            id="Path_4771"
            data-name="Path 4771"
            d="M37.124,30.49a5.646,5.646,0,0,0-4.59-4.609V24.152A6.824,6.824,0,0,0,36.64,22a6.746,6.746,0,0,0,1.791-4.581,6.818,6.818,0,0,0-6.83-6.792l-.926.01V22.413a9.044,9.044,0,0,0,0,18v1.26a4.63,4.63,0,1,0,1.859,3.7V40.416a9.094,9.094,0,0,0,8.166-9V30.49Zm-4.59-8.212V12.57a4.937,4.937,0,0,1,0,9.708m0,12.78V27.772a3.767,3.767,0,0,1,0,7.286m6.248-2.719a7.286,7.286,0,0,1-6.248,6.215V36.948a5.646,5.646,0,0,0,4.59-4.609Zm-8.107,13.04a2.8,2.8,0,1,1-2.8-2.787,2.8,2.8,0,0,1,2.8,2.787m-4.72-13.965v.31h1.859v-.31a3.8,3.8,0,0,1,2.861-3.657v10.8a7.2,7.2,0,0,1,0-14.281v1.6a5.626,5.626,0,0,0-4.72,5.543"
            transform="translate(-10467.105 -2955.673)"
            fill="#4B1E78"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.126"
      height="48.874"
      viewBox="0 0 49.126 48.874"
    >
      <g
        id="Group_837"
        data-name="Group 837"
        transform="translate(10460.063 2949.714)"
      >
        <path
          id="Path_4769"
          data-name="Path 4769"
          d="M49.126,24.437A24.563,24.563,0,1,1,24.563,0,24.5,24.5,0,0,1,49.126,24.437"
          transform="translate(-10460.063 -2949.714)"
          fill="#4b1e78"
        />
        <g id="Group_836" data-name="Group 836">
          <path
            id="Path_4771"
            data-name="Path 4771"
            d="M37.124,30.49a5.646,5.646,0,0,0-4.59-4.609V24.152A6.824,6.824,0,0,0,36.64,22a6.746,6.746,0,0,0,1.791-4.581,6.818,6.818,0,0,0-6.83-6.792l-.926.01V22.413a9.044,9.044,0,0,0,0,18v1.26a4.63,4.63,0,1,0,1.859,3.7V40.416a9.094,9.094,0,0,0,8.166-9V30.49Zm-4.59-8.212V12.57a4.937,4.937,0,0,1,0,9.708m0,12.78V27.772a3.767,3.767,0,0,1,0,7.286m6.248-2.719a7.286,7.286,0,0,1-6.248,6.215V36.948a5.646,5.646,0,0,0,4.59-4.609Zm-8.107,13.04a2.8,2.8,0,1,1-2.8-2.787,2.8,2.8,0,0,1,2.8,2.787m-4.72-13.965v.31h1.859v-.31a3.8,3.8,0,0,1,2.861-3.657v10.8a7.2,7.2,0,0,1,0-14.281v1.6a5.626,5.626,0,0,0-4.72,5.543"
            transform="translate(-10467.105 -2955.673)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
