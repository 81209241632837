import React, { useEffect } from "react";
import { QuickTestFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const QuickTestForm = ({ initialValue, onBack, onSubmit }) => {
  const schema = yup.object().shape({
    question_list: yup.array().of(
      yup.object().shape({
        answer: yup.string().required("*Required"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields,
    // append, remove
  } = useFieldArray({
    control,
    name: "question_list",
  });

  useEffect(() => {
    if (initialValue) {
      reset({
        ...initialValue,
      });
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common"]);

  return (
    <QuickTestFormStyled>
      {initialValue && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => (
            <div key={item.id}>
              {item.type === "1" && (
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Radio
                      {...field}
                      label={`${index + 1}. ${item.question}`}
                      options={
                        item.answer_list
                          ? item.answer_list.map((e) => ({
                              label: e,
                              value: e,
                            }))
                          : []
                      }
                      disabled={initialValue && initialValue.isAssessment}
                      errors={
                        errors?.question_list &&
                        errors?.question_list[index]?.answer?.message
                      }
                    />
                  )}
                  name={`question_list.${index}.answer`}
                />
              )}
              {item.type === "2" && (
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextArea
                      {...field}
                      label={`${index + 1}. ${item.question}`}
                      disabled={initialValue && initialValue.isAssessment}
                      errors={
                        errors?.question_list &&
                        errors?.question_list[index]?.answer?.message
                      }
                    />
                  )}
                  name={`question_list.${index}.answer`}
                />
              )}
            </div>
          ))}
          {initialValue && initialValue.isAssessment ? (
            <>
              <div className="passed_label">
                {t("You have taken this test.", { ns: "common" })}
              </div>
              <div className="btn_submit_wrap">
                <Buttons.BgStandard
                  label="กลับ"
                  onClick={onBack}
                />
              </div>
            </>
          ) : (
            <div className="btn_submit_wrap">
              <Buttons.BgStandard label="ส่ง" type="submit" />
            </div>
          )}
        </form>
      )}
    </QuickTestFormStyled>
  );
};

QuickTestForm.propTypes = {};

export default QuickTestForm;
