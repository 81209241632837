import styled from "styled-components";

export const InterestedFormStyled = styled.div`
  .header {
    margin-bottom: 23px;
    .title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .sub_title {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }

  .input_wrap {
    margin-bottom: 10px;
    &.mb_32 {
      margin-bottom: 32px;
    }
  }
  .btn_sec {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .btn_submit {
      width: calc(50% - 14px);
    }
  }
`;
