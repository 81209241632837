import React from "react";

export default function renderIcon({
  width = 33.29,
  height = 35.31,
  color = "#848484",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Group_424"
        data-name="Group 424"
        transform="translate(-10156 -1821) rotate(180)"
      >
        <g
          id="Group_422"
          data-name="Group 422"
          transform="translate(-10172 -1845)"
        >
          <circle
            id="Ellipse_2598"
            data-name="Ellipse 2598"
            cx="11"
            cy="11"
            r="11"
            transform="translate(-6 2)"
            opacity="0.237"
          />
        </g>
        <g
          id="Group_421"
          data-name="Group 421"
          transform="translate(-10178 -1843)"
        >
          <g id="Path_4148" data-name="Path 4148" fill="none">
            <path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z" stroke="none" />
            <path
              d="M 11 1 C 5.485979080200195 1 1 5.485979080200195 1 11 C 1 16.51401901245117 5.485979080200195 21 11 21 C 16.51401901245117 21 21 16.51401901245117 21 11 C 21 5.485979080200195 16.51401901245117 1 11 1 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
              stroke="none"
              fill="#fff"
            />
          </g>
          <g
            id="Group_417"
            data-name="Group 417"
            transform="translate(7.841 6.457)"
          >
            <g id="Group_416" data-name="Group 416">
              <path
                id="Path_4147"
                data-name="Path 4147"
                d="M0,0,4.37,2.565l3.4,2"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
              <line
                id="Line_215"
                data-name="Line 215"
                y1="4.561"
                x2="7.769"
                transform="translate(0 4.561)"
                fill="none"
                stroke="#fff"
                stroke-width="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
