import styled from "styled-components";

export const ProductWarrantyHistoryContainerStyled = styled.div`
  padding-bottom: 40px;
  .member_type_layout {
    padding: 24px 24px 0px 24px;
  }
  .product_warranty_title {
    padding: 0px 24.5px 24.5px 24.5px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .sub_title {
    padding-left: 36px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .product_warranty_list {
    padding: 0 10px;
    .product_item_wrap {
      border-bottom: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      padding-bottom: 14px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .btn_wrap {
    margin: auto;
    margin-top: 20px;
    max-width: 167px;
  }
`;
