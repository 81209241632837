import React from "react";
import { connect } from "react-redux";
import { BookingDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import {
  AudioEquipmentContainer,
  BosendorferContainer,
  MusicalInstrumentContainer,
} from "./Types";

class BookingDetailContainer extends React.Component {
  render() {
    const { router } = this.props;
    return (
      <BookingDetailContainerStyled>
        {router.params?.category_id === "1" && <MusicalInstrumentContainer />}
        {router.params?.category_id === "2" && <AudioEquipmentContainer />}
        {router.params?.category_id === "3" && <BosendorferContainer />}
      </BookingDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(BookingDetailContainer)));
