import styled from "styled-components";

export const ActivityFormQuizContainerStyled = styled.div`
  .header_layout {
  }
  .body_layout {
    padding: 32px 20px 40px 20px;
    .header_layout {
      margin-bottom: 2rem;
      .title {
        margin-bottom: 4px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
      }
      .desc {
        color: ${({ theme }) => theme.COLORS.GRAY_4};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
      }
    }
    .header_question_layout {
      margin-bottom: 28px;
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      overflow: hidden;
      .title {
        padding: 12px 12px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
      }
      .desc {
        padding: 14px 12px;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
      }
    }
    .quiz_container_layout {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
  }
`;
