import styled from "styled-components";

export const ShortcutWidgetStyled = styled.div`
  padding: 0 20px 5px 20px;
  .title {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) =>
      `${theme.FONT.STYLE.GT_AMERICA_COMPRESSED_BOLD},${theme.FONT.STYLE.PROMPT_MEDIUM}`};
  }
  .shortcut_row {
    display: flex;
    justify-content: space-around;
    column-gap: 10px;
    .shortcut_item {
      width: 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      .shortcut_icon {
      }
      .shortcut_title {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) =>
          `${theme.FONT.STYLE.GT_AMERICA_STANDARD_REGULAR},${theme.FONT.STYLE.PROMPT_REGULAR}`};
      }
    }
  }
`;
