import styled from "styled-components";

export const ProfileFormStyled = styled.div`
  border-radius: 0.5rem;
  padding: 1rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .title_form {
    margin-bottom: 26px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .input_wrap {
    margin-bottom: 27px;
    &.mb_12 {
      margin-bottom: 12px;
    }
    &.mb_82 {
      margin-bottom: 82px;
    }
  }
  .input_checkbox_wrap {
    margin-bottom: 19px;
    display: flex;
    justify-content: center;
  }
`;
