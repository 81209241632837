import React, { useEffect } from "react";
import { ProductWarranteeCoperateNoLoginFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import address from "utils/jsons/thailandAddress/address.json";
import { GENDER_TYPE } from "utils/functions/customer";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const nameRegExp = /^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u;

const ProductWarranteeCoperateNoLoginForm = ({ onSubmit, initialValues }) => {
  const schema = yup.object().shape({
    organization_name: yup
      .string()
      .required("Firstname is required")
      .matches(nameRegExp, "Only alphabets are allowed for this field "),
    // organization_coporate_id: yup
    //   .string()
    //   .required('Firstname is required')
    //   .matches(nameRegExp, 'Only alphabets are allowed for this field '),
    organization_province: yup.object().required("Province is required"),
    organization_phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Invalid phone number"),
    organization_email: yup.string().required("Email is required"),
    // organization_email: yup.string().email().required('Email is required'),
    // first_name: yup.string().required('Firstname is required'),
    // last_name: yup.string().required('Lastname is required'),
    // gender: yup.object().required('Gender is required'),
    // province: yup.object().required('Province is required'),
    // phone_number: yup
    //   .string()
    //   .required('Phone number is required')
    //   .matches(phoneRegExp, 'Invalid phone number'),
    // birthday: yup.string().required('Birthday is required'),
    image_file: yup
      .array()
      .required("Receipt is required")
      .min(1, "Receipt is required"),
    // is_accept_user_info: yup.bool().oneOf([true], "Accept must be checked"),
    // is_accept_term_condition: yup
    //   .bool()
    //   .oneOf([true], "Accept must be checked"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
        is_accept_user_info: true,
        is_accept_term_condition: true,
      };
      if (initialValues.province) {
        params.province = {
          label: initialValues.province,
          value: initialValues.province,
        };
      }
      if (initialValues.gender) {
        let genderTemp = GENDER_TYPE.find(
          (e) => e.value === initialValues.gender
        );
        params.gender = {
          label: genderTemp.label,
          value: genderTemp.value,
        };
      }

      // if (initialValues.slip_img) {
      //   params.image_file = [{ url: initialValues.slip_img }];
      // }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common", "register"]);

  const renderProvinceOptions = () => {
    return address.reduce((result, data, i) => {
      if (i === 0) {
        result.push({ label: data.province, value: data.province });
      } else {
        let find = result.find((e) => e.value === data.province);
        if (!find) {
          result.push({ label: data.province, value: data.province });
        }
      }
      return result;
    }, []);
  };

  const disabled = !(
    initialValues &&
    (initialValues.status === "1" || initialValues.status === "5")
  );
  return (
    <ProductWarranteeCoperateNoLoginFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_sec mb_20">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Organization Name", { ns: "register" })}`}
                  errors={errors.organization_name?.message}
                />
              )}
              name="organization_name"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`${t("Tax ID", {
                    ns: "register",
                  })}`}
                  errors={errors.organization_coporate_id?.message}
                />
              )}
              name="organization_coporate_id"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={renderProvinceOptions()}
                  placeholder={`* ${t("Province", { ns: "register" })}`}
                  errors={errors.organization_province?.message}
                />
              )}
              name="organization_province"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Mobile number", { ns: "register" })}`}
                  errors={errors.organization_phone_number?.message}
                />
              )}
              name="organization_phone_number"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Email", { ns: "register" })}`}
                  errors={errors.organization_email?.message}
                  disabled={disabled}
                />
              )}
              name="organization_email"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Upload
                  {...field}
                  acceptType="cover"
                  // placeholder={t('test')}
                  maxMBFileSizeWarning={8}
                  errors={errors.image_file?.message}
                  disabled={disabled}
                />
              )}
              name="image_file"
              defaultValue={[]}
            />
          </div>
        </div>
        {initialValues &&
          (initialValues.status === "1" || initialValues.status === "5") && (
            <div className="btn_submit_wrap">
              <Buttons.BgStandard label="ตกลง" type="submit" />
            </div>
          )}
      </form>
    </ProductWarranteeCoperateNoLoginFormStyled>
  );
};

ProductWarranteeCoperateNoLoginForm.propTypes = {};

export default ProductWarranteeCoperateNoLoginForm;
