import React from "react";
import { connect } from "react-redux";
import { ProductWarrantyHistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { MemberSwitchTypeWidget } from "widgets";
import { Buttons, Cards, Displays } from "components";

class ProductWarrantyHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?member_type=${this.props.authenRedux.member_type_active}`);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authenRedux &&
      this.props.authenRedux !== prevProps.authenRedux
    ) {
      this.fetchData(
        `?member_type=${this.props.authenRedux.member_type_active}`
      );
    }
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_PRODUCT_WARRANTEE(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handleEditProductWarranty = (e) => {
    this.props.router.navigate(
      `/system/home/product-warrantee/${e.serial_no}-${e.item_no}`
    );
  };

  render() {
    const { t, i18n, router } = this.props;
    const { isLoading, data } = this.state;
    return (
      <ProductWarrantyHistoryContainerStyled>
        <div className="member_type_layout">
          <MemberSwitchTypeWidget type="4" />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="product_warranty_title">
              <div>
                {i18n.language === "en"
                  ? "Product Registration History"
                  : "ประวัติการลงทะเบียนสินค้า"}
              </div>
            </div>
            <div className="sub_title">
              {i18n.language === "en"
                ? "Product Detail"
                : "รายละเอียดเครื่องดนตรี"}
            </div>
            {!data || data.length === 0 ? (
              <Displays.LabelEmpty label={"No Data"} />
            ) : (
              <div className="product_warranty_list">
                {data.map((e, i) => (
                  <div key={i} className="product_item_wrap">
                    <Cards.ProductWarrantee
                      isEdit
                      titleShow
                      title={
                        e.pd_dep_desc +
                        " " +
                        e.pd_cat02_desc +
                        " " +
                        e.pd_cat03_desc
                      }
                      description={e.description}
                      descriptionLine2={e.description_line_2}
                      sn={e.serial_no}
                      itemNo={e.item_no}
                      warrantyPeriod={e.warranty_period}
                      warrantyUnit={e.warranty_unit}
                      warrantyDescription={e.warranty_description_th}
                      rejectedReason={e.rejected_reason}
                      status={e.status}
                      approvedWarrantyDate={e.approved_warranty_date}
                      extensionPeriod={e.extension_period}
                      extensionUnit={e.extension_unit}
                      onEdit={() => this.handleEditProductWarranty(e)}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className="btn_wrap">
          <Buttons.BgStandard
            label={t("Back", { ns: "common" })}
            onClick={() => router.navigate(-1)}
          />
        </div>
      </ProductWarrantyHistoryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(["common", "profile"])(
    withRouter(ProductWarrantyHistoryContainer)
  )
);
