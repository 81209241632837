import React, { useEffect } from "react";
import { ProfileFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const ProfileForm = ({
  initialValues,
  onSubmit,
  handleClickShowModal,
  defaultValues,
}) => {
  const schema = yup.object().shape({
    // email: yup.string().email().required("Email is required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common", "register"]);

  return (
    <ProfileFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                label={t("Name", { ns: "register" })}
                {...field}
                placeholder={t("Name", { ns: "register" })}
                errors={errors.first_name?.message}
              />
            )}
            name="first_name"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                label={t("Surname", { ns: "register" })}
                {...field}
                placeholder={t("Surname", { ns: "register" })}
                errors={errors.last_name?.message}
              />
            )}
            name="last_name"
            defaultValue=""
          />
        </div>
        {/* <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={t("Mobile number", { ns: "register" })}
                placeholder={t("Mobile number", { ns: "register" })}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div> */}
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={t("Email", { ns: "register" })}
                placeholder={t("Email", { ns: "register" })}
                errors={errors.email?.message}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DateTimePicker
                {...field}
                isShowMonthDropdown
                isShowYearDropdown
                maxDate={new Date()}
                label={`${t("Date of birth", { ns: "register" })}`}
                errors={errors.birthday?.message}
              />
            )}
            name="birthday"
            // defaultValue={new Date()}
          />
        </div>
        <Buttons.BgStandard type="submit" label={t("Edit", { ns: "common" })} />
      </form>
    </ProfileFormStyled>
  );
};

ProfileForm.propTypes = {};

export default ProfileForm;
