import React from "react";

export default function renderIcon({
  width = 45.579,
  height = 45.378,
  color = "#4b1e78",
}) {
  return (
    <svg
      id="Group_551"
      data-name="Group 551"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.379 45.378"
    >
      <path
        id="Path_3544"
        data-name="Path 3544"
        d="M1161.12,3737.51a22.689,22.689,0,1,1-22.69-22.689,22.69,22.69,0,0,1,22.69,22.689"
        transform="translate(-1115.741 -3714.821)"
        fill={color}
      />
      <path
        id="Path_3545"
        data-name="Path 3545"
        d="M1159.278,3733.736a1.249,1.249,0,0,0-1.159-1.106c-.333-.022-.789-.058-1.919-.072l-1.184,1.283-9.785,10.406-4.27-4.068-2.115,2.2,6.67,6.353,11.328-12.672a36.047,36.047,0,0,1-1.021,7.519c-2.2,9.19-8.779,12.1-10,12.568-1.176-.459-7.814-3.426-10-12.568a34.52,34.52,0,0,1-1.023-8.563c2.264-.253,7.462-1.021,10.529-2.941a21.269,21.269,0,0,0,6.488,2.21l2.032-2.165c-2.846-.467-6.116-1.265-7.686-2.5a1.25,1.25,0,0,0-.771-.267h-.135a1.25,1.25,0,0,0-.771.267c-2.531,1.988-8.776,2.848-10.969,3.011a1.248,1.248,0,0,0-1.148,1.1,32.929,32.929,0,0,0,1.025,10.424c2.715,11.339,11.466,14.306,11.838,14.427a1.246,1.246,0,0,0,.386.061h.413a1.249,1.249,0,0,0,.386-.061c.371-.121,9.122-3.087,11.837-14.427a32.933,32.933,0,0,0,1.025-10.424"
        transform="translate(-1123.134 -3721.312)"
        fill="#fff"
      />
    </svg>
  );
}
