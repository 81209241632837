import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { RadioStyled } from "./styled";
import { useTranslation } from "react-i18next";

const Radio = ({
  theme_standard,
  theme_horizontal,
  theme_question,
  theme_bosendorfer,
  theme_purple,
  label,
  errors,
  disabled,
  disabledType,
  options,
  name,
  onChange,
  value,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_horizontal: theme_horizontal,
    theme_question: theme_question,
    theme_bosendorfer: theme_bosendorfer,
    theme_purple: theme_purple
  });

  const { t } = useTranslation(["common"]);
  return (
    <RadioStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="radio_row">
          {disabled && disabledType === "no_choice" ? (
            <div className="radio_item">
              <input
                type="radio"
                disabled={true}
                id={name + value}
                name={name}
                value={value}
                // onChange={onChange}
                checked={true}
              />
              <label for={name + value}>{value}</label>
            </div>
          ) : (
            options &&
            options.map((e, i) => (
              <div key={i} className="radio_item">
                <input
                  type="radio"
                  disabled={disabled}
                  id={name + e.value}
                  name={name}
                  value={e.value}
                  onChange={onChange}
                  checked={e.value === value}
                />
                <label for={name + e.value}>{e.label}</label>
              </div>
            ))
          )}
        </div>
        {errors && <div className="errors">{t(errors, { ns: "common" })}</div>}
      </div>
    </RadioStyled>
  );
};

Radio.propTypes = {};

export default Radio;
