import React from "react";
import { connect } from "react-redux";
import { HistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { MemberSwitchTypeWidget } from "widgets";
import { Cards, Displays, Headers, Icons } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class HistoryContainer extends React.Component {
  state = {};

  _handleClick = (e) => {
    switch (e) {
      case "1":
        this.props.router.navigate(ROUTE_PATH.HISTORY_PRODUCT_WARRANTY);
        break;
      case "2":
        this.props.router.navigate(ROUTE_PATH.HISTORY_ACTIVITY);
        break;
      default:
        break;
    }
  };

  render() {
    const { i18n } = this.props;
    const { isLoading, isActive, typeSelected } = this.state;
    return (
      <HistoryContainerStyled selected={typeSelected}>
        <Headers.PageHeader
          theme_center
          title={"HISTORY"}
          subTitle={"ประวัติ"}
        />
        <MemberSwitchTypeWidget type="4" />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div
              className="menu_item_wrap"
              onClick={() => this._handleClick("1")}
            >
              <Cards.MenuItem
                icon={<Icons.MemberPerson2 />}
                name={
                  i18n.language === "en"
                    ? "Product Registration History"
                    : "ประวัติการลงทะเบียนสินค้า"
                }
              />
            </div>
            <div
              className="menu_item_wrap"
              onClick={() => this._handleClick("2")}
            >
              <Cards.MenuItem
                icon={<Icons.MemberCoperate />}
                name={
                  i18n.language === "en"
                    ? "Activity Registration History"
                    : "ประวัติการลงทะเบียนกิจกรรม"
                }
              />
            </div>
          </>
        )}
      </HistoryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common", "history"])(withRouter(HistoryContainer)));
