import styled from "styled-components";

export const ActivitiesContainerStyled = styled.div`
  .content {
    padding: 32px 20px 20px;
    .fav_row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 18px;
      .activities_item {
        margin-bottom: 26px;
        flex-basis: calc(50% - 9px);
        overflow: hidden;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
