import moment from "moment";
import { LanguageWidgetStyled } from "./styled";
import { useTranslation } from "react-i18next";
import "moment/locale/th"; // ต้อง import locale ภาษาไทย

const LanguageWidget = () => {
  const { i18n } = useTranslation(["common"]);

  const _handleChangeLang = (e) => {
    moment.locale(e);
    localStorage.setItem("selectedLanguage", e);
    i18n.changeLanguage(e);
  };

  return (
    <LanguageWidgetStyled>
      <button
        className={`btn_lang left ${i18n.language === "th" ? "active" : ""}`}
        onClick={() => _handleChangeLang("th")}
      >
        TH
      </button>
      <div className="linear"></div>
      <button
        className={`btn_lang right ${i18n.language === "en" ? "active" : ""}`}
        onClick={() => _handleChangeLang("en")}
      >
        EN
      </button>
    </LanguageWidgetStyled>
  );
};

export default LanguageWidget;
