import styled from "styled-components";

export const BtnViewMoreExpandStyled = styled.div`
  .btn_view_more {
    text-decoration: underline;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: pointer;
  }

  .theme_standard {
  }
`;
