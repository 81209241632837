import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BtnViewMoreExpandStyled } from "./styled";

const BtnViewMoreExpand = ({ theme_standard, label, children }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_isExpand, _setIsExpand] = useState(false);

  const _handleClick = () => {
    _setIsExpand(!_isExpand);
  };

  return (
    <BtnViewMoreExpandStyled className={customClass}>
      <div className="btn_view_more" onClick={_handleClick}>
        {label}
      </div>
      {_isExpand && <div className="">{children}</div>}
    </BtnViewMoreExpandStyled>
  );
};

BtnViewMoreExpand.propTypes = {};

export default BtnViewMoreExpand;
