import Activity from "./Activity";
import ActivityFormInfoConfirm from "./ActivityFormInfoConfirm";
import ActivityInfoConfirm from "./ActivityInfoConfirm";
import BookingInfoConfirm from "./BookingInfoConfirm";
import ConditionCard from "./ConditionCard";
import CorperateInfo from "./CorperateInfo";
import CustomerActFRegisItem from "./CustomerActFRegisItem";
import MenuItem from "./MenuItem";
import NavigationMenuItem from "./NavigationMenuItem";
import New from "./New";
import NewInfo from "./NewInfo";
import ProductRedeem from "./ProductRedeem";
import ProductWarrantee from "./ProductWarrantee";
import Reward from "./Reward";
import ScanInfo from "./ScanInfo";
import UserInfo from "./UserInfo";
import Question from "./Question";
import QuestionAnsItem from "./QuestionAnsItem";

const EXPORT = {
  Activity,
  ActivityFormInfoConfirm,
  ActivityInfoConfirm,
  BookingInfoConfirm,
  ConditionCard,
  CorperateInfo,
  CustomerActFRegisItem,
  MenuItem,
  NavigationMenuItem,
  New,
  NewInfo,
  ProductRedeem,
  ProductWarrantee,
  Reward,
  ScanInfo,
  UserInfo,
  Question,
  QuestionAnsItem,
};

export default EXPORT;
