import styled from "styled-components";

export const MenuBarWidgetStyled = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  border-bottom: 0px;
  margin: 0px 5px 0px 5px;
  display: flex;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .menu_item {
    flex-basis: 20%;
    padding: 6px 0px 18px 0px;
    cursor: pointer;
    .mi_icon_wrap {
      display: flex;
      justify-content: center;
      min-height: 48.87px;
    }
    .mi_label {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) =>
        `${theme.FONT.STYLE.GT_AMERICA_COMPRESSED_BOLD},${theme.FONT.STYLE.PROMPT_MEDIUM}`};
    }
  }
  .menu_scan_item {
    margin-top: -25px;
    flex-basis: 20%;
    cursor: pointer;
  }
`;
