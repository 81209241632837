import styled from "styled-components";

export const SystemLayoutStyled = styled.div`
  position: relative;
  padding: 24px 0 0 0;
  margin: auto;
  border-left: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  border-right: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  background-color: ${({ theme }) => theme.COLORS.WHITE_1};
  .header_mainlayout {
    .lang_noti_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .body_mainlayout {
    min-height: calc(100vh - 175px);
  }
  .menu_mainlayout {
    position: sticky;
    bottom: 0;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.MOBILE};
    z-index: 99;
  }
`;

export const SystemPageLayoutStyled = styled.div`
  margin: auto;
  border-left: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  border-right: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  background-color: ${({ theme }) => theme.COLORS.WHITE_1};
  min-height: 100vh;
  .header_mainlayout {
    padding: 24px 0 0 0;
    .lang_noti_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
