import React, { useEffect, useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DateTimeStyled } from "./styled";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const DateTime = ({
  theme_standard,
  theme_bosendorfer,
  format = "dd-MM-y",
  value,
  onChange,
  placeholderText,
  label,
  isRequire,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_bosendorfer: theme_bosendorfer,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (value && value !== _value) {
      _setValue(new Date(value));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateTimeStyled className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span> *</span>}
        </div>
      )}
      <DateTimePicker
        {...props}
        className={"input"}
        // calendarIcon={null}
        calendarProps={{ className: "input_calendar" }}
        clearIcon={null}
        format={format}
        value={_value}
        onChange={onChange}
      />
    </DateTimeStyled>
  );
};

DateTime.propTypes = {};

export default DateTime;
