import { useState } from "react";
import { ProfileUpdateWidgetStyled } from "./styled";
import { useDispatch } from "react-redux";
import {
  setReduxAuthenEmail,
  setReduxAuthenFirstname,
  setReduxAuthenLastname,
  setReduxAuthenPhoneNumber,
  setReduxAuthenOrganizationPhoneNumber,
  setReduxAuthenOrganizationProvince,
  setReduxAuthenOrganizationEmail,
  setReduxAuthenBirthday,
} from "store/reducers/authenReducer";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Buttons, Displays, Forms, Modals } from "components";

const ProfileUpdateWidget = ({ data, memberType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);

  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_isShowModal, _setIsShowModal] = useState(false);

  const _handleClickEdit = () => {
    _setIsShowModal(true);
  };

  const _handleCloseModal = () => {
    _setIsBgLoading(false);
    _setIsShowModal(false);
  };

  const _handleSubmit = async (values) => {
    _setIsBgLoading(true);
    let params = {
      ...data,
      ...values,
    };
    if (values.province) {
      params.province = values.province.value;
    }
    if (memberType === "2") {
      let res = await userService.PUT_MY_ORGANIZE_UPDATE_DETAIL(params);
      if (res && res.status === 200) {
        dispatch(setReduxAuthenOrganizationEmail(res.data.email));
        dispatch(setReduxAuthenOrganizationPhoneNumber(res.data.phone_number));
        dispatch(setReduxAuthenOrganizationProvince(res.data.province));
        _handleCloseModal();
        toast.success("อัพเดตสำเร็จ");
      } else {
        _handleCloseModal();
        toast.error("ไม่สามารถอัพเดทได้");
      }
    } else {
      let res = await userService.PUT_EMAIL_UPDATE_DETAIL(params);
      if (res && res.status === 200) {
        dispatch(setReduxAuthenFirstname(res.data.first_name));
        dispatch(setReduxAuthenLastname(res.data.last_name));
        dispatch(setReduxAuthenPhoneNumber(res.data.phone_number));
        dispatch(setReduxAuthenEmail(res.data.email));
        dispatch(setReduxAuthenBirthday(res.data.birthday));
        _handleCloseModal();
        toast.success("อัพเดตสำเร็จ");
      } else {
        _handleCloseModal();
        toast.error("ไม่สามารถอัพเดทได้");
      }
    }
  };

  return (
    <>
      <Displays.BGLoading visible={_isBgLoading} />
      <ProfileUpdateWidgetStyled>
        <Buttons.BgStandard label={t("Edit")} onClick={_handleClickEdit} />
      </ProfileUpdateWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        <Forms.ProfileForm onSubmit={_handleSubmit} initialValues={data} />;
      </Modals.BodyEmpty>
    </>
  );
};

export default ProfileUpdateWidget;
