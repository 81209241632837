import styled from "styled-components";

export const NavigationMenuItemStyled = styled.div`
  position: relative;
  border-radius: 50px;
  height: 43px;
  padding: 0px 12px 0px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 19px;
  background: ${({ theme }) => theme.COLORS.PURPLE_1};
  cursor: pointer;
  .nmi_left_col {
    flex: 1;
    display: flex;
    column-gap: 5.5px;
    .rc_label {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme, language }) =>
        language === "en" ? theme.FONT.SIZE.S22 : theme.FONT.SIZE.S18};
      font-family: ${({ theme }) =>
        `${theme.FONT.STYLE.GT_AMERICA_COMPRESSED_BOLD},${theme.FONT.STYLE.PROMPT_REGULAR}`};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rc_sub_label {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .nmi_right_col {
    flex-basis: 14px;
    flex-shrink: 0;
  }
  .surver_status {
    border-radius: 12px;
    position: absolute;
    right: 13px;
    bottom: 14px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S11};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
`;
