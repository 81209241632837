import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { MenuItemStyled } from "./styled";
import { Icons } from "components";

const MenuItem = ({ theme_standard, name, icon }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <MenuItemStyled className={customClass}>
      <div className="menu_item_row">
        <div className="ct_icon_wrap">{icon}</div>
        {name}
      </div>
      <Icons.ArrowRight />
    </MenuItemStyled>
  );
};

MenuItem.propTypes = {};

export default MenuItem;
