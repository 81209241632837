import { BannerWidgetContainer } from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import cx from "classnames";
import { IMG_PATH } from "utils/constants/imgPath";
import { useNavigate } from "react-router-dom";
import { Icons } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

const BannerWidget = ({ theme_center, data }) => {
  const customClass = cx({
    theme_center: theme_center,
  });

  const navigate = useNavigate();

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class=${className}></span>`;
    },
  };

  const _handleClickItem = (e) => {
    switch (e.action_type) {
      case "1":
      case "2":
      case "3":
        navigate(ROUTE_PATH.ACTIVITIES);
        break;
      case "4":
        navigate(ROUTE_PATH.PRODUCT_WARRANTEE);
        break;
      default:
        break;
    }
  };

  return (
    <BannerWidgetContainer className={customClass}>
      <Swiper
        className="banner_container"
        loop
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={3000}
        slidesPerView={"auto"}
        modules={[Autoplay, Pagination, Navigation]}
        pagination={pagination}
        navigation={{
          nextEl: ".custom_next",
          prevEl: ".custom_prev",
        }}
      >
        {data &&
          data.map((e, i) => (
            <SwiperSlide key={i} className="banner_item">
              {e.action_type === "5" && e.url ? (
                <a href={e.url} target="_blank" rel="noreferrer">
                  {e.node}
                  <img
                    className="banner_img"
                    alt={e.alt}
                    src={IMG_PATH + e.img_path}
                  />
                </a>
              ) : (
                <div onClick={() => _handleClickItem(e)}>
                  {e.node}
                  <img
                    className="banner_img"
                    alt={e.alt}
                    src={IMG_PATH + e.img_path}
                  />
                </div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="custom_prev">
        <Icons.ArrowPrev />
      </div>
      <div className="custom_next">
        <Icons.ArrowNext />
      </div>
    </BannerWidgetContainer>
  );
};

export default BannerWidget;
