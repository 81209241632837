import styled from "styled-components";

export const BannerWidgetContainer = styled.div`
  position: relative;
  .banner_container {
    min-height: 218px;
    .banner_item {
      position: relative;
      .banner_img {
        padding-bottom: 16px;
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
    }
    .swiper-pagination-bullet {
      border-radius: 12px;
      width: 40px;
      height: 2px;
      background: ${({ theme }) => theme.COLORS.GRAY_7};
      &.swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
  }
  .custom_prev {
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: 5px;
    z-index: 10;
  }
  .custom_next {
    cursor: pointer;
    position: absolute;
    top: 45%;
    right: 5px;
    z-index: 2;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .banner_container {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
    }
  }
`;
