import styled from "styled-components";

export const AudioEquipmentContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .fav_row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .activities_item {
      margin-bottom: 28px;
      flex-basis: calc(50% - 10px);
      overflow: hidden;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .sum_title {
    margin-bottom: 30px;
    margin-left: 30px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .detail_card {
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 30px;
    .card_title {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .detail_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
    }
    .info {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      &.mb_20 {
        margin-bottom: 20px;
      }
      &.mb_40 {
        margin-bottom: 40px;
      }
    }
    .btn_wrap {
    }
  }
`;
