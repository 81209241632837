import styled from "styled-components";

export const UploadStyled = styled.div`
  .label {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .dropzone {
    cursor: pointer;
    .input_upload_container {
      border-radius: 21px;
      border: 0.5px solid ${({ theme }) => theme.COLORS.PUEPLE_1};
      padding: 0 24px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }
  .upload_reccomment {
    margin-top: 4px;
    margin-left: 4px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }

  .theme_question {
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
    .dropzone {
      .input_upload_container {
        height: 46px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      }
    }
  }
`;
