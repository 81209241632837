import styled from "styled-components";

export const CelendarWidgetStyled = styled.div`
  .cd_header_row {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .top_left {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    }
    .action_next_prev_block {
      display: flex;
      column-gap: 14px;
      text-decoration: underline;
      .top_right {
        cursor: pointer;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S15};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }
  .cd_body {
    display: flex;
    column-gap: 5px;
    .table_left_column {
      flex-basis: 50px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      .time_cell {
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 4px);
        height: 30px;
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        white-space: pre-wrap;
        line-height: 14px;
        &.f_16 {
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        }
      }
    }
    .table_right_column {
      flex: 1;
      .day_row {
        margin-bottom: 5px;
        display: flex;
        column-gap: 5px;
        .day_cell {
          padding: 2px;
          width: calc(13.28% - 5.5px);
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: ${({ theme }) => theme.COLORS.PURPLE_1};
          text-align: center;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S11};
          white-space: pre-wrap;
          line-height: 14px;
        }
      }
      .data_cell_block {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 5px;
        .data_cell {
          border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1}69;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(13.28% - 7.5px);
          height: 28px;
          background: #707070;
          color: #ffffff;
          white-space: pre-wrap;
          text-align: center;
        }
      }
      .bottom {
        margin-bottom: 30px;
        display: flex;
        .status_detail {
          margin-right: 12px;
          height: 15px;
          display: flex;
          align-items: center;
          .box {
            width: 26px;
            height: 15px;
            margin-right: 10px;
            &.full {
              border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1}69;
              background: ${({ theme }) => theme.COLORS.PURPLE_1}69;
            }
            &.almost {
              border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_7}66;
              background: ${({ theme }) => theme.COLORS.PURPLE_7}66;
            }
            &.available {
              border: 1px solid ${({ theme }) => theme.COLORS.GRAY_7};
              background: ${({ theme }) => theme.COLORS.WHITE_1};
            }
          }
          .status_title {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          }
        }
      }
    }
  }

  &.theme_bosendorfer {
    .cd_header_row {
      margin-bottom: 7px;
      .top_left {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
      }
      .action_next_prev_block {
        .top_right {
          text-decoration: underline;
          color: ${({ theme }) => theme.COLORS.GOLD_2};
          font-size: ${({ theme }) => theme.FONT.SIZE.S18};
          font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
        }
      }
    }
    .cd_body {
      .table_left_column {
        .time_cell {
          background: ${({ theme }) => theme.COLORS.GOLD_2};
        }
      }
      .table_right_column {
        .day_row {
          .day_cell {
            background: ${({ theme }) => theme.COLORS.GOLD_2};
          }
        }
        .data_cell_block {
          .data_cell {
            border: 1px solid ${({ theme }) => theme.COLORS.GOLD_2};
            width: calc(13.28% - 7.5px);
            height: 28px;
          }
        }
        .bottom {
          margin-bottom: 43px;
          .status_detail {
            .box {
              border: 1px solid ${({ theme }) => theme.COLORS.GOLD_2};
              &.full {
                background: #00000069;
              }
              &.almost {
                background: #96969669;
              }
              &.available {
                background: #ffffff;
              }
            }
            .status_title {
              color: ${({ theme }) => theme.COLORS.GOLD_2};
              font-size: ${({ theme }) => theme.FONT.SIZE.S12};
              font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
            }
          }
        }
      }
    }
  }
`;
