import React from "react";
import { BookingBosendorferStep1FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { BOOKING_PURPOSE_MUSIC_INSTRUMENT_OPTIONS } from "utils/functions/booking";
import { Buttons, Icons, Inputs } from "components";

const BookingBosendorferStep1Form = ({ ageOptions, onSubmit, onBack }) => {
  const { i18n } = useTranslation([""]);

  const schema = yup.object().shape({
    purpose: yup.object().nullable().required("required"),
    age_range: yup.object().nullable().required("required"),
    // instrument_type: yup.object().nullable().required("required"),
    // product: yup.object().nullable().required("required"),
    // price_range: yup.object().nullable().required("required"),
    // model_info: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <BookingBosendorferStep1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="section">
          <div className="header">
            <div className="title">
              Musical Instrument Test, Showroom Visit Booking
            </div>
            <div className="sub_title">
              Please provide the following details so that we can prepare the
              musical instruments for testing or arrange a suitable visit based
              on your objectives
            </div>
            <div className="sub_label">
              1. <Icons.GoldBtnIcon />
            </div>
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  theme_bosendorfer
                  options={BOOKING_PURPOSE_MUSIC_INSTRUMENT_OPTIONS.map(
                    (e) => ({
                      ...e,
                      label: i18n.language === "en" ? e.labelEn : e.labelEn,
                      value: e.value,
                    })
                  )}
                  placeholder={"Purpose of purchase or visit"}
                  errors={errors.purpose?.message}
                />
              )}
              name="purpose"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  theme_bosendorfer
                  options={ageOptions}
                  placeholder={"Choose the age of the music instrument players"}
                  errors={errors.age_range?.message}
                />
              )}
              name="age_range"
            />
          </div>
          {/* <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={INSTUMENT_TYPE_OPTIONS.map((e) => ({
                    ...e,
                    label: i18n.language === "en" ? e.labelEn : e.labelTh,
                    value: e.value, // เพิ่ม value
                  }))}
                  placeholder={
                    i18n.language === "en"
                      ? "Choose type of musical instrument"
                      : "เลือกประเภทเครื่องดนตรี"
                  }
                  errors={errors.instrument_type?.message}
                  onChange={(e) => {
                    setValue("product", null);
                    field.onChange(e);
                    setProductOptions(
                      CATE_PRODUCT_OPTION_OBJECT[e.value]?.map((g) => ({
                        ...g,
                        label: i18n.language === "en" ? g.labelEn : g.labelTh,
                        value: g.value,
                      })) || []
                    );
                  }}
                />
              )}
              name="instrument_type"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={productOptions}
                  placeholder={
                    i18n.language === "en"
                      ? "Select product category"
                      : "เลือกกลุ่มสินค้า"
                  }
                  errors={errors.product?.message}
                  disabled={productOptions.length === 0}
                />
              )}
              name="product"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={[
                    {
                      label:
                        i18n.language === "en"
                          ? "0 – 10,000 THB"
                          : "0 – 10,000 บาท",
                      value: { start: 0, end: 10000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "10,000 - 50,000 THB"
                          : "10,000 - 50,000 บาท",
                      value: { start: 10000, end: 50000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "50,001 - 100,000 THB"
                          : "50,001 - 100,000 บาท",
                      value: { start: 50001, end: 100000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "100,001 - 300,000 THB"
                          : "100,001 - 300,000 บาท",
                      value: { start: 100001, end: 300000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "300,001 - 500,000 THB"
                          : "300,001 - 500,000 บาท",
                      value: { start: 300001, end: 500000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "500,001 - 1,000,000 THB"
                          : "500,001 - 1,000,000 บาท",
                      value: { start: 500001, end: 1000000 },
                    },
                    {
                      label:
                        i18n.language === "en"
                          ? "More than 1,000,000 THB"
                          : "มากกว่า 1,000,000 บาท",
                      value: { start: 1000001, end: 1000002 },
                    },
                  ]}
                  placeholder={
                    i18n.language === "en"
                      ? "Preferred price range"
                      : "ช่วงราคาที่สนใจ"
                  }
                  errors={errors.price_range?.message}
                />
              )}
              name="price_range"
            />
          </div>
          <div className="input_wrap mb_38">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  theme_question
                  placeholder={
                    i18n.language === "en"
                      ? "Please specify the model or provide additional details"
                      : "ระบุรุ่น หรือ ข้อมูลเพิ่มเติม"
                  }
                />
              )}
              name={`model_info`}
            />
          </div> */}
          <div className="f_action_row">
            <div className="btn_wrap">
              <div className="icon_wrap left">
                <Icons.ArrowLeft2 />
              </div>
              <Buttons.BgStandard
                theme_bosendorfer
                onClick={onBack}
                label="Back"
              />
            </div>
            <div className="btn_wrap">
              <div className="icon_wrap right">
                <Icons.ArrowRight2 />
              </div>
              <Buttons.BgStandard
                theme_bosendorfer
                type="submit"
                label="Next"
              />
            </div>
          </div>
        </div>
      </form>
    </BookingBosendorferStep1FormStyled>
  );
};

BookingBosendorferStep1Form.propTypes = {};

export default BookingBosendorferStep1Form;
