import { useState, useEffect } from "react";
import { SystemLayoutStyled, SystemPageLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { userService } from "apiServices";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { setReduxWallet } from "store/reducers/walletReducer";
import { ROUTE_PATH } from "utils/constants/routePath";
import "moment/locale/th"; // ต้อง import locale ภาษาไทย
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  LanguageWidget,
  MenuBarWidget,
  NotificationWidget,
  PopupWidget,
} from "widgets";
import { Displays, Headers } from "components";

const SystemLayout = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    moment.locale(i18n.language);
    _fetchMyInfomation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchMyInfomation = async () => {
    let resArr = await Promise.all([
      userService.GET_MY_PROFILE(),
      userService.GET_MY_WALLET(),
      userService.GET_PDPA_LATEST(),
    ]);
    if (resArr) {
      dispatch(setReduxAuthen(resArr[0].data));
      dispatch(setReduxWallet(resArr[1].data));
      if (
        !resArr[0].data.pdpa_accepted_latest_ref_id ||
        resArr[0].data.pdpa_accepted_latest_ref_id !== resArr[2].data._id
      ) {
        navigate(`${ROUTE_PATH.PDPA}`);
      }
      _setIsLoading(false);
    }
  };

  const locationSplit = !location ? "" : location.pathname.split("/");
  const isSinglePage =
    locationSplit[3] === "activity-form" && locationSplit[5] === "quiz";
  return isSinglePage ? (
    <SystemPageLayoutStyled>
      <div className="header_mainlayout">
        <Headers.Logo mb="0px" />
        <div className="lang_noti_block">
          <LanguageWidget />
          <NotificationWidget />
        </div>
      </div>
      {_isLoading ? <Displays.Loading /> : <Outlet />}
    </SystemPageLayoutStyled>
  ) : (
    <SystemLayoutStyled>
      <div className="header_mainlayout">
        <Headers.Logo mb="0px" />
        <div className="lang_noti_block">
          <LanguageWidget />
          <NotificationWidget />
        </div>
      </div>
      <div className="notification_mainlayout"></div>
      <div className="popup_mainlayout">
        <PopupWidget />
      </div>
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading /> : <Outlet />}
      </div>
      <div className="menu_mainlayout">
        <MenuBarWidget />
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
