import React from "react";
import { ProductWarranteeSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { withTranslation } from "react-i18next";
import { Displays, Headers, Icons } from "components";

class ProductWarranteeSuccessContainer extends React.Component {
  handleClickHistory = () => {
    this.props.router.navigate(`${ROUTE_PATH.HISTORY}`);
  };

  render() {
    const { i18n } = this.props;
    return (
      <ProductWarranteeSuccessContainerStyled>
        <Headers.PageHeader
          icons={<Icons.Warranty width={45.38} height={45.38} />}
          title={"PRODUCT WARRANTY REGISTRATION"}
          subTitle={"ลงทะเบียนรับประกันสินค้า"}
        />
        <Displays.AlertLabel
          label={[
            i18n.language === "en"
              ? "Thank you for registering for product warranty."
              : "ขอบคุณสำหรับการลงทะเบียนรับประกันสินค้า",
            i18n.language === "en"
              ? "You can check your registration status at"
              : "ท่านสามารถตรวจสอบสถานะการลงเบียนได้ที่",
            i18n.language === "en" ? "History Menu" : "เมนู History",
          ]}
          actionLabel={
            i18n.language === "en" ? "Go To History Menu" : "ไปที่ History"
          }
          onClickAction={this.handleClickHistory}
        />
      </ProductWarranteeSuccessContainerStyled>
    );
  }
}

export default withTranslation(["common"])(
  withRouter(ProductWarranteeSuccessContainer)
);
