import { useState } from "react";
import { ScanQrCodeWidgetStyled, CameraScanContainer } from "./styled";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-scanner";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AlertControl } from "components/Modals/Alert";
import { Displays, Icons, Modals } from "components";

const ScanQrCodeWidget = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["menu"]);

  const [_isModal, _setModal] = useState(false);
  const [_isBgLoading, _setIsBgLoading] = useState(false);

  const _handleClickModal = () => {
    _setModal(true);
  };
  const _handleClickCloseModal = () => {
    _setModal(false);
  };

  const _successScan = (e) => {
    if (e) {
      _handleSubmit && _handleSubmit(e.text);
    }
  };

  const _handleSubmit = async (sn) => {
    _handleClickCloseModal();
    _setIsBgLoading(true);
    const split = sn.split("/");
    const serialNo = split[split.length - 1];
    switch (serialNo) {
      case "showroom":
        _handleShowRoomScan();
        _handleClickCloseModal();
        break;
      default:
        let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
          serialNo
        );
        if (res && res.status === 200) {
          _handleClickCloseModal();
          _setIsBgLoading(false);
          navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}/${serialNo}`);
        } else {
          _setIsBgLoading(false);
          toast.error("เลขซีเรียลไม่ถูกต้อง");
          _handleClickCloseModal();  
        }
        break;
    }
  };

  const _handleShowRoomScan = async () => {
    AlertControl.show({
      title: "ยืนยันการเข้าโชว์รูม",
      description: "คุณต้องการเชื่อมข้อมูลการเข้าใช้โขว์รูม ใช่หรือไม่",
      btnLabel1: "กลับ",
      btnLabel2: "ตกลง",
      onEvent2: async () => {
        _setIsBgLoading(true);
        let res = await userService.POST_SHOWROOM_SCAN_QR_BY_CUSTOMER();
        if (res && res.status === 200) {
          _setIsBgLoading(false);
          toast.success("เชื่อมข้อมูลการเข้าใช้โชว์รูมสำเร็จ");
          this.props.router.navigate(ROUTE_PATH.HOME);
        } else {
          _setIsBgLoading(false);
          toast.error(
            "เชื่อมข้อมูลไม่สำเร็จ เนื่องจากไอดีของท่านถูกแบนหรือวันนี้มีการเชื่อมข้อมูลแล้ว กรุณาติดต่อเจ้าหน้าที่"
          );
        }
      },
    });
  };

  return (
    <>
      <Displays.BGLoading visible={_isBgLoading} />
      <ScanQrCodeWidgetStyled lang={i18n.language}>
        <div className="scqr_icon_wrap" onClick={_handleClickModal}>
          <div className="btn_scan">
            <Icons.MenuBarScan />
          </div>
        </div>
        <div className={`scqr_label`}>{t("SCAN", { ns: "menu" })}</div>
      </ScanQrCodeWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_isModal}
        onCloseModal={_handleClickCloseModal}
      >
        <CameraScanContainer>
          <div className="btn_cmra_back" onClick={_handleClickCloseModal}>
            <Icons.ArrowLeft />
          </div>
          <QrReader
            delay={300}
            style={{
              width: "100%",
              height: "100%",
            }}
            vide
            constraints={{
              video: {
                facingMode: { exact: `environment` },
              },
            }}
            onError={() => {}}
            onScan={_successScan}
          />
          {/* <button
            type="button"
            style={{
              position: "absolute",
              left: 20,
              bottom: 20,
              color: "white",
              background: "red",
            }}
            onClick={() => _successScan("IKK019003-WW36280")}
          >
            Test
          </button> */}
        </CameraScanContainer>
      </Modals.BodyEmpty>
    </>
  );
};

export default ScanQrCodeWidget;
