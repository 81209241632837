import React from "react";
import { VisitorFollowerFormStyled } from "./styled";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const VisitorFollowerForm = ({ participantNo, onBack, onSubmit }) => {
  const schema = yup.object().shape({
    participant_list: yup.array().of(
      yup.object().shape({
        first_name: yup.string().required("ชื่อจำเป็นต้องกรอก"),
        last_name: yup.string().required("นามสกุลจำเป็นต้องกรอก"),
        birthday: yup.string().required("วันเกิดจำเป็นต้องกรอก"),
      })
    ),
  });

  const _renderPaticipant = (e) => {
    let options = [];
    for (let index = 0; index < e; index++) {
      options.push({ label: String(index + 1), value: String(index + 1) });
    }
    return options;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      participant_list: _renderPaticipant(participantNo),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "participant_list",
  });

  const { t } = useTranslation(["common", "form", "register"]);

  return (
    <VisitorFollowerFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="title">
          {t("Please enter the details of the follower joining the activity.", {
            ns: "form",
          })}{" "}
        </div>
        <div className="sub_title_block">
          <div className="sub_title">{t("Add Follower", { ns: "form" })}</div>
          <div className="sub_title_no">{participantNo}</div>
          <div className="sub_title">{t("person", { ns: "common" })}</div>
        </div>
        {fields.map((e, i) => (
          <div key={e.id} className="input_row">
            <div className="person_no">
              {t("Follower number", { ns: "form" })} {i + 1}
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder={t("First Name", { ns: "form" })}
                    errors={errors.participant_list?.[i]?.name?.message}
                  />
                )}
                name={`participant_list[${i}].first_name`}
                defaultValue={e.first_name}
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire
                    placeholder={t("Last Name", { ns: "form" })}
                    errors={errors.participant_list?.[i]?.surname?.message}
                  />
                )}
                name={`participant_list[${i}].last_name`}
                defaultValue={e.last_name}
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTimePicker
                    {...field}
                    isRequire
                    label={`${t("Date of birth", { ns: "register" })}`}
                    errors={errors.participant_list?.[i]?.birthDate?.message}
                  />
                )}
                name={`participant_list[${i}].birthday`}
                defaultValue={e.birthday}
              />
            </div>
          </div>
        ))}
        <div className="action_row">
          <div className="btn_wrap">
            <Buttons.BgStandard
              label={t("Back", { ns: "common" })}
              onClick={onBack}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BgStandard
              type="submit"
              label={t("Next", { ns: "common" })}
            />
          </div>
        </div>
      </form>
    </VisitorFollowerFormStyled>
  );
};

VisitorFollowerForm.propTypes = {};

export default VisitorFollowerForm;
