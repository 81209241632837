import React from "react";
import { PreLineAuthProductWarrantyContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";

class PreLineAuthProductWarrantyContainer extends React.Component {
  state = {};

  handleSyncLineSuccess = () => {
    this.props.router.navigate(ROUTE_PATH.SPLASH);
  };

  render() {
    return (
      <PreLineAuthProductWarrantyContainerStyled>
        <img
          className="logo_img"
          alt="yamaha logo"
          src="/assets/images/logos/logo.svg"
        />
        <div className="header_layout">
          <div className="title">กรุณาสมัครสมาชิก SMY Connect</div>
          <div className="title">(Siam Music Members)</div>
          <div className="title">เพื่อลงทะเบียนรับประกันสินค้า</div>
          <div className="title">ผ่านช่องทาง Line OA: YAMAHA MUSIC TH</div>
        </div>
        <div className="header_layout mb_2rem">
          <div className="title">Please register for SMY Connect</div>
          <div className="title">(Siam Music Members)</div>
          <div className="title">to activate your product warranty</div>
          <div className="title">via Line OA: YAMAHA MUSIC TH</div>
        </div>
        <div className="action_layout">
          <a href="https://lin.ee/UK3AqLs">
            <div className="btn_next">Next</div>
          </a>
        </div>
        <div className="reccommend_layout">
          <div className="rec_block">
            <div className="rec_col">
              <div className="rec_item">*</div>
            </div>
            <div className="rec_col">
              <div className="rec_item">
                ในกรณีที่ท่านเป็นสมาชิกแล้ว เลือกปุ่ม "Next"
              </div>
              <div className="rec_item">
                (If you are already a member,please select "Next")
              </div>
            </div>
          </div>
          <div className="rec_block">
            <div className="rec_col">*</div>
            <div className="rec_col">
              <div className="rec_item">
                ท่านจะสามารถตรวจสอบสถานะและระยะเวลารับประกันได้ ผ่านระบบ SMY
                Connect
              </div>
              <div className="rec_item">
                (You can check your product warranty status and coverage period
                through SMY Connect)
              </div>
            </div>
          </div>
        </div>
      </PreLineAuthProductWarrantyContainerStyled>
    );
  }
}

export default withTranslation(["common", "form"])(
  withRouter(PreLineAuthProductWarrantyContainer)
);
