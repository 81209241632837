import React from "react";

export default function renderIcon({
  width = 45.992,
  height = 45.492,
  color = "#9ca2b8",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.492 45.492"
    >
      <g
        id="Group_805"
        data-name="Group 805"
        transform="translate(-1875.282 -5286.434)"
      >
        <g
          id="Group_229"
          data-name="Group 229"
          transform="translate(1875.282 5286.434)"
        >
          <path
            id="Path_3786"
            data-name="Path 3786"
            d="M1920.774,5309.18a22.746,22.746,0,1,1-22.746-22.746,22.746,22.746,0,0,1,22.746,22.746"
            transform="translate(-1875.282 -5286.435)"
            fill="#4b1e78"
          />
        </g>
        <g
          id="Group_230"
          data-name="Group 230"
          transform="translate(1881.905 5293.056)"
        >
          <path
            id="Path_3787"
            data-name="Path 3787"
            d="M1903.343,5330.618a16.123,16.123,0,1,1,16.123-16.123A16.14,16.14,0,0,1,1903.343,5330.618Zm0-29.611a13.488,13.488,0,1,0,13.488,13.488A13.5,13.5,0,0,0,1903.343,5301.007Z"
            transform="translate(-1887.221 -5298.372)"
            fill="#fff"
          />
          <path
            id="Union_3"
            data-name="Union 3"
            d="M8107-6382.98a3.6,3.6,0,0,1,3.618-3.581,3.633,3.633,0,0,1,1.987.588v-6.418a2.86,2.86,0,0,1,1.723-2.967c1.483-.6,7.272-2.514,7.519-2.6l.145-.032a2.315,2.315,0,0,1,1.678.454,1.681,1.681,0,0,1,.559,1.316v9.1a3.557,3.557,0,0,1,.087.781,3.6,3.6,0,0,1-3.62,3.581,3.6,3.6,0,0,1-3.619-3.581,3.6,3.6,0,0,1,3.619-3.581,3.625,3.625,0,0,1,2.027.615v-6.918a.294.294,0,0,0-.05-.2.821.821,0,0,0-.437-.1c-.743.247-5.993,1.986-7.332,2.532a1.4,1.4,0,0,0-.8,1.447l.008.1v8.514a3.556,3.556,0,0,1,.126.94,3.6,3.6,0,0,1-3.62,3.582A3.6,3.6,0,0,1,8107-6382.98Zm1.506,0a2.1,2.1,0,0,0,2.112,2.092,2.1,2.1,0,0,0,2.113-2.092,2.1,2.1,0,0,0-2.113-2.091A2.1,2.1,0,0,0,8108.507-6382.98Zm10.075-3.352a2.105,2.105,0,0,0,2.113,2.091,2.105,2.105,0,0,0,2.114-2.091,2.106,2.106,0,0,0-2.114-2.091A2.106,2.106,0,0,0,8118.583-6386.332Z"
            transform="translate(-8100.036 6404.822)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
