import React from "react";
import { connect } from "react-redux";
import { BosendorferContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import moment from "moment";
import i18n from "i18n";
import { INSTUMENT_BOSENDORDER_TYPE_OPTIONS } from "utils/functions/productBooking";
import { AlertControl } from "components/Modals/Alert";
import { Buttons, Displays, Forms, Icons } from "components";

class BosendorferContainer extends React.Component {
  state = {
    step: 1,
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
    isLoading: true,
  };

  handleSwitchToSection2 = () => {
    this.setState({ isSection1: false });
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(`?start_date=${startDate}&end_date=${endDate}`);
  }

  fetchData = async (query) => {
    let resArr = await Promise.all([
      userService.GET_HOLIDATE_LIST(query),
      userService.GET_TIME_SLOT_LIST(query),
    ]);
    if (resArr) {
      this.setState({
        holidayData: resArr[0].data.docs,
        timeSlotData: resArr[1].data.docs,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmitStep1 = (values) => {
    this.setState({
      step1TempData: values,
      step: 2,
    });
  };

  handleSubmitStep2 = (values) => {
    let splitTime = values.time.split(",");
    let params = {
      ...values,
      start_time: splitTime[0],
      end_time: splitTime[1],
    };
    this.setState({
      step2TempData: params,
      step: 3,
    });
  };

  handleClickPrevWeek = () => {
    const { startDate } = this.state;
    const momentLimit = moment().endOf("day");
    const startTemp = moment(startDate, "YYYY-MM-DD")
      .subtract(7, "day")
      .format("YYYY-MM-DD");
    const endTemp = moment(startDate, "YYYY-MM-DD")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    if (momentLimit.isAfter(startDate, "YYYY-MM-DD")) {
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "คุณไม่สามารถดูย้อนหลังได้",
        btnLabel2: "ตกลง",
      });
    } else {
      this.setState(
        {
          startDate: startTemp,
          endDate: endTemp,
          isBgLoading: true,
        },
        () => {
          this.fetchData(`?start_date=${startTemp}&end_date=${endTemp}`);
        }
      );
    }
  };

  handleClickNextWeek = () => {
    const { endDate } = this.state;
    const momentLimit = moment().add(15, "day").endOf("day");
    const startTemp = moment(endDate, "YYYY-MM-DD")
      .add(1, "day")
      .format("YYYY-MM-DD");
    const endTemp = moment(endDate, "YYYY-MM-DD")
      .add(7, "day")
      .format("YYYY-MM-DD");
    if (momentLimit.isBefore(moment(endTemp, "YYYY-MM-DD"))) {
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "คุณสามารถดูล่วงหน้าได้เพียง 14 วันเท่านั้น",
        btnLabel2: "ตกลง",
      });
    } else {
      this.setState(
        {
          startDate: startTemp,
          endDate: endTemp,
          isBgLoading: true,
        },
        () => {
          this.fetchData(`?start_date=${startTemp}&end_date=${endTemp}`);
        }
      );
    }
  };

  handleSubmitBooking = async () => {
    const { authenRedux } = this.props;
    const { step1TempData, step2TempData } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...step1TempData,
      member_type_active: authenRedux.member_type_active,
      instrument_type_code: INSTUMENT_BOSENDORDER_TYPE_OPTIONS[0].value,
      instrument_type_en: INSTUMENT_BOSENDORDER_TYPE_OPTIONS[0].labelEn,
      instrument_type_th: INSTUMENT_BOSENDORDER_TYPE_OPTIONS[0].labelTh,
      // product_code: step1TempData.product.value,
      // product_en: step1TempData.product.labelEn,
      // product_th: step1TempData.product.labelTh,
      purpose: step1TempData.purpose.value,
      category: "3",
      ...step2TempData,
    };
    if (step1TempData.age_range) {
      params.age_min = step1TempData.age_range.value.start;
      params.age_max = step1TempData.age_range.value.end;
    }
    if (step1TempData.price_range) {
      params.price_range_min = step1TempData.price_range.value.start;
      params.price_range_max = step1TempData.price_range.value.end;
    }
    let res = await userService.POST_BOOKING_CREATE(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      toast.success("จองสำเร็จ");
      this.props.router.navigate(ROUTE_PATH.BOOKING);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("จองไม่สำเร็จ");
    }
  };

  handleBack = () => {
    const { step } = this.state;
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      if (step === 1) {
        this.props.router.navigate(ROUTE_PATH.BOOKING);
      } else {
        this.setState({
          step: step - 1,
          isLoading: false,
        });
      }
    }, 500);
  };

  render() {
    const {
      isBgLoading,
      isLoading,
      step,
      startDate,
      endDate,
      timeSlotData,
      holidayData,
      step2TempData,
    } = this.state;
    return (
      <BosendorferContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="bsdf_title">Flagship Store, Bangkok</div>
            <div className="bsdg_line_title" />
            {step === 1 && (
              <div className="section">
                <Forms.BookingBosendorferStep1Form
                  ageOptions={AGE_OPTIONS}
                  onSubmit={this.handleSubmitStep1}
                  onBack={this.handleBack}
                />
              </div>
            )}
            {step === 2 && (
              <div className="section">
                <Forms.BookingBosendorferStep2Form
                  startDate={startDate}
                  endDate={endDate}
                  timeSlotData={timeSlotData}
                  holidayData={holidayData}
                  onClickPrevWeek={this.handleClickPrevWeek}
                  onClickNextWeek={this.handleClickNextWeek}
                  onSubmit={this.handleSubmitStep2}
                  onBack={this.handleBack}
                />
              </div>
            )}
            {step === 3 && (
              <>
                <div className="bsdf_header">
                  <div className="title">
                    Musical Instrument Test, Showroom Visit Booking
                  </div>
                  <div className="sub_title">Please check detail</div>
                </div>
                <div className="detail_card">
                  <div className="card_title">
                    <Icons.GoldBtnIcon />
                  </div>
                  <div className="detail_title">Time</div>
                  <div className="info">
                    {moment(step2TempData.date).format("DD MMMM YYYY")}
                  </div>
                  <div className="info mb_40">
                    {step2TempData?.start_time} - {step2TempData?.end_time}
                  </div>
                  <div className="btn_wrap">
                    <Buttons.BgStandard
                      theme_bosendorfer
                      label="Confirm"
                      onClick={this.handleSubmitBooking}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </BosendorferContainerStyled>
    );
  }
}

const AGE_OPTIONS = [
  {
    label: i18n.language === "en" ? "3 - 12 years old" : "3 - 12 years old",
    value: { start: 3, end: 12 },
  },
  {
    label: i18n.language === "en" ? "13 - 18 years old" : "13 - 18 years old",
    value: { start: 13, end: 18 },
  },
  {
    label: i18n.language === "en" ? "19 - 24 years old" : "19 - 24 years old",
    value: { start: 19, end: 24 },
  },
  {
    label: i18n.language === "en" ? "25 - 34 years old" : "25 - 34 years old",
    value: { start: 25, end: 34 },
  },
  {
    label: i18n.language === "en" ? "35 - 44 years old" : "35 - 44 years old",
    value: { start: 35, end: 44 },
  },
  {
    label: i18n.language === "en" ? "45 - 54 years old" : "45 - 54 years old",
    value: { start: 45, end: 54 },
  },
  {
    label: i18n.language === "en" ? "65+ years old" : "65+ years old",
    value: { start: 65, end: 0 },
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(BosendorferContainer)));
