import React from "react";
import { RegisterCoperateFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import address from "utils/jsons/thailandAddress/address2.json";
import { GENDER_TYPE } from "utils/functions/customer";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const nameRegExp = /^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u;

const RegisterCoperateForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    organization_name: yup.string().required("Firstname is required"),
    // .matches(nameRegExp, 'Only alphabets are allowed for this field '),
    // organization_coporate_id: yup
    //   .string()
    //   .required('Firstname is required')
    //   .matches(nameRegExp, 'Only alphabets are allowed for this field '),
    organization_province: yup.object().required("Province is required"),
    organization_phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Invalid phone number"),
    organization_email: yup.string().email().required("Email is required"),
    first_name: yup
      .string()
      .required("Firstname is required")
      .matches(nameRegExp, "Only alphabets are allowed for this field "),
    last_name: yup
      .string()
      .required("Lastname is required")
      .matches(nameRegExp, "Only alphabets are allowed for this field "),
    gender: yup.object().required("Gender is required"),
    province: yup.object().required("Province is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Invalid phone number"),
    birthday: yup.string().required("Birthday is required"),
    email: yup.string().email().required("Email is required"),
    // is_accept_user_info: yup.bool().oneOf([true], 'Accept must be checked'),
    // is_accept_term_condition: yup
    //   .bool()
    //   .oneOf([true], 'Accept must be checked'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t, i18n } = useTranslation(["common", "register"]);

  const renderProvinceOptions = () => {
    return address.reduce((result, data) => {
      result.push({
        ...data,
        label: i18n.language === "en" ? data.name_en : data.name_th,
        value: data.id,
      });
      return result;
    }, []);
  };

  return (
    <RegisterCoperateFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_sec mb_20">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Organization Name", {
                    ns: "register",
                  })}`}
                  errors={errors.organization_name?.message}
                />
              )}
              name="organization_name"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Tax ID", {
                    ns: "register",
                  })} ${t("(if applicable)", { ns: "register" })}`}
                  errors={errors.organization_coporate_id?.message}
                />
              )}
              name="organization_coporate_id"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={renderProvinceOptions()}
                  placeholder={`* ${t("Province", { ns: "register" })}`}
                  errors={errors.organization_province?.message}
                />
              )}
              name="organization_province"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Mobile number", { ns: "register" })}`}
                  errors={errors.organization_phone_number?.message}
                />
              )}
              name="organization_phone_number"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Email", { ns: "register" })}`}
                  errors={errors.organization_email?.message}
                />
              )}
              name="organization_email"
              defaultValue=""
            />
          </div>
        </div>
        <div className="form_sec">
          <div className="fs_title">ชื่อผู้ติดต่อ</div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Name", { ns: "register" })}`}
                  errors={errors.first_name?.message}
                />
              )}
              name="first_name"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Surname", { ns: "register" })}`}
                  errors={errors.last_name?.message}
                />
              )}
              name="last_name"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={
                    i18n.language === "en"
                      ? GENDER_TYPE.map((e) => ({
                          label: e.labelEN,
                          value: e.value,
                        }))
                      : GENDER_TYPE
                  }
                  placeholder={`* ${t("Gender", { ns: "register" })}`}
                  errors={errors.gender?.message}
                />
              )}
              name="gender"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={renderProvinceOptions()}
                  placeholder={`* ${t("Province", { ns: "register" })}`}
                  errors={errors.province?.message}
                />
              )}
              name="province"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Mobile number", { ns: "register" })}`}
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTimePicker
                  {...field}
                  isShowMonthDropdown
                  isShowYearDropdown
                  maxDate={new Date()}
                  label={`* ${t("Date of birth", { ns: "register" })}`}
                  errors={errors.birthday?.message}
                />
              )}
              name="birthday"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t("Email", { ns: "register" })}`}
                  errors={errors.email?.message}
                />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>

        <Buttons.BgStandard
          type="submit"
          label={t("Register", { ns: "common" })}
        />
      </form>
    </RegisterCoperateFormStyled>
  );
};

RegisterCoperateForm.propTypes = {};

export default RegisterCoperateForm;
