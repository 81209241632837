import React from "react";
import { connect } from "react-redux";
import { ActivitiesContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { Cards, Displays, Headers, Icons } from "components";

class ActivitiesContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux } = this.props;
    let resArr = await Promise.all([
      userService.GET_ACTIVITY_LIST(`?is_active=true&pagination=false`),
      userService.GET_MY_ACTIVITY(
        `?member_type=${authenRedux.member_type_active}`
      ),
      userService.GET_ACTIVITY_FORM_LIST(`?is_active=true&pagination=false`),
      userService.GET_MY_ACTIVITY_FORM_LIST(
        `?member_type=${authenRedux.member_type_active}`
      ),
    ]);
    if (resArr) {
      const activityReduce = resArr[0].data.docs.reduce(
        (result, data) => {
          let find = resArr[1].data.find(
            (e) => String(e.activity_ref_id) === String(data._id)
          );
          if (find) {
            if (data.is_pin) {
              result.dataPin.push({
                ...data,
                activityType: "1",
                activityMe: { status: find.status },
              });
            } else {
              result.dataUnpin.push({
                ...data,
                activityType: "1",
                activityMe: { status: find.status },
              });
            }
          } else {
            if (data.is_pin) {
              result.dataPin.push({
                ...data,
                activityType: "1",
                activityMe: {
                  status:
                    data.announcement_type === "1" &&
                    data.permission_main_quotar === data.permission_main_active
                      ? "-2"
                      : "-1",
                },
              });
            } else {
              result.dataUnpin.push({
                ...data,
                activityType: "1",
                activityMe: {
                  status:
                    data.announcement_type === "1" &&
                    data.permission_main_quotar === data.permission_main_active
                      ? "-2"
                      : "-1",
                },
              });
            }
          }
          return result;
        },
        {
          dataPin: [],
          dataUnpin: [],
        }
      );
      const activityFormReduce = resArr[2].data.docs.reduce(
        (result, data) => {
          let find = resArr[3].data.find(
            (e) => String(e.activity_ref_id) === String(data._id)
          );
          if (find) {
            if (data.is_pin) {
              result.dataPin.push({
                ...data,
                activityType: "2",
                activityMe: { status: find.status },
              });
            } else {
              result.dataUnpin.push({
                ...data,
                activityType: "2",
                activityMe: { status: find.status },
              });
            }
          } else {
            if (data.is_pin) {
              result.dataPin.push({
                ...data,
                activityType: "2",
                activityMe: {
                  status:
                    data.announcement_type === "1" &&
                    data.permission_main_quotar === data.permission_main_active
                      ? "-2"
                      : "-1",
                },
              });
            } else {
              result.dataUnpin.push({
                ...data,
                activityType: "2",
                activityMe: {
                  status:
                    data.announcement_type === "1" &&
                    data.permission_main_quotar === data.permission_main_active
                      ? "-2"
                      : "-1",
                },
              });
            }
          }
          return result;
        },
        {
          dataPin: [],
          dataUnpin: [],
        }
      );
      this.setState({
        data: [
          ...activityFormReduce.dataPin,
          ...activityReduce.dataPin,
          ...[
            ...activityFormReduce.dataUnpin,
            ...activityReduce.dataUnpin,
          ].sort((a, b) => a.created_at - b.created_at),
        ],
        isLoading: false,
      });
    }
  };

  handleClickActivities = (e) => {
    switch (e.activityType) {
      case "1":
        this.props.router.navigate(`${ROUTE_PATH.ACTIVITIES}/${e._id}`);
        break;
      case "2":
        this.props.router.navigate(`${ROUTE_PATH.ACTIVITY_FORM}/${e._id}`);
        break;
      default:
        break;
    }
  };

  render() {
    const { t, i18n } = this.props;
    const { isLoading, data } = this.state;
    return (
      <ActivitiesContainerStyled>
        <div className="content">
          <Headers.PageHeader
            icons={<Icons.ActivityCircle />}
            title={"ACTIVITY / NEWS"}
            subTitle={"ข่าวสารและกิจกรรม"}
          />
          {isLoading ? (
            <Displays.Loading />
          ) : !data || data.length === 0 ? (
            <Displays.LabelEmpty label={t("No Data", { ns: "common" })} />
          ) : (
            <div className="fav_row">
              {data.map((e, i) => (
                <div
                  key={i}
                  className="activities_item"
                  onClick={() => this.handleClickActivities(e)}
                >
                  <Cards.Activity
                    src={e.img}
                    title={
                      i18n.language === "en"
                        ? e.short_title_en
                        : e.short_title_th
                    }
                    description={
                      i18n.language === "en"
                        ? e.short_description_en
                        : e.short_description_th
                    }
                    permission_type={e.permission_type}
                    announcement_type={e.announcement_type}
                    status={e.activityMe.status}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </ActivitiesContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(ActivitiesContainer)));
