import { HeaderBackHomeWidgetStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icons } from "components";

const HeaderBackHomeWidget = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const _handleClickBack = () => {
    navigate(-1);
  };

  return (
    <HeaderBackHomeWidgetStyled>
      <div className="btn_hdb" onClick={_handleClickBack}>
        <Icons.ArrowLeft2 color="#482174" />
      </div>
      <div className="hdb_label_block" onClick={_handleClickBack}>
        {/* <Icons.Home /> */}
        {t("Back", { ns: "common" })}
      </div>
    </HeaderBackHomeWidgetStyled>
  );
};

export default HeaderBackHomeWidget;
