import styled from "styled-components";

export const ProductWarranteeStyled = styled.div`
  .product_warrantee_container {
    padding: 0 27px;
    .warrantee_title {
      margin-bottom: 13px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
    }
    .img_wrap {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      .product_img {
        max-width: 108px;
        max-height: 100px;
        height: auto;
        margin-bottom: 8px;
      }
    }
    .title {
      margin-bottom: 7px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .pw_status {
      margin-bottom: 10px;
      width: 110px;
      height: fit-content;
      font-size: 12px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      padding: 5px 10px;
      border-radius: 50px;
      background-color: ${({ status }) =>
        status === "Waiting"
          ? "#FEDB04"
          : status === "Approved"
          ? "#0FB863"
          : status === "Expired"
          ? "#95909A"
          : "#FFD700"};
    }
    .pw_row {
      display: flex;
      &.block {
        display: block;
      }
      .label {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
      .value {
        margin-left: 4px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        &.flex {
          display: flex;
          column-gap: 20px;
        }
      }
      .pw_status {
        border-radius: 10px;
        min-width: 82px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ statusBg }) => statusBg};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
    .btn_edit_pw {
      margin-top: 4px;
      border-radius: 12px;
      width: fit-content;
      padding: 0 12px;
      background: ${({ theme }) => theme.COLORS.RED_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      cursor: pointer;
    }
  }

  .theme_standard {
  }
`;
