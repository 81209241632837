import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CorperateInfoStyled } from "./styled";
import { useTranslation } from "react-i18next";
import BtnEmailUpdateWidget from "widgets/BtnEmailUpdateWidget";

const CorperateInfo = ({
  theme_standard,
  coporate_id,
  phone_number,
  province,
  email,
  name,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t } = useTranslation(["profile", "register"]);

  return (
    <CorperateInfoStyled>
      <div className={customClass}>
        <div className="user_info_container">
          <div className="ui_row">
            <div className="ui_label">
              {t("Organization Name", { ns: "register" })}
            </div>
            <div className="ui_value">{`${name}`}</div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Tax ID", { ns: "register" })}
            </div>
            <div className="ui_value">{coporate_id || "-"}</div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Mobile number", { ns: "register" })}
            </div>
            <div className="ui_value">
              {phone_number}
              <BtnEmailUpdateWidget type="phone_number" memberType="2" />
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Email", { ns: "register" })}
            </div>
            <div className="ui_value">
              {email}
              <BtnEmailUpdateWidget type="email" memberType="2" />
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Address", { ns: "profile" })}</div>
            <div className="ui_value">
              {province}
              <BtnEmailUpdateWidget type="province" memberType="2" />
            </div>
          </div>
        </div>
      </div>
    </CorperateInfoStyled>
  );
};

CorperateInfo.propTypes = {};

export default CorperateInfo;
