import AccountListForm from "./AccountListForm";
import ActivityFormQuestionForm from "./ActivityFormQuestionForm";
import ActivityQuestionform from "./ActivityQuestionform";
import BookingBosendorferStep1Form from "./BookingBosendorferStep1Form";
import BookingBosendorferStep2Form from "./BookingBosendorferStep2Form";
import BookingStep1Form from "./BookingStep1Form";
import BookingStep2Form from "./BookingStep2Form";
import EmailForm from "./EmailForm";
import FirstnameForm from "./FirstnameForm";
import InterestedForm from "./InterestedForm";
import LastnameForm from "./LastnameForm";
import LoginForm from "./LoginForm";
import PDPAForm from "./PDPAForm";
import PersonalInfoForm from "./PersonalInfoForm";
import PhoneForm from "./PhoneForm";
import PhoneNumberForm from "./PhoneNumberForm";
import ProductWarranteeForm from "./ProductWarranteeForm";
import ProductWarranteeCoperateNoLoginForm from "./ProductWarranteeCoperateNoLoginForm";
import ProductWarranteeNoLoginForm from "./ProductWarranteeNoLoginForm";
import ProfileForm from "./ProfileForm";
import ProvinceForm from "./ProvinceForm";
import QuickTestActivityForm from "./QuickTestActivityForm";
import QuickTestForm from "./QuickTestForm";
import RegisterCorperateForm from "./RegisterCorperateForm";
import RegisterCorperateOnlyForm from "./RegisterCorperateOnlyForm";
import RegisterForm from "./RegisterForm";
import ScanQRCodeForm from "./ScanQRCodeForm";
import ShippingForm from "./ShippingForm";
import VerifyOTPForm from "./VerifyOTPForm";
import VisitorFollowerForm from "./VisitorFollowerForm";

const EXPORT = {
  AccountListForm,
  ActivityFormQuestionForm,
  ActivityQuestionform,
  BookingBosendorferStep1Form,
  BookingBosendorferStep2Form,
  BookingStep1Form,
  BookingStep2Form,
  EmailForm,
  FirstnameForm,
  InterestedForm,
  LastnameForm,
  LoginForm,
  PDPAForm,
  PersonalInfoForm,
  PhoneForm,
  PhoneNumberForm,
  ProductWarranteeCoperateNoLoginForm,
  ProductWarranteeForm,
  ProductWarranteeNoLoginForm,
  ProfileForm,
  ProvinceForm,
  QuickTestActivityForm,
  QuickTestForm,
  RegisterCorperateForm,
  RegisterCorperateOnlyForm,
  RegisterForm,
  ScanQRCodeForm,
  ShippingForm,
  VerifyOTPForm,
  VisitorFollowerForm,
};

export default EXPORT;
