import styled from "styled-components";

export const BosendorferContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .bsdf_title {
    margin-bottom: 32px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GOLD_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S25};
    font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
  }
  .bsdg_line_title {
    margin: auto;
    margin-bottom: 44px;
    width: 115px;
    height: 3px;
    background: ${({ theme }) => theme.COLORS.GOLD_2};
  }
  .detail_card {
    border: 1px solid ${({ theme }) => theme.COLORS.GOLD_2};
    padding: 30px;
    .card_title {
      margin-bottom: 33px;
      display: flex;
      justify-content: center;
    }
    .detail_title {
      margin-bottom: 18px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
    }
    .info {
      margin-bottom: 8px;
      color: ${({ theme }) => theme.COLORS.GOLD_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_BOLD};
      &.mb_20 {
        margin-bottom: 20px;
      }
      &.mb_40 {
        margin-bottom: 40px;
      }
    }
    .btn_wrap {
    }
  }

  .bsdf_header {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 22px;
      white-space: pre-wrap;
      color: ${({ theme }) => theme.COLORS.GOLD_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
    }
    .sub_title {
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.GLACIAL_INDIFFERENCE_REGULAR};
    }
  }
`;
