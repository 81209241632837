import styled from "styled-components";

export const HistoryContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  overflow: hidden;
  .product_item_wrap {
    padding: 0 3px;
  }
  .menu_item_wrap {
    margin-bottom: 16px;
  }
  .line_between {
    margin: 15px 0 12px 0px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
  .btn_history {
    margin-bottom: 16px;
    border-radius: 21px;
    border: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding-left: 50px;
    padding-right: 20px;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    .content {
      display: flex;
      align-items: center;
      column-gap: 15px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      .ct_icon_wrap {
        width: 24px;
      }
    }
  }
  .head_btn {
    display: flex;
    align-items: center;
    .btn_left {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      border-right: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      width: 50%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ selected, theme }) =>
        selected === "1"
          ? theme.FONT.STYLE.PROMPT_SEMIBOLD
          : theme.FONT.STYLE.PROMPT_REGULAR};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      cursor: pointer;
    }
    .btn_right {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      width: 50%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ selected, theme }) =>
        selected === "2"
          ? theme.FONT.STYLE.PROMPT_SEMIBOLD
          : theme.FONT.STYLE.PROMPT_REGULAR};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      cursor: pointer;
    }
  }
`;
