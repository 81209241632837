import styled from "styled-components";

export const HeaderWarrantyStatusStyled = styled.div`
  margin-bottom: ${({ mb }) => (mb ? mb : "18px")};
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    margin-bottom: 36px;
    column-gap: 15px;
    .hdw_icon_wrap {
      flex-shrink: 0;
    }
  }

  .theme_standard {
  }

  .theme_head_only {
    .header_icon_title_container {
      .hi_right_col {
        .lc_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S26};
        }
      }
    }
  }
`;
