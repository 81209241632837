import styled from "styled-components";

export const ProductWarranteeDetailContainerStyled = styled.div`
  padding: 60px 16px 104px 16px;
  .product_info_wrap {
    margin-bottom: 48px;
  }
  .product_more_item {
    padding: 12px 0px;
    ${({ theme }) => theme.COLORS.GRAT_1};
    border-top: 1px solid;
    &:first-child {
      margin-top: 4412px;
    }
  }
  .content2 {
    .arrow_next {
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    }

    .content_input {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  .btn_sec {
    display: flex;
    flex: 1;
    padding: 0 25px;
    .btn_edit {
      width: 100%;
      max-width: 150px;
    }
  }
  .btn_add_sn {
    margin-top: 12px;
    margin-bottom: 48px;
  }
`;
