import React, { useEffect } from "react";
import { PDPAFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const PDPAForm = ({
  privacy_title_en,
  privacy_title_th,
  privacy_body_en,
  privacy_body_th,
  privacy_priority_type,
  privacy_warning_msg_en,
  privacy_warning_msg_th,
  product_warranty_title_en,
  product_warranty_title_th,
  product_warranty_body_en,
  product_warranty_body_th,
  product_warranty_priority_type,
  product_warranty_warning_msg_en,
  product_warranty_warning_msg_th,
  certified_true_info_title_en,
  certified_true_info_title_th,
  certified_true_info_body_en,
  certified_true_info_body_th,
  certified_true_info_priority_type,
  certified_true_info_warning_msg_en,
  certified_true_info_warning_msg_th,
  marketing_title_en,
  marketing_title_th,
  marketing_body_en,
  marketing_body_th,
  marketing_priority_type,
  marketing_warning_msg_en,
  marketing_warning_msg_th,
  onClickMore,
  initialValues,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    is_accepted_privacy_pdpa: yup.string().required("required"),
    is_accepted_product_warranty_pdpa: yup.string().required("required"),
    is_accepted_certified_true_info_pdpa: yup.string().required("required"),
    is_accepted_marketing_pdpa: yup.string().required("required"),
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        is_accepted_privacy_pdpa: initialValues.is_accepted_privacy_pdpa
          ? "1"
          : "2",
        is_accepted_product_warranty_pdpa:
          initialValues.is_accepted_product_warranty_pdpa ? "1" : "2",
        is_accepted_certified_true_info_pdpa:
          initialValues.is_accepted_certified_true_info_pdpa ? "1" : "2",
        is_accepted_marketing_pdpa: initialValues.is_accepted_marketing_pdpa
          ? "1"
          : "2",
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t, i18n } = useTranslation(["common"]);

  const _handleChangeCheckAll = (e) => {
    if (e) {
      reset({
        is_accept_all: true,
        is_accepted_privacy_pdpa: "1",
        is_accepted_product_warranty_pdpa: "1",
        is_accepted_certified_true_info_pdpa: "1",
        is_accepted_marketing_pdpa: "1",
      });
    } else {
      reset({
        is_accept_all: false,
        is_accepted_privacy_pdpa: null,
        is_accepted_product_warranty_pdpa: null,
        is_accepted_certified_true_info_pdpa: null,
        is_accepted_marketing_pdpa: null,
      });
    }
  };

  const _onSubmit = (values) => {
    let err = [];
    if (
      privacy_priority_type === "1" &&
      values.is_accepted_privacy_pdpa === "2"
    ) {
      err.push(
        i18n.language === "en" ? privacy_warning_msg_en : privacy_warning_msg_th
      );
      setError(`is_accepted_privacy_pdpa`, {
        type: "custom",
        message:
          i18n.language === "en"
            ? privacy_warning_msg_en
            : privacy_warning_msg_th,
      });
    }
    if (
      product_warranty_priority_type === "1" &&
      values.is_accepted_product_warranty_pdpa === "2"
    ) {
      err.push(
        i18n.language === "en"
          ? product_warranty_warning_msg_en
          : product_warranty_warning_msg_th
      );
      setError(`is_accepted_product_warranty_pdpa`, {
        type: "custom",
        message:
          i18n.language === "en"
            ? product_warranty_warning_msg_en
            : product_warranty_warning_msg_th,
      });
    }
    if (
      certified_true_info_priority_type === "1" &&
      values.is_accepted_certified_true_info_pdpa === "2"
    ) {
      err.push(
        i18n.language === "en"
          ? certified_true_info_warning_msg_en
          : certified_true_info_warning_msg_th
      );
      setError(`is_accepted_certified_true_info_pdpa`, {
        type: "custom",
        message:
          i18n.language === "en"
            ? certified_true_info_warning_msg_en
            : certified_true_info_warning_msg_th,
      });
    }
    if (
      marketing_priority_type === "1" &&
      values.is_accepted_marketing_pdpa === "2"
    ) {
      err.push(
        i18n.language === "en"
          ? marketing_warning_msg_en
          : marketing_warning_msg_th
      );
      setError(`is_accepted_marketing_pdpa`, {
        type: "custom",
        message:
          i18n.language === "en"
            ? marketing_warning_msg_en
            : marketing_warning_msg_th,
      });
    }

    if (err.length > 0) {
      toast.error(err.join(", "));
    } else {
      onSubmit(values);
    }
  };

  return (
    <PDPAFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label={t("Accept All", { ns: "common" })}
                errors={errors.is_accept_all?.message}
                onChangeCustom={_handleChangeCheckAll}
              />
            )}
            name="is_accept_all"
            defaultValue={false}
          />
        </div>
        <div className="papd_item">
          <div className="pdpa_title">
            {i18n.language === "en" ? privacy_title_en : privacy_title_th}
          </div>
          <div
            className="pdpa_sub_title"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en" ? privacy_body_en : privacy_body_th,
            }}
          >
            {/* {privacy_body} */}
          </div>
          <div className="pdpa_more" onClick={() => onClickMore("privacy")}>
            {t("View more", { ns: "common" })}
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                theme_horizontal
                options={[
                  {
                    label: t("Accepted", { ns: "common" }),
                    value: "1",
                  },
                  {
                    label: t("Not Accepted", { ns: "common" }),
                    value: "2",
                  },
                ]}
                errors={errors.is_accepted_privacy_pdpa?.message}
              />
            )}
            name="is_accepted_privacy_pdpa"
            defaultValue=""
          />
        </div>
        <div className="papd_item">
          <div className="pdpa_title">
            {i18n.language === "en"
              ? product_warranty_title_en
              : product_warranty_title_th}
          </div>
          <div
            className="pdpa_sub_title"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? product_warranty_body_en
                  : product_warranty_body_th,
            }}
          >
            {/* {product_warranty_body} */}
          </div>
          <div
            className="pdpa_more"
            onClick={() => onClickMore("product_warranty")}
          >
            {t("View more", { ns: "common" })}
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                theme_horizontal
                options={[
                  {
                    label: t("Accepted", { ns: "common" }),
                    value: "1",
                  },
                  {
                    label: t("Not Accepted", { ns: "common" }),
                    value: "2",
                  },
                ]}
                errors={errors.is_accepted_product_warranty_pdpa?.message}
              />
            )}
            name="is_accepted_product_warranty_pdpa"
            defaultValue=""
          />
        </div>
        <div className="papd_item">
          <div className="pdpa_title">
            {i18n.language === "en"
              ? certified_true_info_title_en
              : certified_true_info_title_th}
          </div>
          <div
            className="pdpa_sub_title"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? certified_true_info_body_en
                  : certified_true_info_body_th,
            }}
          >
            {/* {certified_true_info_body} */}
          </div>
          <div
            className="pdpa_more"
            onClick={() => onClickMore("product_warranty")}
          >
            {t("View more", { ns: "common" })}
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                theme_horizontal
                options={[
                  {
                    label: t("Accepted", { ns: "common" }),
                    value: "1",
                  },
                  {
                    label: t("Not Accepted", { ns: "common" }),
                    value: "2",
                  },
                ]}
                errors={errors.is_accepted_certified_true_info_pdpa?.message}
              />
            )}
            name="is_accepted_certified_true_info_pdpa"
            defaultValue=""
          />
        </div>
        <div className="papd_item mb_22">
          <div className="pdpa_title">
            {i18n.language === "en" ? marketing_title_en : marketing_title_th}
          </div>
          <div
            className="pdpa_sub_title"
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en" ? marketing_body_en : marketing_body_th,
            }}
          >
            {/* {marketing_body} */}
          </div>
          <div className="pdpa_more" onClick={() => onClickMore("marketing")}>
            {t("View more", { ns: "common" })}
          </div>
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                theme_horizontal
                options={[
                  {
                    label: t("Accepted", { ns: "common" }),
                    value: "1",
                  },
                  {
                    label: t("Not Accepted", { ns: "common" }),
                    value: "2",
                  },
                ]}
                errors={errors.is_accepted_marketing_pdpa?.message}
              />
            )}
            name="is_accepted_marketing_pdpa"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard label={t("OK", { ns: "common" })} type="submit" />
      </form>
    </PDPAFormStyled>
  );
};

PDPAForm.propTypes = {};

export default PDPAForm;
