import styled from "styled-components";

export const MenuItemStyled = styled.div`
  border-radius: 21px;
  border: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  padding-left: 50px;
  padding-right: 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  .menu_item_row {
    display: flex;
    align-items: center;
    column-gap: 15px;
    white-space: nowrap;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    .ct_icon_wrap {
      width: 24px;
    }
  }

  .theme_standard {
  }
`;
