import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ProductWarranteeStyled } from "./styled";
import {
  CAL_WARRANTY_EXPIRE,
  RENDER_STATUS,
} from "utils/functions/productWarranty";
import { useTranslation } from "react-i18next";
import { RENDER_UNIT } from "utils/functions/unit";

const ProductWarrantee = ({
  theme_standard,
  description,
  descriptionLine2,
  sn,
  warrantyPeriod,
  warrantyUnit,
  warrantyDescription,
  rejectedReason,
  approvedWarrantyDate,
  extensionPeriod,
  extensionUnit,
  status,
  onEdit,
  isEdit,
  isDelete,
  onDelete,
  src,
  title,
  titleShow,
  itemNo,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t, i18n } = useTranslation(["history"]);

  const renderStatus = RENDER_STATUS(status);
  const renderUnit = RENDER_UNIT(warrantyUnit);
  const renderExtensionUnit = RENDER_UNIT(extensionUnit);
  const renderWarrantyExpire =
    status !== "3"
      ? false
      : CAL_WARRANTY_EXPIRE(
          approvedWarrantyDate,
          warrantyPeriod,
          warrantyUnit,
          extensionPeriod,
          extensionUnit
        );
  return (
    <ProductWarranteeStyled
      statusBg={renderStatus && renderStatus.bgColor}
      status={renderStatus.label}
    >
      <div className={customClass}>
        <div className="product_warrantee_container">
          {!titleShow && (
            <div className="warrantee_title">
              {i18n.language === "en"
                ? "Musical instrument details"
                : "รายละเอียดเครื่องดนตรี"}
            </div>
          )}
          <div className="img_wrap">
            {/* <img
              className="product_img"
              alt="product"
              src={src ? src : "/assets/images/mockups/piano.png"}
            /> */}
          </div>
          <div className="title">{title}</div>
          {renderStatus && (
            <div className="pw_status">
              {t(renderStatus.label, { ns: "history" })}
            </div>
          )}
          <div className="pw_row">
            <div className="label">
              {i18n.language === "en" ? "Description : " : "รายละเอียด : "}
            </div>
            <div className="value">{description}</div>
          </div>
          {descriptionLine2 && (
            <div className="pw_row">
              <div className="label">
                {i18n.language === "en"
                  ? "Description Line 2 : "
                  : "รายละเอียด เพิ่มเติม : "}
              </div>
              <div className="value">{descriptionLine2}</div>
            </div>
          )}
          <div className="pw_row">
            <div className="label">
              {i18n.language === "en" ? "Item Number " : "หมายเลขสินค้า "} :{" "}
            </div>
            <div className="value">{itemNo}</div>
          </div>
          <div className="pw_row">
            <div className="label">
              {i18n.language === "en" ? " Serial No" : " หมายเลขซีเรียล"} :{" "}
            </div>
            <div className="value">{sn}</div>
          </div>
          <div className="pw_row">
            <div className="label">
              {i18n.language === "en"
                ? "Warranty Period"
                : "ระยะเวลาการรับประกัน"}{" "}
              :{" "}
            </div>
            <div className="value flex">
              {warrantyPeriod}{" "}
              {renderUnit
                ? ` ${
                    i18n.language === "en"
                      ? renderUnit.labelEN
                      : renderUnit.labelTH
                  }`
                : ""}
            </div>
          </div>

          <div className="pw_row">
            <div className="label">
              {i18n.language === "en"
                ? "Warranty Description"
                : "คำอธิบายการรับประกัน"}{" "}
              :{" "}
            </div>
            <div className="value">{warrantyDescription}</div>
          </div>

          {extensionPeriod && (
            <div className="pw_row">
              <div className="label">
                {t("Warranty Extension", { ns: "history" })} :{" "}
              </div>
              <div className="value flex">
                {extensionPeriod}
                {renderExtensionUnit
                  ? ` ${
                      i18n.language === "en"
                        ? renderExtensionUnit.labelEN
                        : renderExtensionUnit.labelTH
                    }`
                  : ""}
              </div>
            </div>
          )}
          {renderWarrantyExpire && (
            <div className="pw_row">
              <div className="label">
                {t("Expiration date", { ns: "history" })} :{" "}
              </div>
              <div className="value">
                {renderWarrantyExpire.format("DD/MM/YYYY")}
              </div>
            </div>
          )}
          {status === "5" && (
            <>
              <div className="pw_row">
                <div className="label">
                  {t("Reason for cancellation", { ns: "history" })} :{" "}
                </div>
                <div className="value">{rejectedReason}</div>
              </div>
              {isEdit && (
                <div className="btn_edit_pw" onClick={onEdit}>
                  {t("Edit", { ns: "common" })}
                </div>
              )}
            </>
          )}
          {isDelete && (
            <div className="btn_edit_pw" onClick={onDelete}>
              {t("Delete", { ns: "common" })}
            </div>
          )}
        </div>
      </div>
    </ProductWarranteeStyled>
  );
};

ProductWarrantee.propTypes = {};

export default ProductWarrantee;
