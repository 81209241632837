import React from "react";
import { SpecialPointsContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
// import { MemberSwitchTypeWidget } from "widgets";
import { Cards, Headers, Icons } from "components";

class SpecialPointsContainer extends React.Component {
  handleClickMenu = (e) => {
    this.props.router.navigate(e.path);
  };

  render() {
    const { data } = this.props;
    return (
      <SpecialPointsContainerStyled>
        <div className="header_sec">
          <Headers.PageHeader
            icons={<Icons.SpecialPoint />}
            title={"SPECIAL GIFT"}
            subTitle={"ของรางวัลพิเศษ"}
          />
        </div>
        {data &&
          data.map((e, i) => (
            <div
              key={i}
              className="menu_item_wrap"
              onClick={() => this.handleClickMenu(e)}
            >
              <Cards.MenuItem icon={e.icon} name={e.name} />
            </div>
          ))}
      </SpecialPointsContainerStyled>
    );
  }
}

export default withRouter(SpecialPointsContainer);
