import React from "react";

export default function renderIcon({
  width = 46.579,
  height = 45.378,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.379 45.378"
    >
      <g
        id="Group_723"
        dataName="Group 723"
        transform="translate(-23 -175.498)"
      >
        <g
          id="Group_102"
          dataName="Group 102"
          transform="translate(23 175.498)"
        >
          <g id="Group_101" dataName="Group 101">
            <path
              id="Path_211"
              dataName="Path 211"
              d="M1177.12,2204.938a22.69,22.69,0,1,1-22.689-22.689,22.689,22.689,0,0,1,22.689,22.689"
              transform="translate(-1131.741 -2182.249)"
              fill="#4b1e78"
            />
          </g>
        </g>
        <path
          id="Union_29"
          dataName="Union 29"
          d="M8,28.339a8.01,8.01,0,0,1-8-8V10.257A8.008,8.008,0,0,1,6.5,2.4V4.2a1.565,1.565,0,1,0,3.129,0V2.257h8.664V4.2a1.564,1.564,0,1,0,3.128,0V2.645a8.016,8.016,0,0,1,5.537,7.612V20.339a8.01,8.01,0,0,1-8,8ZM1.685,15.3h.664a5.014,5.014,0,0,0-.664,2.492v3.794a5.005,5.005,0,0,0,5,5H20.272a5.005,5.005,0,0,0,5-5V17.79a5,5,0,0,0-.665-2.492h.665V10.283H1.685ZM18.773,4.183v-3.1a1.083,1.083,0,0,1,2.166,0v3.1a1.083,1.083,0,1,1-2.166,0Zm-11.794,0v-3.1a1.083,1.083,0,0,1,2.166,0v3.1a1.083,1.083,0,1,1-2.166,0Z"
          transform="translate(31.985 182.757)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
