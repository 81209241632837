import styled from "styled-components";

export const PageHeaderStyled = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  .header {
    column-gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon_wrap {
      padding: 1px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      flex-shrink: 0;
    }
    .title_sec {
      .header_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-family: ${({ theme }) =>
          theme.FONT.STYLE.GT_AMERICA_COMPRESSED_BOLD};
      }
      .header_subtitle {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S17};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .theme_standard {
    .header {
      .title_sec {
        text-align: center;
      }
    }
  }

  &.theme_f22b {
    .header {
      .title_sec {
        .header_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S22};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
        }
        .header_subtitle {
          font-size: ${({ theme }) => theme.FONT.SIZE.S22};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
        }
      }
    }
  }

  &.theme_center {
    justify-content: center;
    .header {
      .title_sec {
        text-align: center;
      }
    }
  }
`;
