import React from "react";

export default function renderIcon({
  width = 47.239,
  height = 39.514,
  color = "#FFFFFF",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 47.239 39.514"
    >
      <g id="Group_520" data-name="Group 520" transform="translate(0.5 0.5)">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M46.239,9.034H43.987V4.393a2.147,2.147,0,0,0-2.144-2.144H35.931V0h5.912a4.4,4.4,0,0,1,4.4,4.393v4.64Zm-43.99,0H0V4.393A4.4,4.4,0,0,1,4.392,0h5.95V2.249H4.392A2.147,2.147,0,0,0,2.249,4.393v4.64Z"
          fill={color}
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M46.239,9.034H43.987V4.393a2.147,2.147,0,0,0-2.144-2.144H35.931V0h5.912a4.4,4.4,0,0,1,4.4,4.393v4.64Zm-43.99,0H0V4.393A4.4,4.4,0,0,1,4.392,0h5.95V2.249H4.392A2.147,2.147,0,0,0,2.249,4.393v4.64Z"
          transform="translate(46.239 38.514) rotate(180)"
          fill={color}
          stroke="rgba(255,255,255,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <rect
          id="Rectangle_2052"
          data-name="Rectangle 2052"
          width="46.191"
          height="2.251"
          transform="translate(0.007 17.923)"
          fill={color}
        />
      </g>
    </svg>
  );
}
