import React from "react";

export default function renderIcon({
  width = 10.73,
  height = 13.142,
  color = '#897143',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.73 13.142"
    >
      <g
        id="Group_751"
        data-name="Group 751"
        transform="translate(0.552 0.834)"
      >
        <line
          id="Line_214"
          data-name="Line 214"
          x2="9.625"
          y2="6.375"
          fill="none"
          stroke={color}
          strokeWidth="2"
        />
        <line
          id="Line_215"
          data-name="Line 215"
          y1="6.375"
          x2="9.625"
          transform="translate(0 5.1)"
          fill="none"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
