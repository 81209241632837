import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { DateTimePickerStyled } from "./styled";
import theme from "styles/theme.json";
import { useTranslation } from "react-i18next";
import { MONTH_EN, MONTH_TH } from "utils/functions/date";
import moment from "moment";

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear; i >= currentYear - 80; i--) {
    years.push({ value: i, label: i });
  }
  return years;
};

const days = Array.from({ length: 31 }, (_, i) => {
  const temp = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
  return {
    value: temp,
    label: temp,
  };
});

const DateTimePicker = ({ label, isRequire, value, onChange }) => {
  const { t, i18n } = useTranslation(["common"]);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [_dayOption, setDayOption] = useState(days);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      color: state.isSelected ? theme.COLORS.PURPLE_1 : theme.COLORS.PURPLE_1,
      padding: "8px 10px",
      background: state.isFocused ? "#f2f9fc" : theme.COLORS.WHITE_1,
      fontSize: theme.FONT.SIZE.S14,
    }),
    indicatorSeparator: () => ({
      display: "none", // ซ่อนเส้น Separator (เส้นระหว่าง dropdown กับ text)
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      borderRadius: 21,
      border: `0.5px solid ${theme.COLORS.PURPLE_1}`,
      padding: "2px 2px 2px 2px",
      width: "calc(100% - 4px)",
      display: "flex",
      background: theme.COLORS.WHITE_1,
      fontSize: theme.FONT.SIZE.S14,
      fontFamily: theme.FONT.STYLE.PROMPT_REGULAR,
      color: theme.COLORS.PURPLE_1,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: theme.COLORS.PURPLE_1,
    }),
    singleValue: (provided, state) => {
      const transition = "opacity 300ms";
      return {
        ...provided,
        transition,
        color: theme.COLORS.PURPLE_1,
      };
    },
  };

  useEffect(() => {
    if (value && !selectedDay && !selectedMonth) {
      let momentTemp = moment(value);
      setSelectedDay({
        label: momentTemp.format("DD"),
        value: momentTemp.format("DD"),
      });
      setSelectedMonth({
        label: momentTemp.format("MMMM"),
        value: momentTemp.format("MM"),
      });
      setSelectedYear({
        label: momentTemp.format("YYYY"),
        value: momentTemp.format("YYYY"),
      });
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      onChange(
        new Date(
          `${selectedYear.value}/${selectedMonth.value}/${selectedDay.value}`
        )
      );
    }
  }, [selectedDay, selectedMonth, selectedYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChangeMonth = (e) => {
    setSelectedMonth(e);
    const daysInMonth = moment(
      `${selectedYear || moment().format("YYYY")}-${e.value}`,
      "YYYY-MM"
    ).daysInMonth();
    const dayTemp = Array.from({ length: daysInMonth }, (_, i) => i + 1).map(
      (e) => {
        const render = Number(e) < 10 ? `0${e}` : `${e}`;
        return {
          label: render,
          value: render,
        };
      }
    );
    if (
      selectedDay &&
      Number(dayTemp[dayTemp.length - 1].value) < Number(selectedDay.value)
    ) {
      setSelectedDay(dayTemp[dayTemp.length - 1]);
    }
    setDayOption(dayTemp);
  };

  return (
    <DateTimePickerStyled>
      {label && (
        <div className="label">
          {label} {isRequire && <span> *</span>}
        </div>
      )}
      <div className="input_container">
        <div className="input_item">
          {selectedDay && (
            <div className="select-label">{t("Day", { ns: "common" })}</div>
          )}
          <Select
            styles={customStyles}
            className="input"
            options={_dayOption}
            placeholder={t("Day", { ns: "common" })}
            value={selectedDay}
            onChange={setSelectedDay}
          />
        </div>
        <div className="input_item">
          {selectedMonth && (
            <div className="select-label">{t("Month", { ns: "common" })}</div>
          )}
          <Select
            styles={customStyles}
            className="input"
            options={i18n.language === "en" ? MONTH_EN : MONTH_TH}
            placeholder={t("Month", { ns: "common" })}
            value={selectedMonth}
            onChange={_handleChangeMonth}
          />
        </div>
        <div className="input_item">
          {selectedYear && (
            <div className="select-label">{t("Year", { ns: "common" })}</div>
          )}
          <Select
            styles={customStyles}
            className="input"
            options={generateYears()}
            placeholder={t("Year", { ns: "common" })}
            value={selectedYear}
            onChange={setSelectedYear}
          />
        </div>
      </div>
    </DateTimePickerStyled>
  );
};

export default DateTimePicker;
