import React from "react";

export default function renderIcon({
  width = 45.992,
  height = 45.492,

}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.492 45.492"
    >
      <g
        id="Group_550"
        dataName="Group 550"
        transform="translate(-1875.282 -5286.434)"
      >
        <g
          id="Group_229"
          dataName="Group 229"
          transform="translate(1875.282 5286.434)"
        >
          <path
            id="Path_3786"
            dataName="Path 3786"
            d="M1920.774,5309.18a22.746,22.746,0,1,1-22.746-22.746,22.746,22.746,0,0,1,22.746,22.746"
            transform="translate(-1875.282 -5286.435)"
            fill="#4b1e78"
          />
        </g>
        <g
          id="Group_230"
          dataName="Group 230"
          transform="translate(1881.905 5293.056)"
        >
          <path
            id="Path_3787"
            dataName="Path 3787"
            d="M1903.343,5330.618a16.123,16.123,0,1,1,16.123-16.123A16.14,16.14,0,0,1,1903.343,5330.618Zm0-29.611a13.488,13.488,0,1,0,13.488,13.488A13.5,13.5,0,0,0,1903.343,5301.007Z"
            transform="translate(-1887.221 -5298.372)"
            fill="#fff"
          />
          <path
            id="Path_3788"
            dataName="Path 3788"
            d="M1910.72,5312.726l1.182,3.638a1.119,1.119,0,0,0,1.065.774h3.825a1.119,1.119,0,0,1,.658,2.025l-3.094,2.248a1.12,1.12,0,0,0-.407,1.251l1.182,3.638a1.119,1.119,0,0,1-1.722,1.252l-3.1-2.249a1.12,1.12,0,0,0-1.315,0l-3.095,2.249a1.12,1.12,0,0,1-1.723-1.252l1.182-3.638a1.12,1.12,0,0,0-.407-1.251l-3.095-2.248a1.12,1.12,0,0,1,.659-2.025h3.825a1.119,1.119,0,0,0,1.065-.774l1.182-3.638a1.119,1.119,0,0,1,2.129,0"
            transform="translate(-1893.533 -5304.418)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
