export const INSTUMENT_TYPE_OPTIONS = [
  { labelTh: "เปียโน", labelEn: "Piano", value: "1" },
  { labelTh: "ดุริยางค์", labelEn: "Band & Orchestra", value: "2" },
  {
    labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
    labelEn: "Combo & Electronic Keyboard",
    value: "3",
  },
  {
    labelTh: "เครื่องเสียงมืออาชีพ",
    labelEn: "Professional Audio",
    value: "4",
  },
];

export const RENDER_INSTUMENT_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "เปียโน",
        labelEn: "Piano",
      };
    case "2":
      return {
        labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
        labelEn: "Combo & Electronic Keyboard",
      };
    case "3":
      return {
        labelTh: "คอมโบ และ อิเลคโทรนิกส์คีย์บอร์ด",
        labelEn: "Combo & Electronic Keyboard",
      };
    case "4":
      return {
        labelTh: "เครื่องเสียงมืออาชีพ",
        labelEn: "Professional Audio",
      };
    default:
      return {
        labelTh: "",
        labelEn: "",
      };
  }
};

export const CATE_PRODUCT_OPTION_OBJECT = {
  1: [
    { labelTh: "เปียโนอคูสติก", labelEn: "Acoustic Piano", value: "1" },
    { labelTh: "เปียโนไฮบริด", labelEn: "Hybrid Piano", value: "2" },
    { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano", value: "3" },
  ],
  2: [
    { labelTh: "เครื่องลมไม้", labelEn: "Woodwind", value: "4" },
    { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass", value: "5" },
    {
      labelTh: "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
      labelEn: "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
      value: "6",
    },
    {
      labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
      labelEn:
        "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
      value: "7",
    },
    { labelTh: "ดิจิตอลแซกโซโฟน", labelEn: "Digital Saxophone", value: "8" },
    { labelTh: "HD-300", labelEn: "HD-300", value: "9" },
    {
      labelTh: "เครื่องเพอร์คัชชัน",
      labelEn: "Percussion Instruments",
      value: "10",
    },
    {
      labelTh: "เครื่องสายอคูสติก",
      labelEn: "Acoustic String Instruments",
      value: "11",
    },
    { labelTh: "ขลุ่ย", labelEn: "Recorder", value: "12" },
    { labelTh: "เปียนิกา", labelEn: "Recorder", value: "13" },
    { labelTh: "เวโนวา", labelEn: "Recorder", value: "14" },
  ],
  3: [
    { labelTh: "กีต้าร์โปร่ง", labelEn: "Acoustic Guitar", value: "15" },
    { labelTh: "กลองอคูสติกทุกรุ่น", labelEn: "Acoustic Drum", value: "16" },
    {
      labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
      labelEn: "Acoustic Guitar with Electric Parts",
      value: "17",
    },
    { labelTh: "ตู้แอมป์", labelEn: "Amplifier Cabinet", value: "18" },
    {
      labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
      labelEn: "Digital Piano/Electronic Keyboard",
      value: "19",
    },
    { labelTh: "ซินธิไซเซอร์", labelEn: "Synthesizer", value: "20" },
    { labelTh: "กลองไฟฟ้าทุกรุ่น", labelEn: "Electronic Drum", value: "21" },
    { labelTh: "มัลติเอฟเฟค", labelEn: "Multi-Effects", value: "22" },
    { labelTh: "กีตาร์ไวเลส", labelEn: "Wireless Guitar", value: "23" },
  ],
  4: [
    {
      labelTh: "สินค้าอิเล็กทรอนิกส์",
      labelEn: "Electronic Products",
      value: "24",
    },
    {
      labelTh: "ลำโพงแบบมีภาคขยาย",
      labelEn: "Speakers with Amplification",
      value: "25",
    },
    {
      labelTh: "ตู้ลำโพงและดอกลำโพง",
      labelEn: "Speaker cabinets and Loudspeakers",
      value: "26",
    },
  ],
};

export const PRODUCT_BOOKING_OPTIONS = [
  { labelTh: "เปียโนอคูสติก", labelEn: "Acoustic Piano", value: "1" },
  { labelTh: "เปียโนไฮบริด", labelEn: "Hybrid Piano", value: "2" },
  { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano", value: "3" },
  { labelTh: "เครื่องลมไม้", labelEn: "Woodwind", value: "4" },
  { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass", value: "5" },
  {
    labelTh: "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
    labelEn: "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
    value: "6",
  },
  {
    labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
    labelEn:
      "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
    value: "7",
  },
  { labelTh: "ดิจิตอลแซกโซโฟน", labelEn: "Digital Saxophone", value: "8" },
  { labelTh: "HD-300", labelEn: "HD-300", value: "9" },
  {
    labelTh: "เครื่องเพอร์คัชชัน",
    labelEn: "Percussion Instruments",
    value: "10",
  },
  {
    labelTh: "เครื่องสายอคูสติก",
    labelEn: "Acoustic String Instruments",
    value: "11",
  },
  { labelTh: "ขลุ่ย", labelEn: "Recorder", value: "12" },
  { labelTh: "เปียนิกา", labelEn: "Recorder", value: "13" },
  { labelTh: "เวโนวา", labelEn: "Recorder", value: "14" },
  { labelTh: "กีต้าร์โปร่ง", labelEn: "Acoustic Guitar", value: "15" },
  { labelTh: "กลองอคูสติกทุกรุ่น", labelEn: "Acoustic Drum", value: "16" },
  {
    labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
    labelEn: "Acoustic Guitar with Electric Parts",
    value: "17",
  },
  { labelTh: "ตู้แอมป์", labelEn: "Amplifier Cabinet", value: "18" },
  {
    labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
    labelEn: "Digital Piano/Electronic Keyboard",
    value: "19",
  },
  { labelTh: "ซินธิไซเซอร์", labelEn: "Synthesizer", value: "20" },
  { labelTh: "กลองไฟฟ้าทุกรุ่น", labelEn: "Electronic Drum", value: "21" },
  { labelTh: "มัลติเอฟเฟค", labelEn: "Multi-Effects", value: "22" },
  { labelTh: "กีตาร์ไวเลส", labelEn: "Wireless Guitar", value: "23" },
  {
    labelTh: "สินค้าอิเล็กทรอนิกส์",
    labelEn: "Electronic Products",
    value: "24",
  },
  {
    labelTh: "ลำโพงแบบมีภาคขยาย",
    labelEn: "Speakers with Amplification",
    value: "25",
  },
  {
    labelTh: "ตู้ลำโพงและดอกลำโพง",
    labelEn: "Speaker cabinets and Loudspeakers",
    value: "26",
  },
];

export const RENDER_PRODUCT_BOOKING = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "เปียโนอคูสติก",
        labelEn: "Acoustic Piano",
      };
    case "2":
      return {
        labelTh: "เปียโนไฮบริด",
        labelEn: "Hybrid Piano",
      };
    case "3":
      return { labelTh: "เปียโนไฟฟ้า", labelEn: "Electronic Piano" };
    case "4":
      return { labelTh: "เครื่องลมไม้", labelEn: "Woodwind" };
    case "5":
      return { labelTh: "เครื่องลมทองเหลือง", labelEn: "Brass" };
    case "6":
      return {
        labelTh:
          "ไซเลนท์ไวโอลิน, ไซเลนท์วิโอลา, ไซเลนท์เชลโล, ไซเลนท์ดับเบิลเบส",
        labelEn:
          "Silent Violin, Silent Viola, Silent Cello, Silent Double Bass",
      };
    case "7":
      return {
        labelTh: "ไวโอลินไฟฟ้า, วิโอลาไฟฟ้า, เชลไฟฟ้า, ดับเบิลเบสไฟฟ้า",
        labelEn:
          "Electric Violin, Electric Viola, Electric Cello, Electric Double Bass",
      };
    case "8":
      return {
        labelTh: "ดิจิตอลแซกโซโฟน",
        labelEn: "Digital Saxophone",
      };
    case "9":
      return {
        labelTh: "HD-300",
        labelEn: "HD-300",
      };
    case "10":
      return {
        labelTh: "เครื่องเพอร์คัชชัน",
        labelEn: "Percussion Instruments",
      };
    case "11":
      return {
        labelTh: "เครื่องสายอคูสติก",
        labelEn: "Acoustic String Instruments",
      };
    case "12":
      return {
        labelTh: "ขลุ่ย",
        labelEn: "Recorder",
      };
    case "13":
      return {
        labelTh: "เปียนิกา",
        labelEn: "Recorder",
      };
    case "14":
      return {
        labelTh: "เวโนวา",
        labelEn: "Recorder",
      };
    case "15":
      return {
        labelTh: "กีต้าร์โปร่ง",
        labelEn: "Acoustic Guitar",
      };
    case "16":
      return {
        labelTh: "กลองอคูสติกทุกรุ่น",
        labelEn: "Acoustic Drum",
      };
    case "17":
      return {
        labelTh: "กีต้าร์โปร่งที่มีภาคไฟฟ้า",
        labelEn: "Acoustic Guitar with Electric Parts",
      };
    case "18":
      return {
        labelTh: "ตู้แอมป์",
        labelEn: "Amplifier Cabinet",
      };
    case "19":
      return {
        labelTh: "ดิจิตอล เปียโน/ อีเล็กทรอนิส์ คีย์บอร์ด",
        labelEn: "Digital Piano/Electronic Keyboard",
      };
    case "20":
      return {
        labelTh: "ซินธิไซเซอร์",
        labelEn: "Synthesizer",
      };
    case "21":
      return {
        labelTh: "กลองไฟฟ้าทุกรุ่น",
        labelEn: "Electronic Drum",
      };
    case "22":
      return {
        labelTh: "มัลติเอฟเฟค",
        labelEn: "Multi-Effects",
      };
    case "23":
      return {
        labelTh: "กีตาร์ไวเลส",
        labelEn: "Wireless Guitar",
      };
    case "24":
      return {
        labelTh: "สินค้าอิเล็กทรอนิกส์",
        labelEn: "Electronic Products",
      };
    case "25":
      return {
        labelTh: "ลำโพงแบบมีภาคขยาย",
        labelEn: "Speakers with Amplification",
      };
    case "26":
      return {
        labelTh: "ตู้ลำโพงและดอกลำโพง",
        labelEn: "Speaker cabinets and Loudspeakers",
      };

    default:
      break;
  }
};

// AUDIO TYPE
export const INSTUMENT_AUDIO_TYPE_OPTIONS = [
  { labelTh: "ดิจิตัลมิกเซอร์", labelEn: "Digital Mixer", value: "5" },
  { labelTh: "อนาล็อกมิกเซอร์", labelEn: "Analog Mixer", value: "6" },
  {
    labelTh: "แร็ค I/O",
    labelEn: "I/O Rack",
    value: "7",
  },
  {
    labelTh: "เครื่องเสียงมืออาชีพ",
    labelEn: "Speakers",
    value: "8",
  },
  {
    labelTh: "เน็ตเวิร์คสวิตช์",
    labelEn: "Network Switch",
    value: "9",
  },
  {
    labelTh: "โปรเซสเซอร์",
    labelEn: "Processor",
    value: "10",
  },
  {
    labelTh: "พาวเวอร์แอมป์",
    labelEn: "Power Amplifier",
    value: "11",
  },
  {
    labelTh: "STAGEPAS",
    labelEn: "STAGEPAS",
    value: "12",
  },
  {
    labelTh: "ไมโครโฟน",
    labelEn: "Microphone",
    value: "13",
  },
  {
    labelTh: "หูฟัง",
    labelEn: "Headphones",
    value: "14",
  },
  {
    labelTh: "อินเตอร์เฟสเสียง",
    labelEn: "Audio Interface",
    value: "15",
  },
  {
    labelTh: "ซอฟต์แวร์",
    labelEn: "Software",
    value: "16",
  },
  {
    labelTh: "AFC",
    labelEn: "AFC",
    value: "17",
  },
  {
    labelTh: "โซลูชั่นเสียงสำหรับธุรกิจ",
    labelEn: "Business Solution",
    value: "18",
  },
];
export const CATE_PRODUCT_AUDIO_OPTION_OBJECT = {
  5: [
    { labelTh: "RIVAGE PM Series", labelEn: "RIVAGE PM Series", value: "27" },
    { labelTh: "DM7 Series", labelEn: "DM7 Series", value: "28" },
    { labelTh: "DM3 Series", labelEn: "DM3 Series", value: "29" },
    { labelTh: "อื่น ๆ", labelEn: "Other", value: "30" },
  ],
  6: [
    { labelTh: "MG Series", labelEn: "MG Series", value: "31" },
    { labelTh: "AG Series", labelEn: "AG Series", value: "32" },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "33",
    },
  ],
  7: [
    { labelTh: "R Series", labelEn: "R Series", value: "34" },
    { labelTh: "T Series", labelEn: "T Series", value: "35" },
  ],
  8: [
    {
      labelTh: "DZR / DXS XLF Series",
      labelEn: "DZR / DXS XLF Series",
      value: "36",
    },
    {
      labelTh: "DXR mkII Series",
      labelEn: "DXR mkII Series",
      value: "37",
    },
    {
      labelTh: "DHR Series",
      labelEn: "DHR Series",
      value: "38",
    },
    {
      labelTh: "DBR Series",
      labelEn: "DBR Series",
      value: "39",
    },
    {
      labelTh: "CZR / CXS XLF Series",
      labelEn: "CZR / CXS XLF Series",
      value: "40",
    },

    {
      labelTh: "CHR Series",
      labelEn: "CHR Series",
      value: "41",
    },
    {
      labelTh: "CBR Series",
      labelEn: "CBR Series",
      value: "42",
    },
    {
      labelTh: "HS Series",
      labelEn: "HS Series",
      value: "43",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "44",
    },
  ],
  9: [
    {
      labelTh: "SWP1-16MMF",
      labelEn: "SWP1-16MMF",
      value: "45",
    },
    {
      labelTh: "สินค้าอิเล็กทรอนิกส์",
      labelEn: "Other",
      value: "46",
    },
  ],
  10: [
    {
      labelTh: "DME7",
      labelEn: "DME7",
      value: "47",
    },
    {
      labelTh: "MRX7-D",
      labelEn: "MRX7-D",
      value: "48",
    },
    {
      labelTh: "MTX Series",
      labelEn: "MTX Series",
      value: "49",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "50",
    },
  ],
  11: [
    {
      labelTh: "PX Series",
      labelEn: "PX Series",
      value: "51",
    },
    {
      labelTh: "MA/PA Series",
      labelEn: "MA/PA Series",
      value: "52",
    },
    {
      labelTh: "XMV Series",
      labelEn: "XMV Series",
      value: "53",
    },
    {
      labelTh: "PC-D / DI Series",
      labelEn: "PC-D / DI Series",
      value: "54",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "55",
    },
  ],
  12: [
    {
      labelTh: "STAGEPAS 1K mkII",
      labelEn: "STAGEPAS 1K mkII",
      value: "56",
    },
    {
      labelTh: "STAGEPAS 600BT",
      labelEn: "STAGEPAS 600BT",
      value: "57",
    },
    {
      labelTh: "STAGEPAS 400 BT",
      labelEn: "STAGEPAS 400 BT",
      value: "58",
    },
    {
      labelTh: "STAGEPAS 200",
      labelEn: "STAGEPAS 200",
      value: "59",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "60",
    },
  ],
  13: [
    {
      labelTh: "YDM707",
      labelEn: "YDM707",
      value: "61",
    },
    {
      labelTh: "YDM505 / YDM505S",
      labelEn: "YDM505 / YDM505S",
      value: "62",
    },
    {
      labelTh: "DM-105",
      labelEn: "DM-105",
      value: "63",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "64",
    },
  ],
  14: [
    {
      labelTh: "MT-Series",
      labelEn: "MT-Series",
      value: "65",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "66",
    },
  ],
  15: [
    {
      labelTh: "IXO Series",
      labelEn: "IXO Series",
      value: "67",
    },
    {
      labelTh: "UR Series",
      labelEn: "UR Series",
      value: "68",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "69",
    },
  ],
  16: [
    {
      labelTh: "Cubase",
      labelEn: "Cubase",
      value: "70",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "71",
    },
  ],
  17: [
    {
      labelTh: "AFC Image",
      labelEn: "AFC Image",
      value: "72",
    },
  ],
  18: [
    {
      labelTh: "องค์กร",
      labelEn: "Corporate",
      value: "73",
    },
    {
      labelTh: "การศึกษา",
      labelEn: "Education",
      value: "74",
    },
    {
      labelTh: "บรอดแคสต์",
      labelEn: "Broadcast",
      value: "75",
    },
    {
      labelTh: "ร้านค้า",
      labelEn: "Retail",
      value: "76",
    },
    {
      labelTh: "ร้านอาหารและบาร์",
      labelEn: "Restaurant & Bar",
      value: "77",
    },
    {
      labelTh: "ธุรกิจบริการ",
      labelEn: "Hospitality",
      value: "78",
    },
    {
      labelTh: "การแสดงสดและอีเว้นท์",
      labelEn: "Live Event",
      value: "79",
    },
    {
      labelTh: "อื่น ๆ",
      labelEn: "Other",
      value: "80",
    },
  ],
};

export const INSTUMENT_BOSENDORDER_TYPE_OPTIONS = [
  { labelTh: "Bosendorfer piano", labelEn: "Bosendorfer piano", value: "19" },
];
