import React from "react";
import { ActivitiesDetailContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { setReduxWallet } from "store/reducers/walletReducer";
import QRCode from "react-qr-code";
import Linkify from "react-linkify";
import { AlertControl } from "components/Modals/Alert";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { BtnScanQRWidget } from "widgets";
import { IMG_PATH } from "utils/constants/imgPath";
import { ActQuestionContainer, ActQuestionPreviewContainer } from "./Modals";
import {
  Buttons,
  Cards,
  Displays,
  Forms,
  Headers,
  Icons,
  Inputs,
  Modals,
} from "components";

class ActivitiesDetailContainer extends React.Component {
  state = {
    isLoading: true,
    participantValue: { label: "0", value: "0" },
    step: 1,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux, router } = this.props;
    let res = await Promise.all([
      userService.GET_ACTIVITY_DETAIL(router.params?.id),
      userService.GET_ACTIVITY_HISTORY_BY_ME_ACTIVITY(
        router.params?.id,
        authenRedux.member_type_active
      ),
    ]);
    if (res && res[0].status === 200) {
      this.setState({
        data: res[0].data,
        activityRegisData: res[1].data ? res[1].data : false,
        step: 1,
        isShowModal: false,
        renderModal: false,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  onSubmitRef = (e) => {
    this.formRef = e;
  };

  handleActivityRegis = async (values) => {
    const { authenRedux, t } = this.props;
    const { data, participantValue } = this.state;
    let msgErr = [];
    if (!authenRedux?.email) {
      AlertControl.show({
        title: t("Alert", { ns: "common" }),
        description: t(
          `Please update your email on the Profile menu page before participating in the activity.`,
          { ns: "common" }
        ),
        btnLabel1: t("Back", { ns: "common" }),
        btnLabel2: t("OK", { ns: "common" }),
        onEvent2: () => {
          this.props.router.navigate(ROUTE_PATH.PROFILE_ME);
        },
      });
    } else {
      if (
        data.type === "2" &&
        data.permission_type === "2" &&
        Number(participantValue.value) > 0
      ) {
        this.setState({ step: 2 });
      } else {
        if (data.is_condition && !values) {
          msgErr.push(
            t("Please answer the questions before registering.", {
              ns: "common",
            })
          );
        }
        if (msgErr.length > 0) {
          AlertControl.show({
            title: t("Alert", { ns: "common" }),
            description: msgErr.join(", "),
            btnLabel2: t("OK", { ns: "common" }),
          });
        } else {
          if (data.is_condition && values.question_list) {
            this.setState({
              isShowModal: true,
              renderModal: (
                <ActQuestionPreviewContainer
                  data={values.question_list}
                  onBack={this.handleCloseModal}
                  å
                  onConfirm={() => this.handleSubmit(values)}
                />
              ),
            });
          } else {
            this.handleSubmit(values);
          }
        }
      }
    }
  };

  handleSubmit = async (values) => {
    const { authenRedux, router, t } = this.props;
    const { data, participantValue } = this.state;
    this.setState({
      isBgLoading: true,
    });
    const bodyFormData = new FormData();
    let params = {
      activity_id: router.params?.id,
    };
    bodyFormData.append("activity_id", router.params?.id);
    bodyFormData.append("member_type", authenRedux.member_type_active);
    if (data.is_condition) {
      params.question_list = values.question_list;
      bodyFormData.set(
        "question_list",
        JSON.stringify(
          values.question_list.map((e) => {
            let render = { ...e };
            if (e.type === "3") {
              delete render.answer;
            }
            if (
              e.answer === "Other" ||
              e.answer === "อื่นๆ" ||
              e.answer === "อื่นๆ:"
            ) {
              render.answer = e.other;
            }
            return render;
          })
        )
      );
      values.question_list.forEach((e, i) => {
        bodyFormData.append(`file[]`, e.answer[0]);
      });
    }
    if (
      data.type === "2" &&
      data.permission_type === "2" &&
      Number(participantValue.value) > 0
    ) {
      bodyFormData.set(
        "participant_list",
        JSON.stringify(values.participant_list)
      );
    }
    switch (data.permission_type) {
      case "1":
        let res1 = await userService.POST_ACTIVITY_HISTORY_REGISTER(
          bodyFormData
        );
        if (res1 && res1.status === 200) {
          this.updateMyWallet();
          this.fetchData();
        } else {
          toast.error(t("Register fail", { ns: "common" }));
        }
        break;
      case "2":
        let res2 = await userService.POST_ACTIVITY_HISTORY_LIMIT_REGISTER(
          bodyFormData
        );
        if (res2 && res2.status === 200) {
          this.updateMyWallet();
          this.fetchData();
        } else {
          this.setState({
            isShowModal: false,
            renderModal: false,
            isBgLoading: false,
          });
          AlertControl.show({
            description: t(
              `Thank you for joining in the fun with Yamaha. I'm sorry, I have full rights.`,
              { ns: "common" }
            ),
            btnLabel2: t("OK", { ns: "common" }),
          });
        }
        break;
      default:
        break;
    }
  };

  handleClick = (key) => {
    switch (key) {
      case "register":
        this.setState({
          isRegisted: true,
        });
        break;
      case "qrcode":
        this.props.router.navigate(`${ROUTE_PATH.ACTIVITIES}/34/qrcode`);
        break;
      default:
        break;
    }
  };

  handleScanQrCodeSuccess = async (sn) => {
    const { authenRedux, router, t } = this.props;
    const { activityRegisData } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      activity_id: router.params?.id,
      activity_history_id: activityRegisData._id,
      qrcode: sn,
      member_type: authenRedux.member_type_active,
    };
    let res = await userService.POST_ACTIVITY_HISTORY_SCAN(params);
    if (res && res.status === 200) {
      toast.success(t("Scan complete", { ns: "common" }));
      this.fetchData();
      this.updateMyWallet();
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error(t("Scan failed. Please check QrCode.", { ns: "common" }));
    }
  };

  updateMyWallet = async () => {
    let res = await userService.GET_MY_WALLET();
    if (res) {
      this.props.setReduxWallet(res.data);
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITIES}`);
  };

  handleEditQuestion = () => {
    const { activityRegisData } = this.state;
    this.setState({
      isShowModal: true,
      renderModal: (
        <ActQuestionContainer
          initialValue={activityRegisData}
          onBack={this.handleCloseModal}
          onQuestionUpdateFail={this.handleCloseModal}
          onQuestionUpdateSuccess={() => {
            this.handleCloseModal();
            this.setState({
              isLoading: true,
            });
            this.fetchData();
          }}
        />
      ),
    });
  };
  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: false,
    });
  };

  renderPaticipantOptions = (e) => {
    let options = [{ label: "0", value: "0" }];
    for (let index = 0; index < e; index++) {
      options.push({ label: String(index + 1), value: String(index + 1) });
    }
    return options;
  };

  render() {
    const { t, i18n } = this.props;
    const {
      isLoading,
      isBgLoading,
      isShowModal,
      activityRegisData,
      data,
      renderModal,
      participantValue,
      step,
    } = this.state;
    const isExpire =
      !data || !data?.last_date
        ? false
        : moment().isAfter(moment(data.last_date).endOf("day"));
    return (
      <ActivitiesDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.PageHeader
          icons={<Icons.ActivityCircle />}
          title={"ACTIVITY"}
          subTitle={"กิจกรรม"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            {step === 1 &&
              (activityRegisData && activityRegisData.status === "2" ? (
                <>
                  <div className="activity_wrap">
                    <img
                      className="act_img"
                      alt="activity"
                      src={
                        data.img
                          ? IMG_PATH + data.img
                          : data.img ||
                            "/assets/images/mockups/activities-1.jpg"
                      }
                    />
                  </div>
                  <div className="status_wrap">
                    <Displays.AlertLabel
                      label={
                        activityRegisData.activity_point === 0
                          ? [
                              "You have successfully registered.",
                              "Thank you for participating in the activity.",
                            ]
                          : [
                              t("You have successfully registered.", {
                                ns: "common",
                              }),
                              `${t("you received", { ns: "common" })} ${
                                activityRegisData.activity_point
                              } ${t("point", { ns: "common" })}`,
                              t(
                                "Thank you for participating in the activity.",
                                {
                                  ns: "common",
                                }
                              ),
                            ]
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="activity_wrap">
                    <img
                      className="act_img"
                      alt="activity"
                      src={
                        data.img
                          ? IMG_PATH + data.img
                          : "/assets/images/mockups/activities-1.jpg"
                      }
                    />
                  </div>
                  {activityRegisData &&
                    activityRegisData.active_qrcode_amount > 0 && (
                      <div className="scan_info_wrap">
                        <Cards.ScanInfo
                          amount={activityRegisData.active_qrcode_amount}
                        />
                      </div>
                    )}
                  {activityRegisData &&
                    activityRegisData.status === "3" &&
                    (activityRegisData.activity_type === "3" ? (
                      <>
                        <div className="annouce_label mb_12">
                          {t(
                            "Thank you for registering to participate in the activity.",
                            { ns: "common" }
                          )}
                        </div>
                        <div className="annouce_label mbb_12">
                          {t("Please wait for results announcement", {
                            ns: "home",
                          })}{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="annouce_label mb_12">
                          {t(
                            "Thank you for registering to participate in the activity.",
                            { ns: "common" }
                          )}
                        </div>
                        <div className="annouce_label mbb_12">
                          {t("Please wait for results announcement on the", {
                            ns: "home",
                          })}{" "}
                          {moment(data.announcement_date).format("DD/MM/YYYY")}
                        </div>
                      </>
                    ))}
                  {activityRegisData &&
                    activityRegisData.status === "4" &&
                    (activityRegisData.activity_type === "3" ? (
                      <>
                        <div className="annouce_label mb_12">
                          {t("Sorry, registration is not yet complete.", {
                            ns: "common",
                          })}
                        </div>
                        <div className="annouce_label mbb_12">
                          {activityRegisData.rejection_reason}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="annouce_label mb_12">
                          {t(
                            "Sorry, you were not selected to participate in the activity.",
                            { ns: "common" }
                          )}
                        </div>
                        <div className="annouce_label mbb_12">
                          {t("Invite to join new activities next time.", {
                            ns: "common",
                          })}
                        </div>
                      </>
                    ))}
                  {activityRegisData && activityRegisData.status === "2" && (
                    <div className="jointed_block">
                      {t("You have already participated in the activity.", {
                        ns: "common",
                      })}
                    </div>
                  )}
                  {!activityRegisData ||
                  activityRegisData.activity_type === "1" ||
                  activityRegisData.activity_type === "3" ? (
                    <>
                      <div className="activities_title">
                        {i18n.language === "en" ? data.name_en : data.name_th}
                      </div>
                      <div className="activities_sub_title">
                        {data.type === "1"
                          ? t("Online activity information", { ns: "home" })
                          : data.type === "2"
                          ? t("Onsite activity information", { ns: "home" })
                          : t("Activity information", { ns: "home" })}
                      </div>
                      <div className="activities_sub_article">
                        <Linkify
                          componentDecorator={(
                            decoratedHref,
                            decoratedText,
                            key
                          ) => (
                            <a target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </a>
                          )}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === "en"
                                  ? data.description_en
                                  : data.description_th,
                            }}
                          />
                        </Linkify>
                      </div>
                      {data.img2 && (
                        <div className="img_position2">
                          <img
                            className="act_img2"
                            alt="activity"
                            src={
                              data.img2
                                ? IMG_PATH + data.img2
                                : "/assets/images/mockups/activities-1.jpg"
                            }
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    activityRegisData &&
                    activityRegisData.status === "1" && (
                      <>
                        <div className="qr_code_wrap">
                          <QRCode
                            value={`${activityRegisData.customer_ref_id},${
                              activityRegisData.activity_ref_id
                            },${activityRegisData._id},${
                              activityRegisData.member_type || "1"
                            },1`}
                            size={220}
                          />
                        </div>
                        <div className="comment_label">
                          {t("You have registered for the activity.", {
                            ns: "common",
                          })}
                        </div>
                        <div className="comment_label">
                          {t("Please show QR code to confirm.", {
                            ns: "common",
                          })}
                        </div>
                        <div className="comment_label">
                          {t("On-site registration", {
                            ns: "common",
                          })}
                        </div>
                        <div className="btn_view_more_wrap">
                          <Buttons.BtnViewMoreExpand
                            label={t("Show Activity Details", { ns: "common" })}
                          >
                            <div className="activities_title">
                              {i18n.language === "en"
                                ? data.name_en
                                : data.name_th}
                            </div>
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="blank"
                                  href={decoratedHref}
                                  key={key}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    i18n.language === "en"
                                      ? data.description_en
                                      : data.description_th,
                                }}
                              />
                            </Linkify>
                          </Buttons.BtnViewMoreExpand>
                        </div>
                      </>
                    )
                  )}
                  {data.is_condition && (
                    <div className="question_block">
                      <div className="question_label">
                        {t("Question", { ns: "home" })} : {data.question}
                        {activityRegisData.status === "3" && (
                          <div
                            className="btn_question_editd"
                            onClick={this.handleEditQuestion}
                          >
                            {t("Edit", { ns: "common" })}
                          </div>
                        )}
                      </div>
                      {!activityRegisData?.question_list ? (
                        <div className="answer_input_wrap">
                          <Forms.ActivityQuestionform
                            initialValue={data}
                            ref={this.onSubmitRef}
                            onSubmit={this.handleActivityRegis}
                          />
                        </div>
                      ) : (
                        <div className="answer_row">
                          {activityRegisData.question_list.map((e, i) => (
                            <div key={i} className="question_wrap">
                              <Cards.QuestionAnsItem
                                no={i + 1}
                                question={e.question}
                                answer={
                                  e.type === "3" ? "อัพโหลดสำเร็จ" : e.answer
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  {
                    // CASE PATICIPANT EVENT OFFLINE ONLY
                    // ===================================================
                    // ===================================================
                    !activityRegisData &&
                      data.type === "2" &&
                      data.permission_type === "2" &&
                      data.participation_limit > 0 && (
                        <div className="btn_add_paticipant">
                          {t("Add number child followers", { ns: "common" })}
                          <div className="btnap_input_block">
                            <div className="input_participant_wrap">
                              <Inputs.DropdownSelect
                                options={this.renderPaticipantOptions(
                                  data.participation_limit
                                )}
                                value={participantValue}
                                onChange={(e) =>
                                  this.setState({ participantValue: e })
                                }
                              />
                            </div>
                            {t("person", { ns: "common" })}
                          </div>
                        </div>
                      )
                  }
                  {!activityRegisData && data.status === "1" ? (
                    isExpire ? (
                      <div className="notice_close_regis">
                        {t("Registration Closed", { ns: "common" })}
                      </div>
                    ) : (
                      <div className="btn_wrap">
                        {data.registration_start_date &&
                        moment().isBefore(
                          moment(
                            !data.registration_start_time
                              ? moment(data.registration_start_date)
                              : `${moment(data.registration_start_date).format(
                                  "DD/MM/YYYY"
                                )} ${moment(
                                  data.registration_start_time,
                                  "HH:mm"
                                ).format("HH:mm")}`,
                            "DD/MM/YYYY HH:mm"
                          )
                        ) ? (
                          <div className="notice_close_regis">
                            {!data.registration_start_time
                              ? t(
                                  "Registration will open on the application start date",
                                  { ns: "common" }
                                )
                              : t(
                                  "Registration will open on \n{{date}} \nat {{time}}",
                                  {
                                    ns: "common",
                                    date: moment(
                                      data.registration_start_date
                                    ).format("DD MMMM YYYY"),
                                    time: moment(
                                      data.registration_start_time,
                                      "HH:mm"
                                    ).format("HH:mm"),
                                  }
                                )}
                          </div>
                        ) : (
                          <Buttons.BgStandard
                            label={t("Register", { ns: "common" })}
                            onClick={() => {
                              if (
                                data.is_condition &&
                                !activityRegisData?.question_list
                              ) {
                                this.formRef.submit();
                              } else {
                                this.handleActivityRegis();
                              }
                            }}
                          />
                        )}
                      </div>
                    )
                  ) : (
                    <div className="action_row">
                      <div className="btn_col">
                        <Buttons.BgStandard
                          label={t("Back", { ns: "common" })}
                          onClick={this.handleClickBack}
                        />
                      </div>
                      {activityRegisData.status === "1" &&
                        activityRegisData.activity_type === "1" && (
                          <div className="btn_col">
                            <BtnScanQRWidget
                              onScanSuccess={this.handleScanQrCodeSuccess}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </>
              ))}
            {step === 2 && (
              <Forms.VisitorFollowerForm
                participantNo={participantValue?.value}
                onBack={() =>
                  this.setState({
                    step: 1,
                  })
                }
                onSubmit={this.handleSubmit}
              />
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivitiesDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(["common", "profile"])(withRouter(ActivitiesDetailContainer))
);
