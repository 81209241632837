import Activity from "./Activity";
import ActivityCircle from "./ActivityCircle";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import ArrowLeft2 from "./ArrowLeft2";
import ArrowNext from "./ArrowNext";
import ArrowPrev from "./ArrowPrev";
import ArrowRight from "./ArrowRight";
import ArrowRight2 from "./ArrowRight2";
import ArrowRightCircle from "./ArrowRightCircle";
import Bell from "./Bell";
import Cancel from "./Cancel";
import Checkbox from "./Checkbox";
import Checkbox2 from "./Checkbox2";
import Close from "./Close";
import Confirm from "./Confirm";
import Download from "./Download";
import GameLuckyDraw from "./GameLuckyDraw";
import GoldBtnIcon from "./GoldBtnIcon";
import Home from "./Home";
import Loading from "./Loading";
import MemberCoperate from "./MemberCoperate";
import MemberPerson from "./MemberPerson";
import MemberPerson2 from "./MemberPerson2";
import MenuBarHistory from "./MenuBarHistory";
import MenuBarHome from "./MenuBarHome";
import MenuBarProfile from "./MenuBarProfile";
import MenuBarScan from "./MenuBarScan";
import MenuBarSong from "./MenuBarSong";
import MenuNews from "./MenuNews";
import MenuPointRedeem from "./MenuPointRedeem";
import MenuProfile from "./MenuProfile";
import Music from "./Music";
import MusicNote from "./MusicNote";
import QrCodeMark from "./QrCodeMark";
import RedeemECoupon from "./RedeemECoupon";
import RedeemVoucher from "./RedeemVoucher";
import RedeemGift from "./RedeemGift";
import Resend from "./Resend";
import Scan from "./Scan";
import ShortcutActive from "./ShortcutActive";
import ShortcutGame from "./ShortcutGame";
import ShortcutPriority from "./ShortcutPriority";
import ShortcutSurvey from "./ShortcutSurvey";
import SpecialPoint from "./SpecialPoint";
import SubMenuActivity from "./SubMenuActivity";
import SubMenuFav from "./SubMenuFav";
import SubMenuGame from "./SubMenuGame";
import SubMenuProductWarrantee from "./SubMenuProductWarrantee";
import SubMenuSpecialPoint from "./SubMenuSpecialPoint";
import Success from "./Success";
import Survey from "./Survey";
import Voucher from "./Voucher";
import DropdownArrow from "./DropdownArrow";
import SubMenuBooking from "./SubMenuBooking";
import Warranty from "./Warranty";

const EXPORT = {
  Activity,
  ActivityCircle,
  ArrowDown,
  ArrowLeft,
  ArrowLeft2,
  ArrowNext,
  ArrowPrev,
  ArrowRight,
  ArrowRight2,
  ArrowRightCircle,
  Bell,
  Cancel,
  Checkbox,
  Checkbox2,
  Close,
  Confirm,
  Download,
  GameLuckyDraw,
  GoldBtnIcon,
  Home,
  Loading,
  MemberCoperate,
  MemberPerson,
  MemberPerson2,
  MenuBarHistory,
  MenuBarHome,
  MenuBarProfile,
  MenuBarScan,
  MenuBarSong,
  MenuNews,
  MenuPointRedeem,
  MenuProfile,
  Music,
  MusicNote,
  QrCodeMark,
  RedeemECoupon,
  RedeemVoucher,
  RedeemGift,
  Resend,
  Scan,
  ShortcutActive,
  ShortcutGame,
  ShortcutPriority,
  ShortcutSurvey,
  SpecialPoint,
  SubMenuActivity,
  SubMenuFav,
  SubMenuGame,
  SubMenuProductWarrantee,
  SubMenuSpecialPoint,
  Success,
  Survey,
  Voucher,
  DropdownArrow,
  SubMenuBooking,
  Warranty,
};

export default EXPORT;
