import styled from "styled-components";

export const ScanQRCodeStyled = styled.div`
  .scan_qr_container {
    margin: auto;
    border-radius: 21px;
    border: 0.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    max-width: 400px;
    height: 40px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .sq_block {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sq_label {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }
  .scan_show {
    text-align: center;
  }

  .theme_standard {
  }
`;
