import React from "react";
import { GameContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import { AlertControl } from "components/Modals/Alert";
import { SpinWheelWidget } from "widgets";
import { Displays, Headers, Icons } from "components";

class GameContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchReward();
  }

  fetchReward = async () => {
    let res = await userService.GET_LUCKY_DRAW_LIST(
      `?is_active=true&pagination=false`
    );
    if (res && res.status === 200) {
      this.setState({
        rewardData: [
          // {
          //   id: false,
          //   option: 'ไม่ได้ของรางวัล',
          //   style: {
          //     backgroundColor: '#ff0000',
          //     textColor: 'white',
          //   },
          // },
          ...res.data.docs.map((e, i) => ({
            id: e._id,
            option: e.name,
            style: {
              backgroundColor: (i + 1) % 2 === 0 ? "#4B1E78" : "yellow",
              textColor: "black",
            },
          })),
        ],
        isLoading: false,
      });
    }
  };

  handleClickSpinWheel = async (e) => {
    const { walletRedux } = this.props;
    const { rewardData } = this.state;
    this.fetchReward();
    this.props.setReduxWallet({
      ...walletRedux,
      coin: walletRedux.coin - 1,
    });
    if (!rewardData[e].id) {
      AlertControl.show({
        title: "Sorry",
        description: "You not have reward",
        btnLabel2: "OK",
      });
    } else {
      AlertControl.show({
        title: "ยินดีด้วย",
        description: `คุณได้รับ ${rewardData[e].option}`,
        btnLabel2: "ตกลง",
      });
    }
  };

  render() {
    const { isLoading, rewardData } = this.state;
    return (
      <GameContainerStyled>
        <div className="header_sec">
          <Headers.PageHeader
            icons={<Icons.SpecialPoint />}
            title={"Game"}
            subTitle={"เกมส์"}
          />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <SpinWheelWidget
            data={rewardData}
            onSpinSuccess={this.handleClickSpinWheel}
          />
        )}
      </GameContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  walletRedux: state.walletRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GameContainer));
