import styled from "styled-components";

export const BookingContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .section {
    .activity_wrap {
      margin-bottom: 22px;
      .act_img {
        width: 100%;
        height: auto;
      }
    }
    .status_wrap {
      .title {
        margin-bottom: 16px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
      }
      .detail {
        width: 95%;
        margin-bottom: 13px;
        color: ${({ theme }) => theme.COLORS.PURPLE_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
        white-space: pre-wrap;
        &.bd {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
          span {
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S14};
            font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
            text-decoration: none;
          }
        }
      }
    }
    .btn_wrap {
      margin-bottom: 17px;
      .btn {
        padding: 10px 20px;
      }
      .gold_btn {
        border: 1px solid ${({ theme }) => theme.COLORS.YELLOW_2};
        border-radius: 50px;
        padding: 8px;
        width: 100%;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        color: ${({ theme }) => theme.COLORS.YELLOW_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
      }
    }
  }

  .desc {
    width: 75%;
    padding: 0 25px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    margin-bottom: 60px;
    white-space: pre-wrap;
  }

  .status_detail {
    .title {
      margin-bottom: 10px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
    }

    .detail_status {
      width: 100%;
      .status {
        margin-bottom: 20px;
        width: max-content;
        display: flex;
        text-align: center;
        border-radius: 50px;
        padding: 5px 30px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
        &.orange {
          background: #ff8900;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
        &.yellow {
          background: #fff500;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
        &.green {
          background: #3f830d;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .detail {
      margin-left: 10px;
      display: flex;
      .detail_left {
        .title_detail {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
          margin-bottom: 10px;
          text-wrap: nowrap;
        }
      }
      .detail_right {
        margin-left: 20px;
        margin-bottom: 30px;
        .sub_title {
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
          margin-bottom: 10px;
        }
      }
    }
    .remark {
      color: ${({ theme }) => theme.COLORS.RED_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      margin-bottom: 10px;
    }
    .qr_sec {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
    .btn_wrap {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .btn {
        padding: 10px 20px;
      }
    }
  }
  .btn_back {
    width: 177.74px;
  }
`;
