import styled from "styled-components";

export const ActivitiesDetailContainerStyled = styled.div`
  padding: 32px 20px 40px 20px;
  .activity_wrap {
    margin-bottom: 22px;
    .act_img {
      width: 100%;
      height: auto;
    }
  }
  .jointed_block {
    margin-bottom: 18px;
    border-radius: 17px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .scan_info_wrap {
    margin-bottom: 12px;
  }
  .activities_title {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .activities_sub_title {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.COLORS.PURPLE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .activities_sub_article {
  }
  .img_position2 {
    margin-top: 8px;
    .act_img2 {
      width: 100%;
      height: auto;
    }
  }
  .qr_code_wrap {
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
  }
  .notice_close_regis {
    margin-top: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    white-space: pre-wrap;
  }
  .comment_label {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S21};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .btn_view_more_wrap {
    margin-top: 10px;
  }
  .status_wrap {
    margin-bottom: 55px;
  }
  .question_block {
    margin-top: 8px;
    .question_label {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      .btn_question_editd {
        border-radius: 1rem;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        cursor: pointer;
      }
    }
    .answer_input_wrap {
    }
    .answer_row {
      .question_wrap {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .annouce_label {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    &.mb_12 {
      margin-top: 12px;
    }
    &.mbb_12 {
      margin-bottom: 12px;
    }
  }
  .btn_add_paticipant {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    .btnap_input_block {
      display: flex;
      align-items: center;
      column-gap: 20px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      .input_participant_wrap {
        min-width: 99px;
      }
    }
  }
  .btn_wrap {
    width: 100%;
  }
  .action_row {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 16px;
    .btn_col {
      width: 100%;
    }
  }
`;
