import { useEffect, useState } from "react";
import { MenuBarWidgetStyled } from "./styled";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { useTranslation } from "react-i18next";
import MenuBarHome from "components/Icons/MenuBarHome";
import MenuBarSong from "components/Icons/MenuBarSong";
import MenuBarHistory from "components/Icons/MenuBarHistory";
import MenuBarProfile from "components/Icons/MenuBarProfile";
import { ScanQrCodeWidget } from "widgets";

const MenuBarWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { t, i18n } = useTranslation(["menu"]);

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split("/");
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.routePath);
  };
  return (
    <MenuBarWidgetStyled>
      {menuList &&
        menuList.map((e, i) =>
          e.isScan ? (
            <div
              key={i}
              className={`menu_scan_item ${i === 2 ? "scan" : ""} ${
                i18n.language === "en" ? "en" : ""
              }`}
            >
              <ScanQrCodeWidget />
            </div>
          ) : (
            <div
              key={i}
              className={`menu_item ${
                _active === e.parentMenu ? "active" : ""
              }`}
              onClick={() => _handleClickMenu(e)}
            >
              <div
                className={`mi_icon_wrap ${
                  _active === e.parentMenu ? "active" : ""
                }`}
              >
                {_active === e.parentMenu ? e.iconAct : e.icon}
              </div>
              <div className={`mi_label`}>{t(e.label, { ns: "menu" })}</div>
            </div>
          )
        )}
    </MenuBarWidgetStyled>
  );
};

const menuList = [
  {
    parentMenu: "home",
    label: "Home",
    icon: <MenuBarHome />,
    iconAct: <MenuBarHome isActive />,
    routePath: ROUTE_PATH.HOME,
  },

  {
    parentMenu: "privilege",
    label: "Privilege",
    icon: <MenuBarSong />,
    iconAct: <MenuBarSong isActive />,
    routePath: ROUTE_PATH.PRIVILEGE,
  },
  {
    isScan: true,
  },
  {
    parentMenu: "history",
    label: "History",
    icon: <MenuBarHistory />,
    iconAct: <MenuBarHistory isActive />,
    routePath: ROUTE_PATH.HISTORY,
  },
  {
    parentMenu: "profile",
    label: "Profile",
    icon: <MenuBarProfile />,
    iconAct: <MenuBarProfile isActive />,
    routePath: ROUTE_PATH.PROFILE_ME,
  },
];

export default MenuBarWidget;
