import React from "react";
import { HomeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { withTranslation } from "react-i18next";
import { Cards, Displays, Icons } from "components";
import { BannerWidget, ShortcutWidget } from "widgets";
import { userService } from "apiServices";

class HomeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      userService.GET_BANNER_LIST(),
      userService.GET_SHORTCUT_LIST(),
    ]);
    if (resArr) {
      this.setState({
        dataBanner: resArr[0].data.docs,
        dataShortcut: resArr[1].data.docs,
        isLoading: false,
      });
    }
  };

  handleClickMenu = (e) => {
    this.props.router.navigate(e.path);
  };

  render() {
    const { i18n } = this.props;
    const { isLoading, dataBanner, dataShortcut } = this.state;
    return (
      <HomeContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="banner_wrap">
              <BannerWidget data={dataBanner} />
            </div>
            {dataShortcut && dataShortcut.length > 0 && (
              <ShortcutWidget data={dataShortcut} />
            )}
            <div className="menu_wrap_nav_list">
              <div className="menu_block">
                {data &&
                  data.map((e, i) => (
                    <div
                      key={i}
                      className="menu_item_wrap"
                      onClick={() => this.handleClickMenu(e)}
                    >
                      <Cards.NavigationMenuItem
                        isSurvey
                        language={i18n.language}
                        icon={e.icon}
                        topLabel={i18n.language === "en" ? e.name : e.name_th}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </HomeContainerStyled>
    );
  }
}

const data = [
  {
    key: "activities",
    name: "ACTIVITY/NEWS",
    name_th: "ข่าวสารและกิจกรรม",
    icon: <Icons.ArrowRightCircle />,
    path: ROUTE_PATH.ACTIVITIES,
  },
  {
    key: "product_warrantee",
    name: "PRODUCT WARRANTY",
    name_th: "รับประกันสินค้า",
    icon: <Icons.ArrowRightCircle />,
    path: `${ROUTE_PATH.PRODUCT_WARRANTEE}/register`,
  },
  {
    key: "booking",
    name: "FLAGSHIP STORE, BANGKOK",
    name_th: "แฟลกชิปสโตร์ แบงค๊อก",
    icon: <Icons.ArrowRightCircle />,
    path: ROUTE_PATH.BOOKING,
  },
  {
    key: "special_points",
    name: "SPECIAL GIFT",
    name_th: "ของรางวัลพิเศษ",
    icon: <Icons.ArrowRightCircle />,
    path: ROUTE_PATH.SPECIAL_POINTS,
  },
];

export default withRouter(withTranslation(["common", "home"])(HomeContainer));
