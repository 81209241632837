import React from "react";

export default function renderIcon({ width = 50.059, height = 50.058 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50.059 50.058"
    >
      <g
        id="Group_821"
        dataName="Group 821"
        transform="translate(9780.349 6347.181)"
      >
        <g
          id="Group_809"
          dataName="Group 809"
          transform="translate(-9780.349 -6347.181)"
        >
          <g id="Group_229" dataName="Group 229" transform="translate(0)">
            <path
              id="Path_3786"
              dataName="Path 3786"
              d="M1925.34,5311.463a25.029,25.029,0,1,1-25.03-25.029,25.029,25.029,0,0,1,25.03,25.029"
              transform="translate(-1875.282 -5286.434)"
              fill="#4b1e78"
            />
          </g>
        </g>
        <g
          id="Group_810"
          dataName="Group 810"
          transform="translate(-9771.306 -6335.357)"
        >
          <path
            id="Path_4717"
            dataName="Path 4717"
            d="M2217.168,1463.081V1440.75h27.852v3.957h3.948v18.657a2.808,2.808,0,0,0-.113.323c-.464,2.092-1.477,2.917-3.582,2.917H2230.1c-2.94,0-5.88.011-8.821,0a3.667,3.667,0,0,1-3.985-3.129c-.033-.132-.086-.26-.129-.391m1.978-20.331v.757q0,9.322,0,18.644c0,1.813.659,2.479,2.455,2.48h22.071a4.531,4.531,0,0,1-.65-2.986q.008-9.039,0-18.078v-.816Z"
            transform="translate(-2217.168 -1440.75)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2419"
            dataName="Rectangle 2419"
            width="19.776"
            height="1.856"
            transform="translate(4.314 5.544)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2420"
            dataName="Rectangle 2420"
            width="8.19"
            height="1.856"
            transform="translate(15.9 18.965)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2421"
            dataName="Rectangle 2421"
            width="8.19"
            height="1.856"
            transform="translate(15.9 14.228)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2423"
            dataName="Rectangle 2423"
            width="8.19"
            height="10.541"
            transform="translate(4.314 10.281)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2422"
            dataName="Rectangle 2422"
            width="8.19"
            height="1.856"
            transform="translate(15.9 10.281)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
