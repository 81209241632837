import { useState } from "react";
import { BtnScanQRWidgetStyled } from "./styled";
import QrReader from "react-qr-scanner";
// import { useTranslation } from "react-i18next";
import { Icons, Modals } from "components";
import { useTranslation } from "react-i18next";

const BtnScanQRWidget = ({ onScanSuccess }) => {
  const { i18n } = useTranslation(["home"]);

  const [_isShowModal, _setIsShowModal] = useState(false);

  const _handleClickScan = () => {
    _setIsShowModal(true);
  };

  const _handleCloseModal = () => {
    _setIsShowModal(false);
  };

  // TEST
  // const [_value, _setValue] = useState();
  // const _handleChangeValue = (e) => {
  //   _setValue(e.target.value);
  // };

  const _successScan = (e) => {
    if (e) {
      onScanSuccess && onScanSuccess(e.text);
      _handleCloseModal();
    }
  };

  return (
    <BtnScanQRWidgetStyled>
      <button className="btn_scan" onClick={_handleClickScan}>
        {i18n.language === "en"
          ? "Turn on the camera to Scan more products"
          : "เปิดกล้องเพื่อ แสกนสินค้าเพิ่ม"}
        <Icons.Scan width={30.22} height={23.8} />
      </button>
      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        <div className="scan_show">
          <QrReader
            delay={300}
            style={{
              width: 240,
              height: 240,
            }}
            constraints={{
              video: {
                facingMode: { exact: `environment` },
              },
            }}
            onError={() => {}}
            onScan={_successScan}
          />
          {/* <button
            onClick={() =>
              _successScan({
                text: "https://siammusicmembers.com/product-warrantee/21EFEL01024-ZU34050",
              })
            }
          >
            Test
          </button> */}
        </div>
      </Modals.BodyEmpty>
    </BtnScanQRWidgetStyled>
  );
};

export default BtnScanQRWidget;
