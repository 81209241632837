import styled from "styled-components";

export const AlertLabelStyled = styled.div`
  .advertise_container {
    padding: 0 13px;
    .at_block {
      border-radius: 21px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      .at_label {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
    .advertise_btn {
      margin: auto;
      margin-top: 65px;
      max-width: 321px;
    }
  }

  .theme_standard {
  }
`;
