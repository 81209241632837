import styled from "styled-components";

export const VisitorFollowerFormStyled = styled.div`
  .title {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
  }
  .sub_title_block {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .sub_title {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
    .sub_title_no {
      color: ${({ theme }) => theme.COLORS.PURPLE_1}99;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }
  .input_row {
    margin-bottom: 50px;
    .person_no {
      margin-bottom: 9px;
      color: ${({ theme }) => theme.COLORS.GRAY_4};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
    }
    .input_wrap {
      margin-bottom: 25px;
    }
    .btn_delete_wrap {
      display: flex;
      justify-content: flex-end;
      .btn_delete {
        color: ${({ theme }) => theme.COLORS.RED_1};
        background: none;
        cursor: pointer;
      }
    }
  }
  .btn_submit_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    &.right {
      justify-content: flex-end;
    }
    .btn {
      width: 150px;
      margin-bottom: 50px;
    }
  }
`;
