import React from "react";

export default function renderIcon({ width = 50.002, height = 50.002 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50.002 50.002"
    >
      <g
        id="Group_814"
        dataName="Group 814"
        transform="translate(9780.293 6540.689)"
      >
        <g
          id="Group_813"
          dataName="Group 813"
          transform="translate(-9780.293 -6540.689)"
        >
          <path
            id="Path_3544"
            dataName="Path 3544"
            d="M1165.743,3739.822a25,25,0,1,1-25-25,25,25,0,0,1,25,25"
            transform="translate(-1115.741 -3714.822)"
            fill="#4b1e78"
          />
          <path
            id="Path_3545"
            dataName="Path 3545"
            d="M1162.027,3734.183a1.377,1.377,0,0,0-1.277-1.219c-.366-.024-.869-.064-2.114-.079l-1.3,1.414-10.781,11.466-4.7-4.482-2.331,2.424,7.349,7,12.482-13.963a39.732,39.732,0,0,1-1.125,8.285c-2.423,10.126-9.674,13.334-11.018,13.849-1.3-.506-8.61-3.775-11.022-13.849a38.034,38.034,0,0,1-1.127-9.436c2.495-.279,8.222-1.125,11.6-3.241a23.431,23.431,0,0,0,7.149,2.435l2.24-2.385c-3.136-.515-6.74-1.394-8.469-2.754a1.377,1.377,0,0,0-.85-.293h-.148a1.377,1.377,0,0,0-.85.293c-2.789,2.191-9.67,3.138-12.086,3.318a1.376,1.376,0,0,0-1.265,1.218,36.283,36.283,0,0,0,1.129,11.486c2.991,12.495,12.634,15.764,13.044,15.9a1.381,1.381,0,0,0,.425.067h.455a1.383,1.383,0,0,0,.426-.067c.409-.133,10.052-3.4,13.042-15.9a36.283,36.283,0,0,0,1.13-11.486"
            transform="translate(-1122.201 -3720.493)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
