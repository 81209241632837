import styled from "styled-components";

export const HeaderBackHomeWidgetStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_8};
  border-top: 1px solid ${({ theme }) => theme.COLORS.PURPLE_8};
  display: flex;
  align-items: center;
  column-gap: 10px;
  .btn_hdb {
    padding: 22px 20px 22px 22px;
    cursor: pointer;
  }
  .hdb_label_block {
    display: flex;
    align-items: center;
    column-gap: 13px;
    color: ${({ theme }) => theme.COLORS.PURPLE_8};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: pointer;
  }
`;
