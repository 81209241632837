import React from "react";

export default function renderIcon({ width = 50.059, height = 50.058 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50.059 50.058"
    >
      <g
        id="Group_823"
        data-name="Group 823"
        transform="translate(9743 6953.183)"
      >
        <g
          id="Group_816"
          data-name="Group 816"
          transform="translate(37.349 -535.748)"
        >
          <g
            id="Group_811"
            data-name="Group 811"
            transform="translate(-9780.349 -6417.435)"
          >
            <g id="Group_229" data-name="Group 229" transform="translate(0 0)">
              <path
                id="Path_3786"
                data-name="Path 3786"
                d="M1925.341,5311.463a25.029,25.029,0,1,1-25.03-25.028,25.029,25.029,0,0,1,25.03,25.028"
                transform="translate(-1875.282 -5286.435)"
                fill="#4b1e78"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_819"
          data-name="Group 819"
          transform="translate(-9748.454 -6959.041)"
        >
          <g
            id="Group_554"
            data-name="Group 554"
            transform="translate(13.954 13.62)"
          >
            <path
              id="Path_4414"
              data-name="Path 4414"
              d="M45.65,21.289,44.43,20.06l-6.081-6.142a.988.988,0,0,0-.519-.275c-.026-.005-.054-.007-.08-.01s-.04-.005-.061-.006-.043-.006-.065-.006H19.453a.988.988,0,0,0-.988.988v19.32a7.041,7.041,0,1,0,7.756,11.287H44.949a.987.987,0,0,0,.987-.987V21.985a.989.989,0,0,0-.286-.7m-7.016-4.262,3.913,3.941H38.634ZM21,45.566A5.068,5.068,0,1,1,26.064,40.5,5.073,5.073,0,0,1,21,45.566M43.961,43.24H27.484A7.042,7.042,0,0,0,21,33.455c-.187,0-.372.01-.555.024V15.595H36.659v6.36a.988.988,0,0,0,.988.988h6.314Z"
              transform="translate(-13.954 -13.62)"
              fill="#fff"
            />
            <path
              id="Path_4417"
              data-name="Path 4417"
              d="M26.372,22.414h9.255a.808.808,0,0,0,0-1.616H26.372a.808.808,0,1,0,0,1.616"
              transform="translate(-16.533 -15.215)"
              fill="#fff"
            />
            <path
              id="Path_4460"
              data-name="Path 4460"
              d="M.534,1.371H6.648A.624.624,0,0,0,7.182.686.624.624,0,0,0,6.648,0H.534A.624.624,0,0,0,0,.686a.624.624,0,0,0,.534.686"
              transform="matrix(0.719, -0.695, 0.695, 0.719, 5.46, 29.697)"
              fill="#fff"
            />
            <path
              id="Path_4418"
              data-name="Path 4418"
              d="M26.372,27.723h9.255a.808.808,0,1,0,0-1.616H26.372a.808.808,0,1,0,0,1.616"
              transform="translate(-16.533 -16.394)"
              fill="#fff"
            />
            <path
              id="Path_4419"
              data-name="Path 4419"
              d="M43.773,39.035H34.519a.808.808,0,1,0,0,1.616h9.254a.808.808,0,1,0,0-1.616"
              transform="translate(-18.343 -19.266)"
              fill="#fff"
            />
            <path
              id="Path_4420"
              data-name="Path 4420"
              d="M43.773,44.575H34.519a.808.808,0,1,0,0,1.616h9.254a.808.808,0,1,0,0-1.616"
              transform="translate(-18.343 -20.497)"
              fill="#fff"
            />
            <path
              id="Path_4421"
              data-name="Path 4421"
              d="M25.565,33.6a.808.808,0,0,0,.808.808h15.59a.808.808,0,1,0,0-1.616H26.373a.808.808,0,0,0-.808.808"
              transform="translate(-16.534 -17.879)"
              fill="#fff"
            />
            <path
              id="Path_4461"
              data-name="Path 4461"
              d="M.315,1.374H3.928c.174,0,.315-.308.315-.687S4.1,0,3.928,0H.315C.141,0,0,.308,0,.687s.141.687.315.687"
              transform="translate(5.727 30.683) rotate(-120)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
