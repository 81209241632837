import React from "react";

export default function renderIcon({
  width = 23.084,
  height = 21.871,
  color = "#482174",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 23.084 21.871"
    >
      <defs>
        <clipPath id="clip-path2">
          <rect
            id="Rectangle_2367"
            data-name="Rectangle 2367"
            width="23.084"
            height="21.871"
            fill="none"
            stroke={color}
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_819"
        data-name="Group 819"
        transform="translate(-32.916 -1079.761)"
      >
        <g
          id="Group_739"
          data-name="Group 739"
          transform="translate(32.916 1079.761)"
        >
          <g
            id="Group_738"
            data-name="Group 738"
            transform="translate(0 0)"
            clip-path="url(#clip-path2)"
          >
            <path
              id="Path_4713"
              data-name="Path 4713"
              d="M21.67,21.525H2.068A1.068,1.068,0,0,1,1,20.457V9.615a1.07,1.07,0,0,1,.416-.847l9.8-7.546a1.068,1.068,0,0,1,1.3,0l9.8,7.546a1.068,1.068,0,0,1,.416.847V20.457A1.068,1.068,0,0,1,21.67,21.525Z"
              transform="translate(-0.327 -0.327)"
              fill="none"
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_4714"
              data-name="Path 4714"
              d="M18.849,27.984v-4.51a2,2,0,0,0-1.877-2.1H14.139a2,2,0,0,0-1.877,2.1v4.51c0,.073.013.141.019.212h6.55c.006-.071.019-.139.019-.212"
              transform="translate(-4.014 -6.998)"
              fill="none"
              stroke={color}
              strokeWidth="1"
            />
            <path
              id="Path_4715"
              data-name="Path 4715"
              d="M18.849,27.984v-4.51a2,2,0,0,0-1.877-2.1H14.139a2,2,0,0,0-1.877,2.1v4.51c0,.073.013.141.019.212h6.55C18.836,28.125,18.849,28.057,18.849,27.984Z"
              transform="translate(-4.014 -6.998)"
              fill="none"
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
