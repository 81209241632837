import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UserInfoStyled } from "./styled";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BtnEmailUpdateWidget from "widgets/BtnEmailUpdateWidget";
import { ProfileUpdateWidget } from "widgets";

const UserInfo = ({ theme_standard, data, onEdit }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t, i18n } = useTranslation(["profile"]);

  return (
    <UserInfoStyled lang={i18n.language}>
      <div className={customClass}>
        <div className="user_info_container">
          <div className="ui_row">
            <div className="ui_label">{t("First name", { ns: "profile" })}</div>
            <div className="ui_value">
              {`${data.first_name}`}
              {onEdit && <BtnEmailUpdateWidget type="first_name" />}
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Last name", { ns: "profile" })}</div>
            <div className="ui_value">
              {`${data.last_name}`}
              {onEdit && <BtnEmailUpdateWidget type="last_name" />}
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Mobile number", { ns: "profile" })}
            </div>
            <div className="ui_value">
              {data.phone_number}
              {onEdit && <BtnEmailUpdateWidget type="phone_number" />}
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Address", { ns: "profile" })}</div>
            <div className="ui_value">{data.province}</div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Date of Birth", { ns: "profile" })}
            </div>
            <div className="ui_value">
              {moment(data.birthday).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="ui_row mb_32">
            <div className="ui_label">{t("Email", { ns: "profile" })}</div>
            <div className="ui_value">
              {onEdit ? (
                <>{data.email ? data.email : <BtnEmailUpdateWidget />}</>
              ) : (
                <>{data.email ? data.email : "-"}</>
              )}
            </div>
          </div>
        </div>
        <div className="btn_wrap">
          <ProfileUpdateWidget data={data} />
        </div>
      </div>
    </UserInfoStyled>
  );
};

UserInfo.propTypes = {};

export default UserInfo;
